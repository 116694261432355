import React, { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';
import locationSetsGqls from 'pages/settings/locationSets/LocationSets.gqls';
import { FilterIcon, StyledFilterBarSelect } from 'pages/shared/shared.style';
import { GenericEntity, OrderDirection } from 'utils/types';
import { LocationSet } from 'utils/types/locations';
import { tabName, tabToPathMap } from 'components/header/Header.consts';
import { useHistory } from 'react-router-dom';
import { CampaignFilterType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { calenderCampaigns } from 'app/genericSlices/campaigns';
import { CalendarFilterBarProps } from './CalendarFilterBar.consts';
import {
  Filters,
  Month,
  MonthSelector,
  Next,
  Previous,
  PrintButton,
  PrintIcon,
  StyledButton,
  Today,
  ViewButton,
  ViewSelector,
} from './CalendarFilterBar.style';
import { CalenderViewMode } from '../calendarView/CalendarView.consts';
import { getInitialCampaignZones, nationalSelectItem, onCampaignZoneChange } from 'utils/location';
import { FetchPolicies } from 'utils/types/common';

export const CalendarFilterBar = ({
  range,
  changeRange,
  view,
  setView,
  selectedPeriods,
  filter,
  filterMulti,
}: CalendarFilterBarProps) => {
  const { filters } = useSelector(calenderCampaigns.campaignsState);
  const { data: zonesData } = useQuery<{ getLocationSets: GenericEntity<LocationSet> }>(
    locationSetsGqls.queries.getZones,
    {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      variables: {
        data: {
          filters: { [LocationSetsFilters.CustomSets]: false },
          order: { name: OrderDirection.ASC },
        },
      },
    },
  );
  const zoneItems = zonesData?.getLocationSets.items ? [nationalSelectItem, ...zonesData.getLocationSets.items] : [];
  const history = useHistory();

  const rangeTitle = useCallback((r: Date, v: CalenderViewMode) => {
    if (v === 'month') {
      return r.toLocaleDateString('default', { month: 'long', year: 'numeric' });
    }
    const start = new Date(r);
    start.setDate(start.getDate() - start.getDay());
    const end = new Date(start);
    end.setDate(end.getDate() + 7);
    if (start.getMonth() === end.getMonth()) {
      return r.toLocaleDateString('default', { month: 'long', year: 'numeric' });
    }
    if (start.getFullYear() === end.getFullYear()) {
      return `${start.toLocaleDateString('default', { month: 'short' })} - ${end.toLocaleDateString('default', {
        month: 'short',
        year: 'numeric',
      })}`;
    }
    return `${start.toLocaleDateString('default', { month: 'short', year: 'numeric' })} - ${end.toLocaleDateString(
      'default',
      {
        month: 'short',
        year: 'numeric',
      },
    )}`;
  }, []);

  const navigateToCampaigns = () => {
    const periodsUrlFilter = selectedPeriods.map((p) => `${CampaignFilterType.Period}=${p.id}`).join('&');
    const zonesUrlFilter = filters[CampaignFilterType.Zone]?.length
      ? `&${filters[CampaignFilterType.Zone].map((z: number) => `${CampaignFilterType.Zone}=${z}`).join('&')}`
      : '';
    const localUrlFilter = filters[CampaignFilterType.OnlyLocal] ? `&${CampaignFilterType.OnlyLocal}=true` : '';
    const url = `${tabToPathMap[tabName.CampaignManagement]}?${periodsUrlFilter}${zonesUrlFilter}${localUrlFilter}`;
    history.push(url);
  };

  return (
    <Filters>
      <MarketConfigurationGuard
        configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
      >
        <FilterIcon name="filter" height={32} width={13}/>
        {zonesData?.getLocationSets?.items?.length > 0 && (
          <StyledFilterBarSelect
            placeholder="Select"
            name="zone"
            label="Zone"
            multiple
            items={zoneItems}
            onChange={(selectedItems: any) => {
              onCampaignZoneChange(selectedItems, filters, filterMulti);
            }}
            initialSelectedItems={getInitialCampaignZones(filters)}
            reset
            withSearch
            maxItems={1}
            selectWidth={240}
          />
        )}
      </MarketConfigurationGuard>
      <ViewSelector
        placeholder="View"
        name="view"
        label="View"
        items={[
          { id: 'month', name: 'Month' },
          { id: 'week', name: 'Week' },
        ]}
        initialSelectedItems={[view]}
        onChange={(v) => setView(v.id)}
        selectWidth={120}
      />
      <MonthSelector>
        <Today onClick={() => changeRange(new Date())}>Today</Today>
        <StyledButton onClick={() => changeRange(range, -1)}>
          <Previous name="arrowUp" />
        </StyledButton>
        <StyledButton onClick={() => changeRange(range, 1)}>
          <Next name="arrowUp" />
        </StyledButton>
        <Month>{rangeTitle(range, view)}</Month>
      </MonthSelector>
      <MarketConfigurationGuard
        configurations={[{ configKey: MarketConfigurationKey.EnableSchedulePeriods, value: true }]}
      >
        <ViewButton disabled={!selectedPeriods?.length} onClick={navigateToCampaigns}>
          View in Campaign Management
        </ViewButton>
      </MarketConfigurationGuard>
      {view === 'week' && (
        <PrintButton onClick={() => window.print()} data-title="Print">
          <PrintIcon name="print" />
        </PrintButton>
      )}
    </Filters>
  );
};
