import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import NewModal from 'components/shared/modal/NewModal';
import { ModalProps } from 'components/shared/modal/Modal.const';
import zIndex from 'styles/zIndex';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { OfferFormTextField } from 'components/shared/textField/OfferFormTextField';
import { OfferFormTextArea } from 'components/shared/textArea/OfferFormTextArea';
import { StyledGoldButton } from 'pages/offers/offerManagement/components/offerForm/OfferForm.style';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';

export const ImageFormContent = styled.div`
  display: flex;
  border-radius: ${typography.borderRadius}px;
  margin-bottom: ${typography.unit}px;
  padding: ${typography.unit * 2}px 0;
  text-overflow: ellipsis;
`;

export const NewImageFormContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: ${typography.borderRadius}px;
  margin-bottom: ${typography.unit}px;
  text-overflow: ellipsis;
  margin-top: 15px;
  margin-left: 36px;
  margin-right: 36px;
  gap: ${typography.unit * 3}px;
`;

export const FileDetailsContainer = styled.div`
  width: 252px;
`;

export const StyledModal = styled(NewModal)`
  width: 660px;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 0 0 ${typography.unit * 4}px;
  overflow: hidden;
`;

export const FileDetails = styled.div`
  display: grid;
  grid-template-columns: 65px auto 35px;
  font-weight: 400;
  // margin-bottom: ${typography.unit}px;
`;

export const PropName = styled.span<StyledWithThemeProps>`
  color: ${newGlobalTheme.colors.text.nonhighlighted};
  font-size: 12px;
  font-family: Speedee;
`;

export const NewPropValue = styled.span<StyledWithThemeProps>`
  color: ${newGlobalTheme.colors.text.nonhighlighted};
  font-size: 12px;
  font-family: Speedee;
  font-weight: 400;
  line-height: 15.17px;
  align-items: center;
  white-space: nowrap;
  width: auto;
  margin-left: 0px;
`;

export const ViewEditImageName = styled(OfferFormTextField)`
  height: 59px;
  width: 100%;
  display: flex;
  margin-bottom: ${typography.unit * 4}px;
  height: 59px;
  div[role='alert'] {
    color: ${newGlobalTheme.colors.text.error} !important;
    margin-top: 39px;
  }
`;

export const NewImageDescription = styled(OfferFormTextArea)`
  margin-bottom: 18px;
`;

export const NewTagsSelectbox = styled(NewSelectbox)<{ disabled?: boolean }>`
  margin-bottom: 18px;
` as unknown as typeof NewSelectbox;

export const PropValue = styled.span<StyledWithThemeProps>`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 12px;
  white-space: nowrap;
`;

export const NewSaveButton = styled(StyledGoldButton)`
  width: 60px;
  height: 32px;
  padding: 8px 12px 8px 12px;
  gap: 8px;
  border-radius: 5px 5px 5px 5px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  color: black;
  background-color: ${newGlobalTheme.colors.button.new.primary};
  border: 1px solid ${newGlobalTheme.colors.button.new.border};
`;

export const StyledText = styled.span<StyledWithThemeProps>`
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 12px;
  font-style: italic;
  height: 23px;
`;

export const NewStyledText = styled.span<StyledWithThemeProps>`
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  font-size: 12px;
  font-style: italic;
  height: 23px;
`;

export const CheckboxContainer = styled.div`
  position: relative;
`;

export const BonusCampaignTooltipContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
`;

export const CheckboxWithTooltip = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxLabel = styled.label`
  margin-left: ${typography.unit}px;
`;

export const ImageContainer = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  width: 602px;
  min-height: 430px;
  position: fixed;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity ${typography.transition}s;
  z-index: ${zIndex.modal};
  background: ${({ theme }) => theme.colors.global.background};
  border-radius: ${typography.borderRadius}px;
  box-shadow: ${({ theme }) => newGlobalTheme.colors.global.boxShadow};
`;
