import styled from '@emotion/styled';
import typography from 'styles/typography';
import ErrorComponent from 'components/shared/error/Error';
import { StyleDateTimePickerProps } from './DateTimePicker.consts';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const StyledDateTimeContainer = styled.div<StyleDateTimePickerProps>`
  display: flex;
  flex-direction: ${({ labelIsHorizontal }) => labelIsHorizontal && 'column'};
  position: relative;

  .MuiInputBase-root {
    font-size: 13px;
    width: 257px;
    color: ${({ theme }) => theme.colors.text.primary} !important;

    &:hover:before {
      border-bottom: 1px solid ${({ theme }) => newGlobalTheme.colors.global.checkboxBorderColor} !important;
    }

    &:before {
      border-bottom-style: solid !important;
      border-color: ${({ theme }) => newGlobalTheme.colors.global.checkboxBorderColor};
    }

    &:after {
      border-bottom: 1px solid
        ${({ theme, disabled }) =>
          disabled ? newGlobalTheme.colors.global.checkboxBorderColor : theme.colors.button.hover} !important;
    }

    input {
      width: 229px;
    }

    button {
      width: 20px;

      &:disabled .MuiSvgIcon-root {
        fill: ${newGlobalTheme.colors.global.inputDisabledSlide}
      }

      &:hover {
        background-color: initial;
      }

      .MuiSvgIcon-root {
        fill: ${newGlobalTheme.colors.global.borderColorboxes};
        width: 15px;

        &:hover {
          fill: ${({ theme }) => theme.colors.button.hover};
        }
      }
    }
  }
`;

export const StyledLabel = styled.label<StyleDateTimePickerProps & {disabled:boolean}>`
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 700;
  color: ${({ disabled }) => (disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary)};
  display: flex;
  align-items: center;
  margin-right: ${({ labelIsHorizontal }) => !labelIsHorizontal && `${typography.unit * 3}px`};
  margin-bottom: ${({ labelIsHorizontal }) => labelIsHorizontal && `${typography.unit}px`};
`;

export const StyledError = styled(ErrorComponent)`
  position: absolute;
  top: ${typography.unit * 8}px;
`;
