import React from 'react';
import { StyledTitle, NewStyledTitle } from './NewTextModalTitle.style';
import { TextTitleProps } from './TextModalTitle.const';

const TextModalTitle = (props: TextTitleProps) => {
  const { text, className } = props;
  const isTermsAndConditionTitle = text.startsWith('Add Terms & Conditions') || text.startsWith('Edit Terms & Conditions');
  return isTermsAndConditionTitle? (
    <NewStyledTitle className={className}>{text}</NewStyledTitle>
  ) : (
    <StyledTitle className={className}>{text}</StyledTitle>
  );
};

export default TextModalTitle;
