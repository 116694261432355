import { store } from 'app/store';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { MessageType } from 'components/shared/notifications/notifications';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { TagProps } from 'pages/settings/tags/Tags.consts';
import { createNewTag, deleteTag, updateTag } from 'utils/api/tags';
import { Tag } from 'utils/types/tags';
import { HeaderComponentType } from 'components/impact/impactModal/ImpactModal.consts';
import { TagActions } from 'pages/settings/tags/components/tagFormFooter/TagFormFooter.consts';

export const createTag = async (tag: TagProps) => {
  try {
    await createNewTag(tag);
    store.dispatch(closeModal());
    showToast(MessageType.Success, `Tag added successfully`);
  } catch (e) {
    showToast(
      MessageType.Error,
      `Failed to add tag${e.message.includes('duplication item') ? ` - ${tag.name} already exists` : ''}`,
    );
  }
};
export const handleDeleteTag = async (tagId: number) => {
  try {
    await deleteTag(tagId);
    store.dispatch(closeModal());
    showToast(MessageType.Success, 'Tag deleted successfully');
  } catch (e) {
    showToast(MessageType.Error, `Failed to delete tag`);
  }
};

export const editTag = async (tag: Partial<TagProps>) => {
  try {
    await updateTag(tag);
    store.dispatch(closeModal());
    showToast(MessageType.Success, `Tag updated successfully`);
  } catch (e) {
    showToast(
      MessageType.Error,
      `Failed to update tag${e.message.includes('duplication item') ? ` - ${tag.name} already exists` : ''}`,
    );
  }
};
export const showImpactModalAction = (
  tag: TagProps,
  tagImpacts: Tag,
  action?: TagActions,
  submitData?: any,
  handleSubmit?: any,
) => {
  let tagActionModal;
  let submitOptions = {};
  switch (action) {
    case TagActions.Delete: {
      tagActionModal = {
        title: `Delete Notification`,
        headerComponentType: HeaderComponentType.TagDeleteUsageType,
      };
      break;
    }
    case TagActions.Edit: {
      tagActionModal = {
        title: `Save Notification`,
        headerComponentType: HeaderComponentType.TagSaveNotificationType,
      };
      break;
    }
    default: {
      tagActionModal = {
        title: `Tag Usage`,
        headerComponentType: HeaderComponentType.TagUsageType,
      };
    }
  }
  const propsParams = {
    title: tagActionModal.title,
    headerComponentType: tagActionModal.headerComponentType,
    campaignImpacts: tagImpacts.campaigns,
    offerImpacts: tagImpacts.offers,
    imageImpacts: tagImpacts.images,
    entityType: 'Tag',
    entityId: tag.id,
    isFromTagScreen: true,
    onCancel: () => store.dispatch(closeModal()),
  };
  if (submitData && action) {
    submitOptions = {
      submitButtonString: action === TagActions.Edit ? 'Yes, Save' : `Yes, ${action}`,
      onSubmit: async () => {
        await handleSubmit(submitData);
      },
    };
  }
  store.dispatch(
    openModal({
      modal: Modals.ImageImpactModal,
      props: { ...propsParams, ...submitOptions },
    }),
  );
};
