import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'utils/debounce';
import { getIsControlWithError } from 'utils/form';
import { Icon } from '../icon';
import { AdornmentPosition, TextFieldProps } from './TextField.consts';
import {
  InputContainer,
  InputWrapper,
  StyledChildren,
  StyledContainer,
  StyledError,
  StyledInput,
  StyledLabel,
  StyledViewButton,
} from './NewTextField.style';

const TextField = ({
  id,
  name,
  type = 'text',
  register,
  validation,
  label,
  labelIsHorizontal,
  placeholder,
  disabled,
  value,
  errors,
  adornmentPosition = AdornmentPosition.Start,
  children,
  className,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  debounceTime,
  caretPosition,
  shouldUnRegister = true,
  autoFocus = false,
  showError = true,
}: TextFieldProps) => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (autoFocus && caretPosition === 0) {
      return;
    }
    if (caretPosition !== 0) {
      elementRef.current?.setSelectionRange(caretPosition, caretPosition);
    }
  }, [caretPosition]);
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <StyledContainer labelIsHorizontal={labelIsHorizontal} className={className}>
      {label && (
        <StyledLabel labelIsHorizontal={labelIsHorizontal}>{`${label}${validation?.required ? '*' : ''}`}</StyledLabel>
      )}
      <InputContainer {...(disabled && { title: value?.toString() })}>
        <InputWrapper
          className={'input-wrapper'}
          disabled={disabled}
          adornmentPosition={adornmentPosition}
          error={getIsControlWithError(name, errors)}
        >
          {children && <StyledChildren>{children}</StyledChildren>}
          <StyledInput
            ref={elementRef}
            id={id}
            {...(register &&
              register(name, {
                ...validation,
                shouldUnregister: shouldUnRegister,
                setValueAs: (v: string | number) => (typeof v === 'string' ? v.trim() : v),
              }))}
            defaultValue={value}
            placeholder={placeholder}
            disabled={disabled}
            {...(onChange && { onChange: debounce(onChange, debounceTime) })}
            {...onFocus}
            {...onBlur}
            onKeyPress={onKeyPress}
            type={type === 'password' ? (passwordVisible ? 'text' : type) : type}
            autoFocus={autoFocus}
            autoComplete="off"
            data-automation-id={`text-field-${name}`}
          />
          {type === 'password' && (
            <StyledViewButton onClick={() => setPasswordVisible(!passwordVisible)}>
              {passwordVisible ? (
                <Icon name="eyeIconPasswordShow" width={26} height={21} />
              ) : (
                <Icon name="eyeIconPasswordHide" width={26} height={16} />
              )}
            </StyledViewButton>
          )}
        </InputWrapper>
        {showError && <StyledError name={name} errors={errors} />}
      </InputContainer>
    </StyledContainer>
  );
};

export default TextField;
