import styled from '@emotion/styled';
import typography, { newTypography } from 'styles/typography';
import ErrorComponent from '../error/Error';
import { StyleTextAreaProps, TextAreaProps } from './TextArea.consts';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledTextArea = styled.textarea<StyleTextAreaProps &{disabled:boolean, scrollOnDisabled?: boolean} >`
  background-color: ${({ theme }) => theme.colors.global.background};
  border: ${({ error, theme }) => `1px solid ${error ? newGlobalTheme.colors.global.error : newGlobalTheme.colors.global.checkboxBorderColor}`};
  border-radius: ${typography.borderRadius}px;
  padding: ${typography.unit * 2}px ${typography.unit * 2}px;
  outline: none;
  font-size: ${typography.fontSize}px;
  font-family: ${newTypography.primaryFont};
  resize: none;
  overflow: auto;
  color: ${({ disabled }) => (disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary)};
  line-height: 22px;

  &::placeholder {
    color: ${({ disabled }) => (disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary)};
  }

  &:focus {
    border: ${({ error, theme, readOnly }) =>
      `1px solid ${error ? theme.colors.global.error : !readOnly ? theme.colors.global.inputPrimary : 'initial'}`};
  }

  &:disabled {
    pointer-events: ${({scrollOnDisabled})=> (scrollOnDisabled || false)?`scroll`:`none`};
  }
`;

export const StyledLabel = styled.div<TextAreaProps &{disabled:boolean}>`
  text-transform: capitalize;
  font-size: 13px;
  color: ${({ disabled }) => (disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary)};
  display: flex;
  align-items: center;
  margin-right: ${typography.unit * 5}px;
  margin-bottom: ${({ labelIsHorizontal }) => labelIsHorizontal && `8px`};
`;

export const StyledError = styled(ErrorComponent)`
  margin-top: ${typography.unit}px;
`;
