import styled from '@emotion/styled';
import { Icon } from 'components/shared/icon';
import typography, { newTypography } from 'styles/typography';
import { ThemeProps } from '../termsConditions/TermsConditions.consts';
import NewPageContainer from 'pages/shared/pageContainer/NewPageContainer';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import Table from 'components/shared/table/CampaignTable';

// region Tags table
export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const HeaderContainer = styled.div<ThemeProps>`
  display: flex;
  justify-content: space-between;
`;
export const TagsActions = styled.div<ThemeProps>`
  display: flex;
`;

export const StyledIcon = styled(Icon)`
  margin-left: ${typography.unit * 2}px;
`;
// endregion

export const TagsPageContainer = styled(NewPageContainer)`
  width: 100%;
  max-width: 1416px;
  margin: 0px auto;
`;

export const StyledLoaderContainerTag = styled.div<{ isEmpty: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  width: 100%;
  height: ${({ isEmpty }) => (isEmpty ? '50%' : '100%')};
`;
export const CellValueWrapper = styled.div`
  display: flex;
`;

export const TagsStyledTable = styled(Table)`
  padding: 0 0;
  margin: 0 ${typography.unit * 1}px;
  border: 0;
  border-radius: 0;
  .MuiDataGrid-columnHeaders {
    border-bottom: 3px solid ${newGlobalTheme.colors.global.tileBackground} !important;
    font-weight: 700;
    font-family: ${newTypography.primaryFont};
    color: ${newGlobalTheme.colors.global.primaryTextColor};
  }
  .MuiDataGrid-row {
    cursor: pointer !important;
    margin-bottom: 0 !important;
    border-bottom: 3px solid ${newGlobalTheme.colors.global.tileBackground} !important;
    font-family: ${newTypography.primaryFont};
    color: ${newGlobalTheme.colors.global.primaryTextColor};
  }
  .MuiDataGrid-row:hover {
    background-color: ${newGlobalTheme.colors.global.tileBackground};
  }
`;