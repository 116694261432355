import React, { useEffect, useState } from 'react';
import { NewTextPageTitle } from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName } from 'components/header/Header.consts';
import { FormMode, OrderDirection } from 'utils/types';
import { useSelector } from 'react-redux';
import { store } from 'app/store';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import AddNewButton from 'pages/shared/addNewButton/AddNewButton';
import { termsConditionsPage } from 'app/genericSlices/termsConditions';
import {
  archiveTermClicked,
  openTermModal,
  unarchiveTermClicked,
} from 'pages/settings/termsConditions/termsConditions.utils';
import PageContainer from '../../shared/pageContainer/PageContainer';
import { TermConditionProps } from './TermsConditions.consts';
import {
  StyledTermConditionItem,
  StyledTotalAmountWithGrid,
  TermConditionsList,
  TermConditionsListWrapper,
  TnCAddNewButton,
  TnCPageContainer,
  TnCStyledHeader,
  TnCTitleActionHeader,
} from './TermsConditions.style';
import { Actions, StyledHeader, StyledLoader } from '../../shared/shared.style';
import { TermsConditionsFilterBar } from './components/termsConditionsFilterBar/TermsConditionsFilterBar';
import { LoaderWrapper } from 'pages/campaigns/calendar/components/calendarView/CalendarView.style';
import { Loader } from 'components/shared/loader';
import { marketConfig } from 'app/slices/config';
import { downloadUsageReport } from 'utils/download';
import NewAddNewButton from 'pages/shared/addNewButton/NewAddNewButton';
import { useQuery } from '@apollo/client';
import { termsConditionsGqls } from './TermsConditions.gqls';
import { FetchPolicies } from 'utils/types/common';
import { useToastError } from 'hooks/use-toast-error';
import useQueryInterval from 'hooks/use-query-polling';

const TermsConditions = () => {
  const { filters } = useSelector(termsConditionsPage.termsConditionsState);
  const [isTnCArchieving, setIsTnCArchieving] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { config } = useSelector(marketConfig);
  
  const { data, error, fetchMore } = useQuery(termsConditionsGqls.queries.getTermsTermsNCondsPage, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: termsConditionsPage.getFilters(filters),
        order: { name: OrderDirection.ASC },
        limit: 24,
      },
    },
  });
  const {
    getTermsTermsNCondsPage: { items: termsConditions, total },
  } = data || { getTermsTermsNCondsPage: { items: undefined } };
  useToastError(error, 'Error loading Term & Conditions');

  const fetchNextTerms = async () => {
    if (termsConditions.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: termsConditionsPage.getFilters(filters),
            offset: termsConditions.length,
            limit: 24,
            order: { name: OrderDirection.ASC },
          },
        },
      });
    }
  };

  return (
    <TnCPageContainer>
      <TnCStyledHeader data-automation-id="header">
        <TnCTitleActionHeader>
          <NewTextPageTitle text={tabName.TermsConditions} tabIndex={0} />
          <Actions data-automation-id="actions">
            <TnCAddNewButton onClick={() => openTermModal(FormMode.New, {})} />
          </Actions>
        </TnCTitleActionHeader>
        <TermsConditionsFilterBar />
        <StyledTotalAmountWithGrid
          amount={total}
          aria-label={`Total Offer ${total}`}
          tabIndex={0}
        />
      </TnCStyledHeader>
      {!termsConditions ? (
        <StyledLoader size={LoaderSize.Large} />
      ) : (
        <>
          {Boolean(termsConditions?.length) && (
            <TermConditionsListWrapper id="terms-scroll">
              {(isTnCArchieving || isDownloading) && (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              )}
              <TermConditionsList
                data-automation-id="terms-list"
                scrollableTarget="terms-scroll"
                dataLength={termsConditions.length}
                next={fetchNextTerms}
                hasMore={termsConditions.length < total}
                loader={<StyledLoader size={LoaderSize.Large} />}
              >
                {termsConditions?.map((term: TermConditionProps, index: number) => (
                  <StyledTermConditionItem
                    key={`${term.id}_${index}`}
                    term={term}
                    editTermClicked={() => openTermModal(FormMode.Edit, term)}
                    downloadUsageReport={(term1) => downloadUsageReport(term1, config, setIsDownloading)}
                    archiveTerm={(term1) => archiveTermClicked(term1, setIsTnCArchieving)}
                    unarchiveTerm={unarchiveTermClicked}
                  />
                ))}
              </TermConditionsList>
            </TermConditionsListWrapper>
          )}
        </>
      )}
    </TnCPageContainer>
  );
};

export default TermsConditions;
