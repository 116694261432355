import React, { useEffect } from 'react';
import { ImpactType } from '../impactItem/ImpactItem.consts';
import { ButtonContained } from 'components/shared/button';
import { downloadCSV } from 'utils/download';
import {
    StyledButtonOutlined,
    StyledButtonText,
    StyledButtonsContainer,
    StyledContentContainer,
    StyledModal,
} from './ImpactModal.style';
import { ModalHeaderComponentMap, UsageModalProps } from './ImpactModal.consts';
import { getUsageReportRows } from 'utils/impact';
import { FetchPolicies } from 'utils/types/common';
import { useSelector } from 'react-redux';
import {
    UsageEntityType,
    UsageEntityTypeLabel,
    campaignUsage as campaignUsageSlice,
    offerUsage as offerUsageSlice,
} from 'app/genericSlices/usage';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import { useQuery } from '@apollo/client';
import { OrderDirection } from 'utils/types';
import { useToastError } from 'hooks/use-toast-error';
import { StyledImpactItem, StyledImpactTables, StyledImpactTablesContainer } from '../impactTables/ImpactsTables.style';
import { getColumns } from '../impactTables/ImpactTablesColumns';
import { CampaignApprovalStatuses } from 'utils/types/campaigns';
import { OfferApprovalStatuses } from 'utils/types/offers';
import offersGqls from 'pages/offers/offerManagement/Offers.gqls';
import { marketConfig } from 'app/slices/config';
import { getCampaignsForReport } from 'utils/api/campaigns';
import { getOfferForProductChangeDetails, getOfferImpactsByIds } from 'utils/api/offers';
import { handleCampaignImpactChange } from 'utils/campaign';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { handleKeyDownModal } from 'utils/modal';

/**
 * Important Notice:
 * 
 * If you're attempting to update the product's usage model or terms & conditions usage model, 
 * DO NOT use this component or modify anything in this modal. 
 * Instead, please remove all references to this component and utilize `UsageModal.tsx`.
 * 
 * Reason for this approach:
 * - Reverting changes is not possible due to shared common component dependencies.
 * - This temporary component was created specifically for the new UI release on staging (stg).
 * 
 * Files and changes to be removed:
 * - modals.ts
 * - UsageModal.tsx
 * - ModalHolder.tsx
 * - ProductSetModal.tsx
 * - termcondition.util.tsx
 */

const OldUiUsageModal = ({
    title,
    headerComponentType,
    onCancel,
    onSubmit,
    entityType,
    entityId,
    haveCampaign,
    haveOffer,
    className,
    offerVersionId,
    submitButtonString,
    offerSource = OfferSource.VCE,
}: UsageModalProps) => {
    const { filters: campaignFliters } = useSelector(campaignUsageSlice.usageState);
    const { filters: offerFliters } = useSelector(offerUsageSlice.usageState);
    const { config } = useSelector(marketConfig);

    const {
        data: campaignData,
        error: campaignError,
        fetchMore: campaignFetchMore,
    } = useQuery(campaignsGqls.queries.getForImpact, {
        fetchPolicy: FetchPolicies.CacheAndNetwork,
        nextFetchPolicy: FetchPolicies.CacheAndNetwork,
        skip: !haveCampaign,
        notifyOnNetworkStatusChange: true,

        variables: {
            data: {
                filters: campaignUsageSlice.getFilters({
                    ...campaignFliters,
                    entityId: entityType === UsageEntityType.Offer ? offerVersionId : entityId,
                    entityType,
                }),
                order: {
                    externalId: OrderDirection.DESC,
                },
                ...(entityType !== 'termCondition' && { offerSource: offerSource }),
                limit: 25,
            },
        },
    });
    const {
        getCampaigns: { items: campaignUsage, total: totalCamapigns },
    } = campaignData || { getCampaigns: { items: [], total: 0 } };
    const campaigns = handleCampaignImpactChange(campaignUsage);
    useToastError(campaignError, 'Error loading campaigns');

    const fetchNextCampaignsUsage = async () => {
        if (campaignUsage.length < totalCamapigns) {
            await campaignFetchMore({
                variables: {
                    data: {
                        filters: campaignUsageSlice.getFilters({
                            ...campaignFliters,
                            entityId: entityType === UsageEntityType.Offer ? offerVersionId : entityId,
                            entityType,
                        }),
                        offset: campaignUsage.length,
                        order: {
                            externalId: OrderDirection.DESC,
                        },
                        limit: 25,
                    },
                },
            });
        }
    };

    const {
        data: offerData,
        error: offerError,
        fetchMore: offerFetchMore,
    } = useQuery(offersGqls.queries.getForImpact, {
        fetchPolicy: FetchPolicies.CacheAndNetwork,
        nextFetchPolicy: FetchPolicies.CacheAndNetwork,
        skip: !haveOffer,
        notifyOnNetworkStatusChange: true,
        variables: {
            data: {
                filters: offerUsageSlice.getFilters({ ...offerFliters, entityId, entityType }),
                order: {
                    id: OrderDirection.DESC,
                },
                ...(entityType !== 'termCondition' && { offerSource: offerSource }),
                limit: 25,
            },
        },
    });
    const {
        getOffers: { items: offerUsage, total: totalOffers },
    } = offerData || { getOffers: { items: [], total: 0 } };
    const offers = getOfferForProductChangeDetails(offerUsage, config);
    useToastError(offerError, 'Error loading offers');

    const fetchNextOffersChanges = async () => {
        if (offerUsage.length < totalOffers) {
            await offerFetchMore({
                variables: {
                    data: {
                        filters: offerUsageSlice.getFilters({ ...offerFliters, entityId, entityType }),
                        order: {
                            id: OrderDirection.DESC,
                        },
                        offset: offerUsage.length,
                        limit: 25,
                    },
                },
            });
        }
    };

    const downloadUsageReport = async () => {
        const [allCampaignsUsage, allOffersUsage] = await Promise.all([
            haveCampaign
                ? getCampaignsForReport({
                    filters: campaignUsageSlice.getFilters({
                        ...campaignFliters,
                        entityId: entityType === UsageEntityType.Offer ? offerVersionId : entityId,
                        entityType,
                    }),
                    limit: 9999,
                    offset: 0,
                    order: {
                        externalId: OrderDirection.DESC,
                    },
                    isBatchingRequired: true,
                })
                : Promise.resolve([]),
            haveOffer
                ? getOfferImpactsByIds(offerUsageSlice.getFilters({ ...offerFliters, entityId, entityType }), config)
                : Promise.resolve([]),
        ]);
        const rows = getUsageReportRows(allCampaignsUsage, allOffersUsage, undefined, entityType);
        downloadCSV(rows, `UsageSummary_${UsageEntityTypeLabel[entityType]}_ID#${entityId}`);
    };

    useEffect(() => {

        // add event listener to prevent focus from leaving the modal 
        const keyDownListener : any = (event: KeyboardEvent) => handleKeyDownModal(event, 'modal');
    
        document.addEventListener('keydown', keyDownListener);
    
        return () => {
          document.removeEventListener('keydown', keyDownListener)
        }
      }, []);


    const ModalHeaderComponent = ModalHeaderComponentMap[headerComponentType];

    return (
        <StyledModal title={title}>
            <StyledContentContainer className={className} data-automation-id="impact">
                {Boolean(ModalHeaderComponent) && <ModalHeaderComponent />}
                <StyledImpactTablesContainer className={className}>
                    <StyledImpactTables>
                        {haveCampaign && (
                            <StyledImpactItem
                                impactType={ImpactType.Campaign}
                                impactStatuses={CampaignApprovalStatuses}
                                impacts={campaigns}
                                tableProps={{
                                    columns: getColumns(ImpactType.Campaign),
                                    rows: campaigns,
                                }}
                                impactSlice={campaignUsageSlice}
                                onRowsScrollEnd={fetchNextCampaignsUsage}
                                totalLength={totalCamapigns}
                            />
                        )}
                        {haveOffer && (
                            <StyledImpactItem
                                impactType={ImpactType.Offer}
                                impactStatuses={OfferApprovalStatuses}
                                impacts={offers}
                                tableProps={{
                                    columns: getColumns(
                                        ImpactType.Offer,
                                        entityType === UsageEntityType.TermCondition ? 'TermCondition' : undefined,
                                    ),
                                    rows: offers,
                                }}
                                impactSlice={offerUsageSlice}
                                totalLength={totalOffers}
                                onRowsScrollEnd={fetchNextOffersChanges}
                            />
                        )}
                    </StyledImpactTables>
                </StyledImpactTablesContainer>
                <StyledButtonsContainer data-automation-id="actions">
                    {onSubmit && <StyledButtonText onClick={onCancel}>Cancel</StyledButtonText>}
                    <StyledButtonOutlined onClick={() => downloadUsageReport()}>Download Usage Report</StyledButtonOutlined>
                    <ButtonContained onClick={onSubmit || onCancel}>{submitButtonString || 'Close'}</ButtonContained>
                </StyledButtonsContainer>
            </StyledContentContainer>
        </StyledModal>
    );
};

export default OldUiUsageModal;