import ToggleSwitch from 'components/shared/toggleSwitch/ToggleSwitch';
import {
  NotificationTranslation,
  PushNotificationItemProps,
  PushNotificationProps,
} from './PushNotificationSection.consts';
import {
  FormRow,
  LanguageContentContainer,
  LanguageNavigationContainer,
  LanguageNavigationItem,
  NotificationLanguage,
  NotificationTitleStyledTextField,
  StyledContainer,
  StyledDateTimePicker,
  StyledTextArea,
  SubHeader,
  ToggleTitle,
  StyledTimePicker,
  PushNotificationRow,
  DashedLine,
  AddNotificationButton,
  InLineTM,
  InLineToastContainer,
} from './PushNotificationSection.style';
import useToggle from 'hooks/use-toggle';
import { useFieldArray, useFormContext, useFormState } from 'react-hook-form';
import { Language, LanguageRecord } from 'utils/languages';
import { marketConfig } from 'app/slices/config';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { hasValue } from 'utils/text';
import { getMarketDatetime } from 'utils/date';
import { ApprovalStatus, FormMode, TimeFormats } from 'utils/types';
import { ValidationMessages } from 'utils/types/common';
import { maxDate } from '../../../../../../../utils/date';
import { Icon } from 'components/shared/icon/Icon';
import { store } from 'app/store';
import { setData } from 'app/slices/modals';
const PushNotificationItem = ({
  mode,
  status,
  translationsMap,
  isDisabled,
  dateTimeConfig,
  className,
  getValues,
  notificationIndex,
  control,
  errors,
  register,
  watch,
  unregister,
  trigger,
  setValue,
  startTimezone,
  withAmPm,
  isNotification,
  isNotificationToggleOn,
  onDelete,
}: PushNotificationItemProps) => {
  const { config, languages } = useSelector(marketConfig);
  const [selectedLanguage, setSelectedLanguage] = useState(config.primaryLanguage);
  const [isNotificationTitle] = watch(['isNotificationTitle']);
  const pushNotificationStartDate = `push_notification.${notificationIndex}.startDate`;
  const campaign_start= 'schedule.campaignStart';
  const [campaignStart, campaignEnd, notificationDate] = watch([
    campaign_start,
    'schedule.campaignEnd',
    pushNotificationStartDate
  ]);

  useEffect(() => {
    trigger(campaign_start);
  }, [notificationDate]);
  useEffect(() => {
    languages?.forEach((lang: any, index: number) => {
      setValue(`push_notification.${notificationIndex}.push_notification_translation.${index}.language`, lang);
    });
  }, [setValue]);
  useEffect(() => {
    if ((mode === FormMode.Edit || mode === FormMode.View) && isNotificationToggleOn) {
      const pushNotificationValue = getValues(`push_notification.${notificationIndex}`);
      const primaryIndex = pushNotificationValue?.push_notification_translation?.findIndex(
        (translation: { language: any }) => translation?.language === config.primaryLanguage,
      );

      if (primaryIndex !== 0) {
        const primaryTranslation = pushNotificationValue?.push_notification_translation?.splice(primaryIndex, 1)[0];
        pushNotificationValue?.push_notification_translation?.unshift(primaryTranslation);
        setValue(`push_notification.${notificationIndex}`, pushNotificationValue, { shouldValidate: false });
      }

      if (pushNotificationValue?.startDate !== null) {
        unregister(pushNotificationStartDate);
        setValue(pushNotificationStartDate, pushNotificationValue?.startDate);
      } else {
        unregister(pushNotificationStartDate);
        setValue(pushNotificationStartDate, campaignStart);
      }
      if(pushNotificationValue?.id ){
        register(`push_notification.${notificationIndex}.id`);
        setValue(`push_notification.${notificationIndex}.id`, pushNotificationValue?.id);
      }
    }
  }, [mode, isNotificationToggleOn]);

  useEffect(() => {
      trigger(pushNotificationStartDate);
  }, [isNotification]);
  useEffect(() => {
    if (campaignStart && campaignEnd) {
      trigger(pushNotificationStartDate);
    }
  }, [campaignStart, campaignEnd]);

  if (!isNotification) {
    return <></>;
  }
  return (
    <StyledContainer className={className} key={notificationIndex} >
      <FormRow marginBottom={36} marginTop={36} alignItems={'center'}>
        {getValues().schedule.hasOwnProperty('dates') ? (
          <StyledTimePicker
            control={control}
            labelIsHorizontal
            name={`push_notification.${notificationIndex}.startDate`}
            label="Notification Time"
            disabled={mode === FormMode.View || isDisabled}
            errors={errors}
            defaultValue={new Date(new Date().setHours(0, 0, 0, 0))}
            withAmPm={withAmPm}
            validation={{
              required: ValidationMessages.RequiredField,
            }}
          />
        ) : (
          <StyledDateTimePicker
            control={control}
            version="campaign-form"
            labelIsHorizontal={true}
            name={`push_notification.${notificationIndex}.startDate`}
            label="Notification date and time"
            disabled={mode === FormMode.View || isDisabled}
            errors={errors}
            timezone={startTimezone}
            defaultValue={campaignStart}
            withAmPm={withAmPm}
            minDate={
              status !== ApprovalStatus.Active
                ? maxDate(getMarketDatetime(startTimezone), campaignStart)
                : campaignStart
            }
            maxDate={campaignEnd}
            validation={{
              required: ValidationMessages.RequiredField,
              validate: {
                minDate: (notificationDate1: Date) => {
                  try {
                    const notification: any = new Date(notificationDate1);
                    notification.setSeconds(0);
                    notification.setMilliseconds(0);
                    const currDateTime = notification.getTime();
                    if (Number.isNaN(currDateTime)) {
                      return `Notification Date is not valid`;
                    }
                    if (
                      mode !== FormMode.View &&
                      currDateTime < getMarketDatetime(startTimezone).getTime() &&
                      status !== ApprovalStatus.Active
                    ) {
                      return `Start time is in the past`;
                    }
                    const campaignStartDate = new Date(campaignStart);
                    const campaignEndDate = new Date(campaignEnd);
                    campaignStartDate.setSeconds(0);
                    campaignStartDate.setMilliseconds(0);
                    campaignEndDate.setSeconds(0);
                    campaignEndDate.setMilliseconds(0);
                    if (!(currDateTime >= campaignStartDate.getTime() && currDateTime <= campaignEndDate.getTime())) {
                      return `Notification time must be between the campaign start and end time`;
                    }
                  } catch (error) {
                    return `Notification Date is not valid`;
                  }
                },
              },
            }}
          />
        )}
        {isNotification && mode!== FormMode.View && notificationIndex !== 0 && (
          <div onClick={()=>onDelete(notificationIndex)}>
            <Icon name="newTrash" staticImport={true} />
          </div>
        )}
      </FormRow>
      <nav aria-label="Language navigation">
        <LanguageNavigationContainer data-automation-id="languages">
          {languages.map((lang: Language, index: number) => (
            <LanguageNavigationItem
              key={`${index}_${lang}`}
              isActive={selectedLanguage === lang}
              aria-selected={selectedLanguage === lang}
              role="button"
              tabIndex={0}
              onClick={() => setSelectedLanguage(lang)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setSelectedLanguage(lang);
                }
              }}
              data-automation-id="language-item"
            >
              {LanguageRecord[lang]}
            </LanguageNavigationItem>
          ))}
        </LanguageNavigationContainer>
      </nav>
      {languages.map((lang: Language, index: number) => {
        const relevantTranslation: NotificationTranslation = translationsMap && translationsMap[lang];

        return (
          <LanguageContentContainer key={`${index}_${lang}`} className={selectedLanguage === lang ? 'selected' : ''}>
            <NotificationLanguage
              shouldUnRegister={false}
              register={register}
              name={`push_notification.${notificationIndex}.push_notification_translation.${index}.language`}
              label="Notification Title"
              value={lang}
              show={selectedLanguage === lang}
            />
            <NotificationTitleStyledTextField
              shouldUnRegister={false}
              register={register}
              name={`push_notification.${notificationIndex}.push_notification_translation.${index}.title`}
              label="Notification Title"
              value={relevantTranslation?.notificationTitle}
              placeholder={isNotificationTitle ? '' : 'Enter'}
              disabled={mode === FormMode.View || isDisabled}
              validation={(isNotification)?({
                required:
                  (config.isSecondaryLanguageEnforced && lang === config.secondaryLanguage) ||
                  lang === config.primaryLanguage,
                maxLength: {
                  value: 100000,
                  message: 'Up to 100K characters',
                },
                validate: (value: string) => {
                  if (lang === config.primaryLanguage) {
                    return !hasValue(value) ? ValidationMessages.RequiredField : true;
                  }
                  if (config.isSecondaryLanguageEnforced) {
                    return !hasValue(value) ? ValidationMessages.RequiredField : true;
                  }
                  return !value || (!hasValue(value) ? ValidationMessages.RequiredField : true);
                },
              }): ({})}
              errors={errors}
              labelIsHorizontal
              show={selectedLanguage === lang}
              version="campaign-form"
            />

            <StyledTextArea
              shouldUnRegister={false}
              register={register}
              name={`push_notification.${notificationIndex}.push_notification_translation.${index}.message`}
              placeholder="Enter Description"
              value={relevantTranslation?.notificationMessage}
              label="Notification Message"
              disabled={mode === FormMode.View || isDisabled}
              validation={(isNotification)?({
                required:
                  (config.isSecondaryLanguageEnforced && lang === config.secondaryLanguage) ||
                  lang === config.primaryLanguage,
                maxLength: {
                  value: 100000,
                  message: 'Up to 100K characters',
                },
                validate: (value: string) => {
                  if (lang === config.primaryLanguage) {
                    return !hasValue(value) ? ValidationMessages.RequiredField : true;
                  }
                  if (config.isSecondaryLanguageEnforced) {
                    return !hasValue(value) ? ValidationMessages.RequiredField : true;
                  }
                  return !value || (!hasValue(value) ? ValidationMessages.RequiredField : true);
                },
              }): ({})}
              errors={errors}
              rows={1}
              labelIsHorizontal
              show={selectedLanguage === lang}
            />
          </LanguageContentContainer>
        );
      })}
    </StyledContainer>
  );
};

const PushNotificationSection = ({
  mode,
  status,
  translationsMap,
  isDisabled,
  dateTimeConfig,
  className,
}: PushNotificationProps) => {
  const { timeFormat, startTimezone } = dateTimeConfig;
  const { register, unregister, control, watch, trigger, setValue, getValues } = useFormContext();
  const { errors } = useFormState();
  const { config, languages } = useSelector(marketConfig);
  const {
    fields: pushNotificationFields,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `push_notification`,
  });
  const initialToggleState =
    Boolean(pushNotificationFields.length) &&
    (getValues('isPushNotificationEnabled') !== undefined ? getValues('isPushNotificationEnabled') : true);
  const [isNotification, setNotification] = useToggle(initialToggleState);
  const [isNotificationToggleOn, setIsNotificationToggleOn] = useState(initialToggleState);
  const [isInfoRequired, setInfoRequired] = useState(true);
  register('isPushNotificationEnabled', { value: isNotification });
  const withAmPm = timeFormat === TimeFormats.Until12;
  const campaign_start= 'schedule.campaignStart';
  const pushNotificationLimit = config.pushNotificationLimit;

  useEffect(() => {
    // Preserve toggle switch state when switching between view and edit mode
    setIsNotificationToggleOn(isNotification);
    setValue('isPushNotificationEnabled', isNotification);
    if (isNotification && mode !== FormMode.View && pushNotificationFields.length <= 0) {
      append({
        startDate: getValues(campaign_start),
        push_notification_translation: languages.map((value) => ({
          language: value,
          title: '',
          message: '',
        })),
      });
    }
  }, [isNotification]);


  const handleToggle = () => {
    setNotification(); 
    // update modal state isFormDirty
      store.dispatch(
        setData({
          data: {
            isFormDirty: true,
          },
        }),
      );
  }

  return (
    <>
      <PushNotificationRow>
        <SubHeader>
          <ToggleTitle disabled={mode === FormMode.View}>Push Notification</ToggleTitle>
          <ToggleSwitch
            size="xlarge"
            checked={isNotification}
            onClick={handleToggle}
            disabled={mode === FormMode.View || isDisabled}
          />
        </SubHeader>
      </PushNotificationRow>
      {pushNotificationFields.map((v: any, pnIndex: number) => (
        <>
        <PushNotificationItem
          key={v.id}
          mode={mode}
          status={status}
          translationsMap={translationsMap}
          isDisabled={isDisabled}
          dateTimeConfig={dateTimeConfig}
          className={className}
          register={register}
          unregister={unregister}
          control={control}
          watch={watch}
          trigger={trigger}
          setValue={setValue}
          getValues={getValues}
          startTimezone={startTimezone}
          withAmPm={withAmPm}
          isNotification={isNotification}
          isNotificationToggleOn={isNotificationToggleOn}
          errors={errors}
          notificationIndex={pnIndex}
          onDelete={remove}
        />
        {(pnIndex!==(pushNotificationFields.length -1)|| mode !== FormMode.View)&& isNotification &&<DashedLine />}
        </>
      ))}
      {mode !== FormMode.View && isNotification && pushNotificationFields.length < pushNotificationLimit && (
        <AddNotificationButton
          onClick={() => {
            append({
              startDate: getValues(campaign_start),
              push_notification_translation: languages.map((value) => ({
                language: value,
                title: '',
                message: '',
              })),
            });
          }}
        >
          Add another notification{' '}
        </AddNotificationButton>
      )}
      {mode !== FormMode.View &&
        isNotification &&
        pushNotificationFields.length >= pushNotificationLimit &&
        isInfoRequired && (
          <InLineToastContainer>
            <InLineTM>
              <Icon name="latestInfo" width={24} staticImport={true} />
              <p>You’ve added the maximum of {pushNotificationLimit} notifications per campaign.</p>
            </InLineTM>
            <div onClick={() => setInfoRequired(false)}>Dismiss</div>
          </InLineToastContainer>
        )}
    </>
  );
};

export default PushNotificationSection;
