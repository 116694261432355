import styled from '@emotion/styled/macro';
import { ButtonContained } from 'components/shared/button';
import { Loader } from 'components/shared/loader';
import typography, { newTypography } from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import { ThemeProps } from '../../../settings/termsConditions/TermsConditions.consts';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import { NewSearchTextField } from 'components/shared/searchTextField/NewSearchTextField';
import TotalAmount from 'components/shared/totalAmount/TotalAmount';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import NewTotalAmount from 'components/shared/totalAmount/NewTotalAmount';

export const NewStyledHeader = styled.header<ThemeProps>`
  display: flex;
  flex-direction: column;
  padding: ${newTypography.unit * 20}px ${newTypography.unit * 6}px 0 ${typography.unit * 6}px;
`;

export const Actions = styled.div<ThemeProps>`
  display: flex;
  justify-content: space-between;
  gap: 16px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    margin-top: -10px;
`;

export const StyledLoader = styled(Loader)`
  margin: auto;
  display: flex;
`;

export const StyledSearch = styled(NewSearchTextField)`
  margin: ${newTypography.unit * 1}px ${newTypography.unit * 10}px;
`;

export const ColoredButtonBase = styled(ButtonContained)`
  color: ${({ theme }) => theme.colors.text.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

export const StyledSectionTitle = styled.div<StyledWithThemeProps>`
font-size: 20px;
font-weight: 700;
line-height: 22.83px;
margin-bottom:8px;
width:530px;
letter-spacing: -0.03em;
text-align: left;
padding-bottom: ${newTypography.unit * 2}px;
`;

export const OfferFilterBarContainer = styled.div`
display: flex;
align-items: center;
flex-direction: column;
flex-wrap: wrap;
flex: 1;
min-height: 131px;
padding: 36px 0px 16px 0px;
border-radius: 8px;
border: 6px 0px 0px 0px;
opacity: 0px;
background: #F5F5F5;
border-top: 6px solid #FFBC0D;

`;

export const NewOfferFilters = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 25px;
  flex: 1;
`;

export const NewStyledFilterBarSelect = styled(NewSelectbox)`
margin: ${newTypography.unit * 1}px ${newTypography.unit * 5}px;
opacity: 0px;
[data-automation-id="chip"] {
      background-color: #FFBC0D !important;
      color:${newGlobalTheme.colors.text.primary} 
  }

  input {
    flex: 1 1 auto;
  }
}
` as unknown as typeof NewSelectbox;

export const StyledTotalAmount = styled(NewTotalAmount)`
  align-self: flex-end;
`;

export const OfferFilterRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const DoeOfferFilterRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const TotalCountOfferFilterRow = styled(OfferFilterRow)`
    margin-bottom: 0px; 
    width: 100%;
    padding-right: 40px; 
  `

export const OfferFieldLabel = styled.label`
width: 53px;
min-height: 18px;
gap: 0px;
opacity: 0px;
font-family: ${newTypography.primaryFont};
font-size: 12px;
font-weight: 700;
line-height: 15.17px;
text-align: left;
color: #292929;
padding: 0px 0px 10px 20px;
&:focus{
width: 80px;
}
`;
export const OfferSearchField = styled.label`
width: 53px;
min-height: 18px;
gap: 0px;
opacity: 0px;
font-family: ${newTypography.primaryFont};
font-size: 12px;
font-weight: 700;
line-height: 15.17px;
text-align: left;
color: #292929;
padding: 14px 0px 10px 20px;
`;
export const OfferNewSearchTextField = styled(NewSearchTextField)`
  margin-left: 20px;
  background-color: #F5F5F5;
  width: 225px; 
  margin-right: 20px; 
`;
export const StyledTotalAmountWithGrid = styled(StyledTotalAmount)`
padding: ${newTypography.unit * 0.5}px ${newTypography.unit * 5}px;
display: flex;
gap: ${newTypography.unit * 2}px;
justify-content: flex-end;
margin-bottom: 10px;
margin-top: -80px;
gap: 0px;
opacity: 0px;
font-family: ${newTypography.primaryFont};
font-size: 12px;
font-style: italic;
font-weight: 400;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: left;
color: ${newGlobalTheme.colors.global.primaryTextColor};
`;
