import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import typography from 'styles/typography';
import { Icon } from 'components/shared/icon';
import { StyledArrowProps, StyleTabStripItemProps } from './TabStripItem.consts';

export const StyledTitle = styled.div<StyleTabStripItemProps>`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  padding: 0 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.primary};
  border-bottom: 2px solid ${({ isSelected, theme }) => (isSelected ? theme.colors.global.brandMain : 'transparent')};
  font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};
  text-transform: capitalize;
  position: relative;
  transition: all ${typography.transition}s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.tabStrip.backgroundHover};
  }
`;

export const StyledLink = styled(Link, {
  shouldForwardProp: (propName) => isPropValid(propName),
})<StyleTabStripItemProps>`
  height: 100%;
  pointer-events: ${({ isSelected }) => isSelected && 'none'};
`;

export const StyledArrowUp = styled(Icon, {
  shouldForwardProp: (propName) => isPropValid(propName),
})<StyledArrowProps>`
  fill: ${({ theme }) => theme.colors.text.primary};
  width: 7px;
  transform: ${({ isOpen }) => !isOpen && `rotate(180deg)`};
  transition: all ${typography.transition}s;
  margin-left: 18px;
`;
