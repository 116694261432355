import { gql } from '@apollo/client';

export const imagesGqls = {
  queries: {
    getAll: gql`
      query Images($data: GetAllQueryDto) {
        getImages(data: $data) {
          items {
            ... on Image {
              id
              name
              description
              tags {
                id
                name
              }
              file
              fileName
              isArchive
              updatedAt
              isLocked
              isBonusCampaign
              imageSource
            }
          }
          total
        }
      }
    `,
    getById: gql`
      query Image($id: Int!) {
        getImage(id: $id) {
          name
        }
      }
    `,
  },
  mutations: {
    add: gql`
      mutation CreateImages($data: [CreateImageDto!]!) {
        createImages(data: $data) {
          id
          name
        }
      }
    `,
    update: gql`
      mutation UpdateImages($data: [UpdateImageDto!]!) {
        updateImages(data: $data) {
          isProcessed
          message
          
        }
      }
    `,
    bulkUpdate: gql`
      mutation BulkUpdateImages($data: [UpdateImageDto!]!) {
        bulkUpdateImages(data: $data) {
          id
          error
          name
        }
      }
    `,
  },
};
