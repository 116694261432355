import React, { useEffect, useState } from 'react';
import { DiscountType } from 'utils/types/offers';
import useDiscountType from 'hooks/use-discount-type';
import { useFormContext, useFormState } from 'react-hook-form';
import { setData } from 'app/slices/modals';
import { store } from 'app/store';
import { calcProductsHint } from 'utils/product';
import {
  EnterValueWrapper,
  ProductPicker,
  StyledBuySelectbox,
  StyledCheckbox,
  StyledForSelectbox,
  StyledLabel,
  StyledLightLabel,
} from '../Template.style';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import getProductsAction from '../shared/ProductsActionCondition';
import { Currency } from '../shared/Currency/Currency';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { LineWrapper, OfferInfoWrapper, ProductLine } from '../../../OfferForm.style';
import OfferTemplateTextField from 'components/shared/textField/OfferTemplateTextField';
import { getIsControlWithError } from 'utils/form';
import {
  NonProductPLUsSection,
  templateValueIncludeNFPC,
  templateValueIncludeNP,
} from '../shared/NonProductPLUsSection';

export const Bogo = ({
  disabled,
  onProductSelection,
  offerSource,
  mode,
  offerID,
  onProductPreviewClick,
}: TemplateProps) => {
  const doeDisable = disabled || (offerSource === OfferSource.DOE && mode === 'edit');
  const { register, control, watch, setValue, getValues } = useFormContext();
  const { errors } = useFormState();

  const [eolv, setEolv] = useState(watch('versions.0.templateValues.eolvEnhancedLogic'));
  const { isFree, isPercent, shouldDisplayCurrency } = useDiscountType();
  const templateValuesGetProductsPath = 'versions.0.templateValues.getProducts';
  const [buy, discountType, buyProducts, getProducts] = watch([
    'versions.0.templateValues.buy',
    'versions.0.templateValues.discountType',
    'versions.0.templateValues.buyProducts',
    templateValuesGetProductsPath,
  ]);

  const [sameProduct, setSameProduct] = useState(watch('versions.0.templateValues.sameProduct'));

  useEffect(() => {
    setValue('versions.0.templateValues.eolvEnhancedLogic', eolv);
  }, [eolv]);

  useEffect(() => {
    setValue('versions.0.templateValues.sameProduct', sameProduct);
    if (sameProduct) {
      setValue(templateValuesGetProductsPath, buyProducts, { shouldValidate: true });
    }
  }, [sameProduct]);

  const [includeNonProduct, setIncludeNonProduct] = useState(watch(templateValueIncludeNP));
  const [includeNonFoodProductCodes] = watch([templateValueIncludeNFPC]);

  useEffect(() => {
    setValue(templateValueIncludeNP, includeNonProduct);
    if (includeNonProduct) {
      setValue(templateValueIncludeNFPC, undefined);
    }
  }, [includeNonProduct]);

  return (
    <>
      <OfferInfoWrapper disabled={disabled}>
        <LineWrapper gap={8}>
          <StyledLabel>Buy</StyledLabel>
          <StyledBuySelectbox
            control={control}
            name="versions.0.templateValues.buy"
            errors={errors}
            disabled={doeDisable}
            validation={{
              min: 1,
              max: 15,
              required: true,
            }}
            items={Array.from(
              { length: 15 },
              (x, i) =>
                ({
                  id: (i + 1).toString(),
                  name: (i + 1).toString(),
                } as any),
            )}
            defaultValue="1"
            initialSelectedItems={buy ? [typeof buy === 'object' ? buy.id : buy] : ['1']}
            selectWidth={164}
            version="offer-form"
          />
          <ProductPicker disabled={disabled}>
            <input
              type="hidden"
              {...register('versions.0.templateValues.buyProducts', {
                value: buyProducts,
                shouldUnregister: true,
                required: true,
              })}
            />
            <ProductLine>{calcProductsHint(buyProducts)}</ProductLine>
            {getProductsAction(
              disabled,
              buyProducts,
              getValues,
              'Select Products',
              'buyProducts',
              (data: any) => ({
                buyProducts: data,
                getProducts: sameProduct ? data : getProducts,
              }),
              onProductSelection,
              null,
              null,
              null,
              offerID,
              onProductPreviewClick,
            )}
          </ProductPicker>
        </LineWrapper>
        <LineWrapper gap={8}>
          <StyledLabel disabled={disabled}>Get</StyledLabel>
          <ProductPicker disabled={disabled}>
            <input
              type="hidden"
              {...register(templateValuesGetProductsPath, {
                value: getProducts,
                shouldUnregister: true,
                required: true,
              })}
            />
            <ProductLine>{calcProductsHint(getProducts)}</ProductLine>
            {getProductsAction(
              disabled || sameProduct,
              getProducts,
              getValues,
              'Select Products',
              'getProducts',
              (data: any) => ({
                getProducts: data,
              }),
              onProductSelection,
              null,
              null,
              null,
              offerID,
              onProductPreviewClick,
            )}
          </ProductPicker>
          <StyledLightLabel weight={400} disabled={disabled}>
            or
          </StyledLightLabel>
          <StyledCheckbox
            checked={sameProduct}
            disabled={disabled}
            offerTemplate
            onClick={() => {
              setSameProduct(!sameProduct);
              store.dispatch(
                setData({
                  data: {
                    sameProduct: !sameProduct,
                    templateProducts: { getProducts: sameProduct ? buyProducts : getProducts },
                    isFormDirty: true,
                  },
                }),
              );
            }}
            label="Same product"
          />
        </LineWrapper>
        {discountType === DiscountType.Percent && (
          <LineWrapper gap={8}>
            <NonProductPLUsSection
              disabled={disabled}
              includeNonProduct={includeNonProduct}
              setIncludeNonProduct={setIncludeNonProduct}
              includeNonFoodProductCodes={includeNonFoodProductCodes}
              register={register}
              getValues={getValues}
              onProductSelection={onProductSelection}
              offerID={offerID}
              onProductPreviewClick={onProductPreviewClick}
            />
          </LineWrapper>
        )}
        <LineWrapper gap={8}>
          <StyledLabel disabled={disabled}>For</StyledLabel>
          <StyledForSelectbox
            name="versions.0.templateValues.discountType"
            control={control}
            disabled={doeDisable}
            validation={{
              required: true,
            }}
            items={
              Object.entries(DiscountTypeRecord()).map(([key, value]) => {
                return {
                  id: key,
                  name: value,
                };
              }) as any[]
            }
            selectWidth={164}
            defaultValue={DiscountType.MoneyOff}
            initialSelectedItems={discountType ? [discountType] : [DiscountType.MoneyOff]}
            version="offer-form"
          />
          {!isFree && (
            <>
              <EnterValueWrapper
                disabled={disabled}
                width={136}
                errors={getIsControlWithError('versions.0.templateValues.discountValue', errors)}
              >
                {shouldDisplayCurrency && <Currency />}
                <OfferTemplateTextField
                  disabled={doeDisable}
                  register={register}
                  validation={{
                    required: true,
                    min: isPercent ? 1 : 0.01,
                    max: isPercent ? 99 : null,
                    pattern: isPercent ? /^([1-9][0-9]?|99)$/ : /^\d+(?:\.\d{1,2})?$/,
                    validate: (v: string) =>
                      (!Number.isNaN(Number(v)) && Number(v) >= 0.01) ||
                      (isPercent && Number(v) < 100 && Number(v) >= 1),
                  }}
                  errors={errors}
                  name="versions.0.templateValues.discountValue"
                  placeholder="Enter value"
                  selectWidth={75}
                />
              </EnterValueWrapper>
            </>
          )}
          <StyledCheckbox
            checked={eolv}
            disabled={doeDisable}
            onClick={() => setEolv(!eolv)}
            label="Equal or Lesser Value"
            offerTemplate
          />
        </LineWrapper>
      </OfferInfoWrapper>
    </>
  );
};
