import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import typography from 'styles/typography';
import zIndex from 'styles/zIndex';
import { StyledWithThemeProps } from 'utils/types';
import { NewButtonContained, NewButtonText } from '../button';
import TextArea from '../textArea/TextArea';

export const StyledTooltip = styled(ReactTooltip)<StyledWithThemeProps & {id?: string;}>`
   padding: 12px 16px !important;
  border-radius: 5px !important;

  background: ${({ theme }) => theme.colors.global.background};
  z-index: ${zIndex.tooltip};

  border: ${({ theme }) => `1px solid ${theme.colors.global.border} !important`};

  background: ${({ theme }) => theme.colors.global.background};
  z-index: ${zIndex.tooltip};
  box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
  text-align: start;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  min-width: 246px;
  width: max-content;

  ${({id}) => id === 'reject-tooltip' && 'height: 126px;'}
  
  pointer-events: auto !important;

  &.show {
    opacity: 1 !important;
    white-space: pre-wrap;
  }

  &[data-id='tooltip'] {
    &.place-bottom {
      margin-top: 15px;

      &::before {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          background-color: transparent;
          border-bottom: ${({ theme }) => `11px solid ${theme.colors.global.border} !important`};
          margin-left: -10px;
          top: -11px;
          z-index: -2;
      }

      &::after {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: ${({ theme }) => `11px solid ${theme.colors.text.white} `};
          transform: none;
          background-color: transparent;
          margin-left: -10px;
          top: -10px;
      }
    }

    &.place-top {
      margin-top: -15px;

      &::before {
         width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          background-color: transparent; 
          border-top: ${({ theme }) => `11px solid ${theme.colors.global.border} !important`};
          margin-left: -10px;
          bottom: -11px; 
          z-index: -2;
      }

      &::after {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: ${({ theme }) => `12px solid ${theme.colors.text.white} `};
          transform: none;
          background-color: transparent;
          margin-left: -10px;
          bottom: -11px;
      }
    }

    &.place-right {
      margin-left: 20px;

      &::before {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: ${({ theme }) => `11px solid ${theme.colors.global.border} !important`};
          transform: none;
          background-color: transparent;
          margin-left: -3px;
          margin-top: -9px; 
          z-index: -2;
      }

      &::after {
          width: 0;
          height: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: ${({ theme }) => `11px solid ${theme.colors.text.white} `};
          transform: none;
          background-color: transparent;
          margin-left: -4px;
          margin-top: -9px;
      }
    }

    &.place-left {
      margin-left: -20px;

      &::before {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: ${({ theme }) => `11px solid ${theme.colors.global.border} !important`};
        transform: none;
        background-color: transparent;
        margin-right: -11px;
        z-index: -2;
        margin-top: -9px;
      } 

      &::after {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: ${({ theme }) => `11px solid ${theme.colors.text.white} `};
        transform: none;
        background-color: transparent;
        margin-right: -4px;
        margin-top: -9px;
      }
    }
  }
`;

export const StyledButtonClose = styled(NewButtonText)`
  position: absolute;
  right: 0;
  padding: 0;
  height: 8px;
  width: 8px;
  font-weight: 700;
`;

export const StyledButtonText = styled(NewButtonText)`
  color: #006BAE;
  font-size: 14px;
    font-weight: 700;
`;

export const CancelButton = styled(NewButtonContained)`
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  background-color: ${newGlobalTheme.colors.button.secondary};
  font-size: 14px;
  height: 24px;
  :hover {
    background-color: ${newGlobalTheme.colors.button.secondary};
  }
  &:disabled {
    background-color: #f7e1a4;
  }
  border: 1px solid #c08b00;
`;

export const StyledContent = styled.div<{ shouldMargin: boolean } & StyledWithThemeProps>`
  padding-right: ${({ shouldMargin }) => shouldMargin && `${typography.unit * 3}px`};
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  // text-overflow: ellipsis;
  // overflow: hidden;
  overflow-wrap: break-word;
  display: flex;
  position: relative;
  top: 0px;
  gap: 8px; 
  letter-spacing: -0.03em;
  align-items: center;
  font-family: Speedee;
  
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${typography.unit * 2}px;
  font-family: Speedee; 
  button:first-of-type {
    margin-right: ${typography.unit * 5}px;
  }
`;

export const StyledTextArea = styled(TextArea)`
    margin-top: 8px; 
    font-family: Speedee; 
    textarea {
      ::placeholder {
        color: ${newGlobalTheme.colors.text.disabledSave}; 
      }
        padding: 2px 10px;
        border-radius: 5px;
        border: 1px solid ${newGlobalTheme.colors.status.archived};
        height: 48px; 
        font-size: 12px;

        :focus {
         border: 1px solid ${newGlobalTheme.colors.status.archived};
        }
    }


`;
