import React, { useState } from 'react';
import { Impact } from '../impactItem/ImpactItem.consts';
import { ButtonContained, ImageCancelButtonText, NewButtonContained } from 'components/shared/button';
import { downloadCSV } from 'utils/download';
import {
  DownloadUsageButton,
  ImageUsageSection,
  RightButtonContainer,
  StyledModalForImage,
  UsageButtonContainer,
  UsageFormContainer,
} from './ImpactModal.style';
import { ImpactModalProps, ModalHeaderComponentMap, ModalInfoComponentMap } from './ImpactModal.consts';
import { StyledSpan } from './shared.style';
import { getUsageReportRows } from 'utils/impact';
import { CancelButton } from 'pages/offers/offerManagement/components/offerForm/OfferForm.style';
import NewImpactTables from '../impactTables/NewImpactTables';
import { StyledButtonContained } from 'pages/settings/tags/components/tagFormFooter/TagFormFooter.style';

const ImageImpactModal = ({
  title,
  headerComponentType,
  infoComponentType,
  submitButtonString,
  onSubmit,
  onCancel,
  campaignImpacts,
  offerImpacts,
  imageImpacts,
  entityType,
  entityId,
  className,
  isFromImageScreen = false,
  isFromTagScreen = false,
}: ImpactModalProps) => {

  const [filteredCampaignImpacts, setfilteredCampaignImpacts] = useState<Impact[]>(campaignImpacts);
  const [filteredOfferImpacts, setfilteredOfferImpacts] = useState<Impact[]>(offerImpacts);
  const [filteredImageImpacts, setfilteredImageImpacts] = useState<Impact[]>(imageImpacts);

  const downloadImpactReport = () => {
    const rows = getUsageReportRows(filteredCampaignImpacts, filteredOfferImpacts, filteredImageImpacts);
    downloadCSV(rows, `UsageSummary_${entityType}_ID#${entityId}`);
  };

  const ModalHeaderComponent = ModalHeaderComponentMap[headerComponentType];
  const ModalInfoComponent = ModalInfoComponentMap[infoComponentType];

  return (
    <StyledModalForImage title={title}>
      <ImageUsageSection className={className} data-automation-id="impact">
        <UsageFormContainer>
        {Boolean(ModalHeaderComponent) && <ModalHeaderComponent />}
        <NewImpactTables
          campaignImpacts={campaignImpacts}
          setCampaginImpacts={setfilteredCampaignImpacts}
          offerImpacts={offerImpacts}
          setOfferImpacts={setfilteredOfferImpacts}
          imageImpacts={imageImpacts}
          setImageImpacts={setfilteredImageImpacts}
          entityType={entityType}
          isFromImageScreen={isFromImageScreen}
          isFromTagScreen={isFromTagScreen}
        />
        {Boolean(ModalInfoComponent) && <ModalInfoComponent campaignImpacts={campaignImpacts} />}
        {onSubmit && <StyledSpan>Are you sure?</StyledSpan>}
        <UsageButtonContainer data-automation-id="actions">
          {onSubmit && <CancelButton onClick={onCancel}>Cancel</CancelButton>}
          {!submitButtonString && (
            <ImageCancelButtonText onClick={onSubmit || onCancel}>Close</ImageCancelButtonText>
          )}
          <RightButtonContainer>
          <DownloadUsageButton noStopPropagation onClick={() => downloadImpactReport()} submitButtonString={submitButtonString}>Download Usage Report</DownloadUsageButton>
          {submitButtonString && (
            <StyledButtonContained onClick={onSubmit || onCancel}>
              {submitButtonString}
            </StyledButtonContained>
          )}
          </RightButtonContainer>
        </UsageButtonContainer>
        </UsageFormContainer>
      </ImageUsageSection>
    </StyledModalForImage>
  );
};

export default ImageImpactModal;
