import styled from '@emotion/styled';
import TextField from 'components/shared/textField/TextField';
import { StyleTextFieldProps } from 'components/shared/textField/TextField.consts';
import OldInfoTooltip from 'components/shared/tooltip/info/OldInfoToolTip';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import typography, { newTypography } from 'styles/typography';

export const ExchangeLimitContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
`;

export const StyledLabelTooltipContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const FormRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: ${typography.unit * 5}px;
  margin-right: 20px;
`;

export const StyledLabel = styled.label<StyleTextFieldProps>`
  font-size: 12px;
  font-weight: 700;
  margin-right: ${newTypography.unit}px;
  margin-bottom: 8px;
  ${({ disabled }) =>
    disabled ? `color: ${newGlobalTheme.colors.text.disabledSave}` : `color: ${newGlobalTheme.colors.text.primary}`};
`;

export const StyledInfoTooltip = styled(OldInfoTooltip)`
  display: inline-flex;
  position: relative;
  left: 4px;
`;

export const StyledNumberField = styled(TextField)`
  margin-bottom: ${typography.unit * 4}px;
  input {
    width: 257px;
    font-size: 12px;
  }
  [data-automation-id~='error-message'] {
    white-space: normal;
  }
`;
