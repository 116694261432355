import styled from '@emotion/styled';
import { Icon, IconProps } from '../icon';
import { NewSearchTextField } from '../newTextField/NewTextField';

export const StyledIcon = styled(Icon)<IconProps>`
  display: flex;
`;

export const StyledTextField = styled(NewSearchTextField)<{ width?: string }>`
  width: ${({ width }) => width || '234px'};
`;
