import { useFormContext } from 'react-hook-form';
import { PLUImages } from '../PLUImages/PLUImages';
import { MetadataProps } from './Metadata.consts';
import { FormMode } from 'utils/types';
import { StyledSpecialOptionsSelectbox } from '../../DoeImageForm.style';
import { SPECIAL_OPTIONS } from '../../DoeImageForm.consts';
import DiscountComponent from '../DiscountComponent/DiscountComponent';
import { useEffect } from 'react';
import { MetaDataContainer } from './Metadata.style';
export const Metadata = ({
  onProductSelection,
  defaultTemplateToggle,
  mode,
  shouldShowField,
  specialOptionsItems,
  setMetadataFieldsValidation,
  imageID
}: MetadataProps) => {
  const { control, watch, getValues } = useFormContext();

  const [discountType, value, minPurchase, products, specialOptions] = watch([
    'image.metadata.discount.discountType',
    'image.metadata.discount.value',
    'image.metadata.discount.minPurchase',
    'image.metadata.products',
    'image.metadata.specialOptions',
  ]);

  useEffect(() => {
    let isProductsFieldValid = false;
    if (products && products.length > 0) {
      const errorsPresent = products.filter((productObj: any) => productObj.code === '');
      isProductsFieldValid = errorsPresent.length === 0;
    }
    setMetadataFieldsValidation((prev) => ({ ...prev, products: isProductsFieldValid }));
  }, [products]);

  useEffect(() => {
    let isDiscountFieldPresent = false;
    const { discount } = getValues().image?.metadata || { discount: undefined };
    if (discount && discount?.discountType) {
      isDiscountFieldPresent = true;
    }
    setMetadataFieldsValidation((prev) => ({ ...prev, discount: isDiscountFieldPresent }));
  }, [discountType, value, minPurchase]);

  useEffect(() => {
    const isSpecialOptionsFieldValid = specialOptions && specialOptions.length > 0;
    setMetadataFieldsValidation((prev) => ({ ...prev, specialOptions: !!isSpecialOptionsFieldValid }));
  }, [specialOptions]);

  return (
    <MetaDataContainer>
      {shouldShowField('image.metadata.products') && (
        <PLUImages
          onProductSelection={onProductSelection}
          defaultTemplateToggle={defaultTemplateToggle}
          disabled={mode === FormMode.View}
          modalMode={mode}
          imageID={imageID}
        />
      )}
      {!defaultTemplateToggle && shouldShowField('image.metadata.discount') && (
        <DiscountComponent disabled={mode === FormMode.View} />
      )}
      {!defaultTemplateToggle && shouldShowField(SPECIAL_OPTIONS) && (
        <StyledSpecialOptionsSelectbox
          name={SPECIAL_OPTIONS}
          control={control}
          valueField="key"
          labelField="name"
          disabled={mode === FormMode.View}
          items={specialOptionsItems}
          placeholder="Select"
          label="Special Options"
          multiple={true}
          maxItems={1}
          selectWidth={401}
          version='offer-form'
        />
      )}
    </MetaDataContainer>
  );
};
