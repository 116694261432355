import React, { useEffect, useState } from 'react';
import { DiscountCondition, DiscountConditionRecord, DiscountType, DoubleDippingRuleRecord } from 'utils/types/offers';
import { useFormContext } from 'react-hook-form';
import { calcProductsHint } from 'utils/product';
import useDiscountType from 'hooks/use-discount-type';
import { marketConfig } from 'app/slices/config';
import { useSelector } from 'react-redux';
import {
  EnterValueWrapper,
  ProductPicker,
  StyledForSelectbox,
  StyledLabel,
  StyledLabelUpto,
  StyledLightLabel,
  StyledSelectbox,
} from '../Template.style';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import getProductsAction from '../shared/ProductsActionCondition';
import { Currency } from '../shared/Currency/Currency';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { ProductSetType } from 'utils/types';
import { store } from 'app/store';
import { setData } from 'app/slices/modals';
import { checkForEmptyData } from '../shared/ProductsActionCondition.utils';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { LineWrapper, OfferInfoWrapper, ProductLine } from '../../../OfferForm.style';
import { getIsControlWithError } from 'utils/form';
import OfferTemplateTextField from 'components/shared/textField/OfferTemplateTextField';
import { templateValueIncludeNP } from '../shared/NonProductPLUsSection';

const selectProductsTextLabel = 'Select Products';
const templateValueIncludeNFPC = 'versions.0.templateValues.includeNonFoodProductCodes';
const conditionValueString = 'versions.0.templateValues.conditionValue';

const ExcludeSection = ({
  excludeCodes,
  disabled,
  getValues,
  onProductSelection,
  offerID,
  register,
  onProductPreviewClick,
}: any) => {
  return (
    <LineWrapper>
      <StyledLabel>Exclude</StyledLabel>
      <ProductPicker>
        <ProductLine>{calcProductsHint(excludeCodes)}</ProductLine>
        {getProductsAction(
          disabled,
          excludeCodes,
          getValues,
          selectProductsTextLabel,
          'excludeCodes',
          (data: any) => ({
            excludeCodes: !checkForEmptyData(data) ? data : undefined,
          }),
          onProductSelection,
          true,
          null,
          null,
          offerID,
          onProductPreviewClick,
        )}
        <input
          type="hidden"
          {...register('versions.0.templateValues.excludeCodes', {
            value: excludeCodes,
            shouldUnregister: true,
            setValueAs: (v: any) => (v === '' ? undefined : v),
          })}
        />
      </ProductPicker>
    </LineWrapper>
  );
};
const NonProductPLUsSection = ({
  disabled,
  includeNonProduct,
  setIncludeNonProduct,
  includeNonFoodProductCodes,
  register,
  getValues,
  onProductSelection,
  offerID,
  onProductPreviewClick,
}: any) => {
  return (
    <LineWrapper>
      <StyledLabel>Non-Product PLUs to Include</StyledLabel>
      <ProductPicker>
        <ProductLine>{calcProductsHint(includeNonFoodProductCodes)}</ProductLine>
        {getProductsAction(
          disabled || includeNonProduct,
          includeNonFoodProductCodes,
          getValues,
          selectProductsTextLabel,
          'includeNonFoodProductCodes',
          (data: any) => ({ includeNonFoodProductCodes: !checkForEmptyData(data) ? data : undefined }),
          onProductSelection,
          true,
          ProductSetType.NonFoodProduct,
          null,
          offerID,
          onProductPreviewClick,
        )}
        <input
          type="hidden"
          {...register(templateValueIncludeNFPC, {
            value: includeNonFoodProductCodes,
            shouldUnregister: true,
            setValueAs: (v: any) => (v === '' ? undefined : v),
          })}
        />
      </ProductPicker>
      <Checkbox
        checked={includeNonProduct}
        disabled={disabled}
        onClick={() => {
          setIncludeNonProduct(!includeNonProduct);
          store.dispatch(setData({ data: { isFormDirty: true } }));
          store.dispatch(
            setData({
              data: {
                templateProducts: {
                  includeNonFoodProductCodes: includeNonProduct ? undefined : includeNonFoodProductCodes,
                },
              },
            }),
          );
        }}
        label="Include All"
      />
    </LineWrapper>
  );
};

const WithCondition = ({
  control,
  disabled,
  resetField,
  discountCondition,
  conditionValue,
  getValues,
  conditionProducts,
  offerID,
  register,
  onProductSelection,
  errors,
  onProductPreviewClick,
}: any) => {
  return (
    <LineWrapper>
      <>
        <LineWrapper gap={8}>
          <StyledLabel>With</StyledLabel>
          <StyledForSelectbox
            name="versions.0.templateValues.discountCondition"
            control={control}
            disabled={disabled}
            onChange={() => {
              resetField('versions.0.templateValues.doubleDipping');
            }}
            validation={{
              required: true,
            }}
            items={
              Object.entries(DiscountConditionRecord)
                .filter(
                  ([key]) => key === DiscountCondition.WithPurchaseOf || key === DiscountCondition.MinimumPurchase,
                )
                .map(([key, value]) => {
                  return {
                    id: key,
                    name: value,
                  };
                }) as any[]
            }
            defaultValue={DiscountCondition.WithPurchaseOf}
            initialSelectedItems={discountCondition ? [discountCondition] : [DiscountCondition.WithPurchaseOf]}
            selectWidth={157}
            version="offer-form"
          />
        </LineWrapper>
        <>
          {discountCondition === DiscountCondition.WithPurchaseOf && (
            <PurchaseOfConditionSection
              control={control}
              disabled={disabled}
              conditionValue={conditionValue}
              conditionProducts={conditionProducts}
              getValues={getValues}
              onProductSelection={onProductSelection}
              offerID={offerID}
              register={register}
              onProductPreviewClick={onProductPreviewClick}
            />
          )}
          {discountCondition === DiscountCondition.MinimumPurchase && (
            <>
              <StyledLightLabel>of</StyledLightLabel>
              <EnterValueWrapper
                width={262}
                disabled={disabled}
                errors={getIsControlWithError(conditionValueString, errors)}
              >
                <Currency />
                <OfferTemplateTextField
                  disabled={disabled}
                  register={register}
                  validation={{
                    required: true,
                    min: 0.01,
                    max: null,
                    pattern: /^\d+(?:\.\d{1,2})?$/,
                    validate: (v: string) => !Number.isNaN(Number(v)) && Number(v) >= 0.01,
                  }}
                  errors={errors}
                  name={conditionValueString}
                  placeholder="Enter value"
                  selectWidth={250}
                />
              </EnterValueWrapper>
            </>
          )}
        </>
      </>
    </LineWrapper>
  );
};

const PurchaseOfConditionSection = ({
  control,
  disabled,
  conditionValue,
  conditionProducts,
  getValues,
  onProductSelection,
  offerID,
  register,
  onProductPreviewClick,
}: any) => {
  return (
    <ProductPicker>
      <StyledSelectbox
        control={control}
        name={conditionValueString}
        disabled={disabled}
        validation={{
          min: 1,
          max: 15,
          required: true,
        }}
        items={Array.from(
          { length: 15 },
          (x, i) =>
            ({
              id: (i + 1).toString(),
              name: (i + 1).toString(),
            } as any),
        )}
        defaultValue="1"
        initialSelectedItems={
          conditionValue ? [typeof conditionValue === 'object' ? conditionValue.id : conditionValue] : ['1']
        }
        selectWidth={60}
        version="offer-form"
      />
      <ProductLine>{calcProductsHint(conditionProducts)}</ProductLine>
      {getProductsAction(
        disabled,
        conditionProducts,
        getValues,
        selectProductsTextLabel,
        'conditionProducts',
        (data: any) => ({
          conditionProducts: data,
        }),
        onProductSelection,
        null,
        null,
        null,
        offerID,
        onProductPreviewClick,
      )}
      <input
        type="hidden"
        {...register('versions.0.templateValues.conditionProducts', {
          value: conditionProducts,
          shouldUnregister: true,
          required: true,
        })}
      />
    </ProductPicker>
  );
};

export const OrderDiscount = ({
  disabled,
  onProductSelection,
  offerID,
  offerSource,
  mode,
  onProductPreviewClick,
}: TemplateProps) => {
  const {
    control,
    register,
    watch,
    getValues,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext();

  const { config } = useSelector(marketConfig);
  const isDoeOffer = offerSource === OfferSource.DOE;
  const isEditMode = mode === 'edit';
  const isDoeOfferEditMode = isDoeOffer && isEditMode;
  const doeDisable = disabled || isDoeOfferEditMode;
  const { isPercent, shouldDisplayCurrency } = useDiscountType();

  const [
    orderDiscountType,
    conditionProducts,
    excludeCodes,
    discountCondition,
    conditionValue,
    doubleDipping,
    includeNonFoodProductCodes,
  ] = watch([
    'versions.0.templateValues.orderDiscountType',
    'versions.0.templateValues.conditionProducts',
    'versions.0.templateValues.excludeCodes',
    'versions.0.templateValues.discountCondition',
    conditionValueString,
    'versions.0.templateValues.doubleDipping',
    templateValueIncludeNFPC,
  ]);
  const [includeNonProduct, setIncludeNonProduct] = useState(watch(templateValueIncludeNP));

  useEffect(() => {
    if (discountCondition !== DiscountCondition.MinimumPurchase) {
      return;
    }
    resetField(conditionValueString, { defaultValue: undefined });
  }, [discountCondition]);

  useEffect(() => {
    setValue(templateValueIncludeNP, includeNonProduct);
    if (includeNonProduct) {
      setValue(templateValueIncludeNFPC, undefined);
    }
  }, [includeNonProduct]);

  const getWidth = (percentWidth: number, nonPercentWidth: number) => {
    return isPercent ? percentWidth : nonPercentWidth;
  };
  const getMin = (isPercentVal: boolean) => {
    return isPercentVal ? 1 : 0.01;
  };
  return (
    <OfferInfoWrapper disabled={disabled}>
      <LineWrapper>
        <LineWrapper gap={8}>
          <StyledLabel>Get</StyledLabel>
          <StyledForSelectbox
            name="versions.0.templateValues.orderDiscountType"
            control={control}
            disabled={doeDisable}
            validation={{
              required: true,
            }}
            items={
              Object.entries(DiscountTypeRecord())
                .filter(([key]) => key === DiscountType.MoneyOff || key === DiscountType.Percent)
                .map(([key, value]) => {
                  return {
                    id: key,
                    name: value,
                  };
                }) as any[]
            }
            selectWidth={getWidth(136, 224)}
            defaultValue={DiscountType.MoneyOff}
            initialSelectedItems={orderDiscountType ? [orderDiscountType] : [DiscountType.MoneyOff]}
            version="offer-form"
          />
        </LineWrapper>
        <EnterValueWrapper
          width={getWidth(100, 229)}
          disabled={disabled}
          errors={getIsControlWithError('versions.0.templateValues.orderDiscountValue', errors)}
        >
          {shouldDisplayCurrency && <Currency />}
          <OfferTemplateTextField
            disabled={doeDisable}
            register={register}
            validation={{
              required: true,
              min: getMin(isPercent),
              max: isPercent ? 99 : null,
              pattern: isPercent ? /^([1-9][0-9]?|99)$/ : /^\d+(?:\.\d{1,2})?$/,
              validate: (v: string) =>
                (!Number.isNaN(Number(v)) && Number(v) >= 0.01) || (isPercent && Number(v) < 100 && Number(v) >= 1),
            }}
            errors={errors}
            name="versions.0.templateValues.orderDiscountValue"
            placeholder="Enter value"
            selectWidth={getWidth(100, 210)}
          />
        </EnterValueWrapper>
        {isPercent && (
          <>
            <StyledLabelUpto>Up to</StyledLabelUpto>
            <EnterValueWrapper
              width={120}
              disabled={disabled}
              errors={getIsControlWithError('versions.0.templateValues.orderDiscountLimit', errors)}
            >
              <Currency />
              <OfferTemplateTextField
                disabled={doeDisable}
                register={register}
                validation={{
                  required: false,
                  min: 0,
                  max: null,
                  pattern: /^\d+(?:\.\d{1,2})?$/,
                  validate: (v: string) => {
                    if (v === '') {
                      return true;
                    }
                    return !Number.isNaN(Number(v)) && Number(v) >= 0;
                  },
                }}
                errors={errors}
                name="versions.0.templateValues.orderDiscountLimit"
                placeholder="Enter value"
                selectWidth={115}
              />
            </EnterValueWrapper>
            <InfoTooltip
              id="uptoTooltip"
              className="test"
              content="If the field remains empty or is set to 0, It will be treated as having no limit."
            />
          </>
        )}
      </LineWrapper>
      <WithCondition
        control={control}
        disabled={doeDisable}
        resetField={resetField}
        discountCondition={discountCondition}
        conditionValue={conditionValue}
        getValues={getValues}
        conditionProducts={conditionProducts}
        offerID={offerID}
        register={register}
        onProductSelection={onProductSelection}
        errors={errors}
        onProductPreviewClick={onProductPreviewClick}
      />
      <NonProductPLUsSection
        disabled={disabled}
        includeNonProduct={includeNonProduct}
        setIncludeNonProduct={setIncludeNonProduct}
        includeNonFoodProductCodes={includeNonFoodProductCodes}
        register={register}
        getValues={getValues}
        onProductSelection={onProductSelection}
        offerID={offerID}
        onProductPreviewClick={onProductPreviewClick}
      />
      {(discountCondition === DiscountCondition.MinimumPurchase ||
        discountCondition === DiscountCondition.WithAnyPurchase) && (
        <>
          <ExcludeSection
            excludeCodes={excludeCodes}
            disabled={disabled}
            getValues={getValues}
            onProductSelection={onProductSelection}
            offerID={offerID}
            register={register}
            onProductPreviewClick={onProductPreviewClick}
          />
          <LineWrapper gap={8}>
            <StyledLabel>Double Dipping</StyledLabel>
            <StyledForSelectbox
              name="versions.0.templateValues.doubleDipping"
              disabled={doeDisable}
              control={control}
              validation={{
                required: true,
              }}
              items={
                Object.entries(DoubleDippingRuleRecord).map(([key, value]) => {
                  return {
                    id: key,
                    name: value,
                  };
                }) as any[]
              }
              defaultValue={config.defaultDoubleDippingRule}
              initialSelectedItems={
                doubleDipping
                  ? [typeof doubleDipping === 'object' ? doubleDipping.id : doubleDipping]
                  : [config.defaultDoubleDippingRule]
              }
              selectWidth={380}
              version="offer-form"
            />
          </LineWrapper>
        </>
      )}
    </OfferInfoWrapper>
  );
};
