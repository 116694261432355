import { Impact, ImpactType } from 'components/impact/impactItem/ImpactItem.consts';
import { ApprovalStatus, ApprovalStatusLabel, ApprovalStatusWithLabel } from './types';

export const getUsageReportRows = (campaigns?: any[], offers?: any[], images?: any[], entityType?: string) => {
  const rows = [['Type', 'ID', 'Name', 'Status']];
  if (entityType === 'termCondition') {
    rows[0].push('Default T&C');
    campaigns?.forEach((impact: Impact) =>
    rows.push([
      ImpactType.Campaign,
      impact?.externalId?.toString() || '-',
      impact.title,
      ApprovalStatusLabel[
        impact.status === ApprovalStatus.AssociationStopped
          ? ApprovalStatus.Active
          : (impact.status as ApprovalStatusWithLabel)
      ],
       "NA",
    ]),
  );
  offers?.forEach((impact: Impact) =>
    rows.push([
      ImpactType.Offer,
      impact.id.toString(),
      impact.title,
      ApprovalStatusLabel[impact.status as ApprovalStatusWithLabel],
      impact.defaultTnC,
    ]),
  );
  }
  else{
    campaigns?.forEach((impact: Impact) =>
    rows.push([
      ImpactType.Campaign,
      impact?.externalId?.toString() || '-',
      impact.title,
      ApprovalStatusLabel[
        impact.status === ApprovalStatus.AssociationStopped
          ? ApprovalStatus.Active
          : (impact.status as ApprovalStatusWithLabel)
      ],
    ]),
  );
  offers?.forEach((impact: Impact) =>
    rows.push([
      ImpactType.Offer,
      impact.id.toString(),
      impact.title,
      ApprovalStatusLabel[impact.status as ApprovalStatusWithLabel],
    ]),
  );
  }

  images?.forEach((impact: Impact) =>
    rows.push([
      ImpactType.Image,
      impact.id.toString(),
      impact.title,
      ApprovalStatusLabel[impact.status as ApprovalStatusWithLabel] || 'Unarchived',
    ]),
  );
  return rows;
};
