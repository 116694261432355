import { useForm, useFormContext } from 'react-hook-form';
import { TemplateProps } from '../Template.consts';
import {
  StyledDiv,
  StyledLabel,
  StyledText,
  StyledVoucherGroupBoxColumn,
  StyledVoucherGroupBoxRow,
  StyledVoucherGroupDetailsBox,
  StyledVoucherGroupSelectBox,
} from '../Template.style';
import { useQuery } from '@apollo/client';
import { VoucherGroup, VoucherGroupDetails } from 'utils/types/voucherGroups';
import { DateTimeConfig, FormMode, OrderDirection } from 'utils/types';
import offersGqls from 'pages/offers/offerManagement/Offers.gqls';
import { FetchPolicies } from 'utils/types/common';
import { useEffect, useState } from 'react';
import ProgressLoader from 'pages/shared/progressIndicator/ProgressLoader';
import { getVoucherGroupById } from 'utils/api/offers';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { convertUtcDateToTimezoneDate, extractDate, getMarketDatetime } from 'utils/date';
import { offerFormVersion } from 'components/shared/selectbox/NewSelectbox.consts';

export const NonFoodDigitalRewards = ({ disabled, modalMode, setVoucherDetails }: TemplateProps) => {
  const {
    control,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();
  const { config } = useSelector(marketConfig);
  const { dateFormat, startTimezone, endTimezone } = config;
  const dateTimeConfig: DateTimeConfig = { dateFormat, startTimezone, endTimezone };
  const voucherValue = 'versions.0.templateValues.nonFoodDiscountRewards';
  const voucherGroup = watch(voucherValue);
  const formMethods = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  const [retryAttempt, setRetryAttempt] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchFailed, setIsFetchFailed] = useState(false);
  const [intialVoucherValue] = useState(getValues(voucherValue));

  const [selectedVoucherGroup, setSelectedVoucherGroup] = useState(formMethods.getValues(voucherValue));
  const [items, setItems] = useState<VoucherGroupDetails[]>([]);
  const [voucherFromDb, setVoucherFromDb] = useState<VoucherGroup>();

  const {
    data: voucherGroupsData,
    loading,
    error,
    refetch,
  } = useQuery<{ getAllVoucherGroups: [VoucherGroup] }>(offersGqls.queries.getAllVoucherGroups, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: {},
        order: { name: OrderDirection.ASC },
        limit: 20000,
      },
    },
  });

  useEffect(() => {
    const voucherArray = voucherGroupsData?.getAllVoucherGroups;
    if (voucherArray) {
      setItems(
        voucherArray
          ?.filter(
            (item: VoucherGroup) =>
              getMarketDatetime(endTimezone).getTime() <
              convertUtcDateToTimezoneDate(item.endAtUtc, endTimezone).getTime(),
          )
          ?.map((item: VoucherGroup) => ({
            id: item.voucherGroupId,
            name: item.voucherGroupName,
            startDate: extractDate(item.startAtUtc, dateTimeConfig, startTimezone),
            endDate: extractDate(item.endAtUtc, dateTimeConfig, endTimezone),
          })) || [],
      );
    }
  }, [voucherGroupsData]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (retryAttempt === 0 && !isFetchFailed && error) {
      refetch();
      setRetryAttempt(1);
    } else if (retryAttempt === 1 && error) {
      setIsFetchFailed(true);
    }
  }, [retryAttempt, isFetchFailed, error, refetch]);

  const getVoucherDeatilsById = async (id: number) => {
    const {
      data: { getVoucherGroupById: voucherGroupById },
    }: any = await getVoucherGroupById(id);
    const voucherGroupDetails: VoucherGroupDetails = {
      id: voucherGroupById.voucherGroupId,
      name: voucherGroupById.voucherGroupName,
      startDate: extractDate(voucherGroupById.startAtUtc, dateTimeConfig, startTimezone),
      endDate: extractDate(voucherGroupById.endAtUtc, dateTimeConfig, endTimezone),
    };
    setVoucherFromDb(voucherGroupById);
    setSelectedVoucherGroup(voucherGroupDetails);
    setItems((prev: any) => {
      if (!prev.some((item: any) => item.id === voucherGroupDetails.id)) {
        return [...prev, voucherGroupDetails];
      }
      return prev;
    });
  };

  useEffect(() => {
    const watchedVoucherGroup = getValues(voucherValue);
    if (!selectedVoucherGroup && watchedVoucherGroup) {
      if (![FormMode.New, FormMode.Duplicate].includes(modalMode as FormMode)) {
        getVoucherDeatilsById(Number(watchedVoucherGroup));
      } else {
        setSelectedVoucherGroup(items.find((item) => item.id === watchedVoucherGroup));
        setVoucherFromDb(
          voucherGroupsData?.getAllVoucherGroups?.find((item) => item.voucherGroupId === watchedVoucherGroup) as any,
        );
      }
    }
  }, [selectedVoucherGroup, items]);

  useEffect(() => {
    if (selectedVoucherGroup) {
      setVoucherDetails({
        name: selectedVoucherGroup?.name,
        startDate: voucherFromDb?.startAtUtc,
        endDate: voucherFromDb?.endAtUtc,
      });
    }
  }, [selectedVoucherGroup, setVoucherDetails]);

  const disabledState = disabled || (intialVoucherValue && modalMode === FormMode.Edit);

  return (
    <>
      <div>
        <StyledVoucherGroupBoxColumn>
          <StyledVoucherGroupBoxRow>
            <StyledLabel disabled={disabledState}>
              Voucher Group
            </StyledLabel>
            <StyledVoucherGroupSelectBox
              key={`voucherGroups_${items.length}`}
              name="versions.0.templateValues.nonFoodDiscountRewards"
              control={control}
              disabled={disabledState}
              validation={{ required: true }}
              items={items}
              placeholder="Select Voucher Group"
              selectWidth={300}
              errors={(() => {
                if (!isLoading && (items.length === 0 || (isFetchFailed && error))) {
                  return 'No voucher group available for selection';
                }
                if (
                  selectedVoucherGroup &&
                  getMarketDatetime(endTimezone).getTime() >
                    convertUtcDateToTimezoneDate(voucherFromDb?.endAtUtc, endTimezone).getTime()
                ) {
                  return 'Selected voucher group is expired';
                }
                return errors;
              })()}
              defaultValue={voucherGroup}
              version={offerFormVersion}
              initialSelectedItems={voucherGroup ? [voucherGroup] : undefined}
              onChange={(selectedVoucherGroupFromList: any) => {
                setSelectedVoucherGroup(selectedVoucherGroupFromList);
                setVoucherFromDb(
                  voucherGroupsData?.getAllVoucherGroups?.find(
                    (item) => item.voucherGroupId === selectedVoucherGroupFromList.id,
                  ) as any,
                );
              }}
            ></StyledVoucherGroupSelectBox>
            {isLoading && <ProgressLoader size={24} toolTipRequired={false} type="loader" thickness={4} />}
          </StyledVoucherGroupBoxRow>
          {selectedVoucherGroup && (
            <StyledVoucherGroupDetailsBox className="voucher-group-details-container">
              <StyledVoucherGroupBoxRow>
                <StyledLabel disabled={disabledState}>
                  Voucher Name:
                </StyledLabel>
                <StyledText disabled={disabledState}>
                  {selectedVoucherGroup.name}
                </StyledText>
              </StyledVoucherGroupBoxRow>
              <StyledDiv />
              <StyledVoucherGroupBoxRow>
                <StyledLabel disabled={disabledState}>
                  Start Date:
                </StyledLabel>
                <StyledText disabled={disabledState}>
                  {selectedVoucherGroup.startDate}
                </StyledText>
                <StyledLabel disabled={disabledState}>
                  End Date:
                </StyledLabel>
                <StyledText disabled={disabledState}>
                  {selectedVoucherGroup.endDate}
                </StyledText>
              </StyledVoucherGroupBoxRow>
            </StyledVoucherGroupDetailsBox>
          )}
        </StyledVoucherGroupBoxColumn>
      </div>
    </>
  );
};
