import styled from '@emotion/styled';
import {  ButtonText, NewButtonText } from 'components/shared/button';
import { Icon, IconProps } from 'components/shared/icon';
import { ImageFormContainerProps } from './DoeImageForm.consts';
import Modal from 'components/shared/modal/Modal';
import ControlledToggleSwitch from 'components/shared/controlledToggleSwitch/ControlledToggleSwitch';
import { StyledWithThemeProps } from 'utils/types';
import  { newTypography } from 'styles/typography';
import NewTextField from 'components/shared/newTextField/NewTextField';
import TextArea from 'components/shared/textArea/TextArea';
import { NewStyledTooltip } from 'components/shared/tags/NewTags.style';
import { StyledGoldButton } from 'pages/offers/offerManagement/components/offerForm/OfferForm.style';
import zIndex from 'styles/zIndex';
import { StyledButtonsContainer } from 'components/impact/impactModal/ImpactModal.style';
import { OfferFormTextField } from 'components/shared/textField/OfferFormTextField';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import DOEImageModal from 'components/shared/modal/DOEImageModal';
import CheckboxGroup from 'components/shared/checkboxGroup/CheckboxGroup';
import { OfferFormTextArea } from 'components/shared/textArea/OfferFormTextArea';
import OfferToolTip from 'components/shared/tooltip/OfferToolTip';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const StyledImagesFormModal = styled(DOEImageModal) <{ isExpanded: boolean }>`
  max-height: ${({ isExpanded }) => (isExpanded ? '75%' : '85%')};
  border-radius: 5px 5px 5px 5px;
  opacity: 0px;
  display: flex;
  flex-direction: column;
  min-width: 602px;
  min-height: 500px;
  padding: ${newTypography.unit * 9}px 0px 0px 0px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity ${newTypography.transition}s;
  z-index: ${zIndex.modal};
  background: ${({ theme }) => theme.colors.global.background};
  border-radius: ${newTypography.borderRadius}px;
  box-shadow: 0 4.8px 14.4px rgba(0, 0, 0, 0.18), 0 25.6px 57.6px rgba(0, 0, 0, 0.22);
  h3 {
    font-family: ${newTypography.primaryFont};
    font-size: 28px;
    font-weight: 700;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    position:relative;
  }

  .ModalHeader {
    width: 530px;
    margin-left: ${newTypography.unit * 9}px;
  }

  div[data-automation-id="modal-content"] {
    padding-left: ${newTypography.unit * 9}px;

  }

`;
export const Forms = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
    border: 3px transparent solid;
    background-clip: padding-box;
    border-radius: 5px;
  }
`;

export const ImageFormContainer = styled.div<ImageFormContainerProps>`
  border-radius: ${newTypography.borderRadius}px;
  margin-bottom: ${newTypography.unit}px;
  padding: ${newTypography.unit * 2}px 0;
  text-overflow: ellipsis;
  min-width: 530px;
  height: 820px;
  top: 898px;
  left: 1642px;
  padding: 36px 0px 0px 0px;
  gap: 36px;
  border-radius: 5px 0px 0px 0px;
  opacity: 0px;
`;

export const DoeTopContainer = styled.div`
  width: 541px;
  display:flex;
  flex: 1;
  column-gap:10px;
  align-items: start;
  gap: ${newTypography.unit * 3}px;
  flex-direction: row;
`;

export const ImageUploadContainer = styled.div`
  display:flex;
  flex-direction: column;
  align-items: start;
  align-items: start;
  overflow: hidden;
  gap: ${newTypography.unit * 3}px;
  button {
    width: 100px;
    height: 23px;
    font-family: ${newTypography.primaryFont};
    font-style: normal;
    font-weight: 700;
    font-size: 14px;    
    line-height: 23px;
    letter-spacing: -0.03em;
    color: #006BAE;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;

export const BottomFormContent = styled.div`
position:relative;
top:30px;
width: 348px;
display:flex;
flex-direction:column;
gap: 0px;
opacity: 0px;
`;

export const FooterFormContent = styled.div<{defaultTemplateToggle? : boolean }>`
  display:flex;
  margin: ${newTypography.unit * 4}px 0px ${newTypography.unit * 4}px 1px;
  gap: 8px;
  diplay:flex;
  left:10px;
  border-radius: 5px 5px 5px 5px;
  opacity: 0px;
  ${({defaultTemplateToggle}) => !defaultTemplateToggle && `box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 5.3px 1px;`}
  width: 530px;
`;

export const FormContent = styled.div`
position:relative;
left:5px;
width: 348px;
display:flex;
flex-direction:column;
gap: ${newTypography.unit * 4}px;
opacity: 0px;
& > *:last-child {
    margin-bottom: 0;
  }

  & > *:nth-last-child(2) {
    margin-bottom: -10px; 
  }
`;

export const StyledControlledToggleSwitch = styled(ControlledToggleSwitch)`
  margin-bottom: ${newTypography.unit * 7}px;
  width: 150px;
`;

export const RemoveButton = styled(ButtonText)`
  position: absolute;
  top: ${newTypography.unit * 2}px;
  right: ${newTypography.unit * 2}px;
`;

export const RemoveIcon = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.text.secondary};
  width: 14px;
`;

export const StyledSelectbox = styled(NewSelectbox)`
  display: flex;
  opacity: 0px;
  width: 530px;
` as unknown as typeof NewSelectbox;

export const StyledSpecialOptionsSelectbox = styled(NewSelectbox)`
  display: flex;
  opacity: 0px;
` as unknown as typeof NewSelectbox;

export const StyledListSelectbox = styled(NewSelectbox)`
  width: fit-content;
` as unknown as typeof NewSelectbox;

export const StyledTextField = styled(NewTextField)`
  min-width: 50px;
  input {
    width: 50px;
  }
`;

export const FileDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 16px; 
`;

export const FileDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
`;

export const PropName = styled.div<StyledWithThemeProps>`
  color: ${newGlobalTheme.colors.text.disabled};
  font-size: 13px;
`;

export const PropValue = styled.div<StyledWithThemeProps>`
  color: ${newGlobalTheme.colors.text.disabled};
  font-size: 12px;
  white-space: wrap;
  word-wrap: break-word;
`;
export const DoeImageContainer = styled.div<StyledWithThemeProps>`
  gap: 0px;
  border-radius: 5px 5px 5px 5px;
  opacity: 0px;
  width: 166px;
  height: 290px;
  padding: ${newTypography.unit * 2}px;
  margin: ${newTypography.unit * 0.5}px ${newTypography.unit * 0.5}px ${newTypography.unit * 0.5}px ${newTypography.unit * 0.5}px;
  background-color: ${({ theme }) => theme.colors.global.imageBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow:hidden;
  background: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 5.3px 2px;
`;

export const DoeImageName = styled(OfferFormTextField)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  margin-bottom: 21px;
  & label {
    width: 75px;
    height: 15px;
    font-family: ${newTypography.primaryFont};
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #292929;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  & input {
    width: 348px;
    height:35px;
    font-family: ${newTypography.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.17px;
    display: flex;
    align-items: center;
    color: #292929;
    background:none;
    padding-left:0px;
    flex: none;
    order: 0;
    flex-grow: 1;
  }  
    .input-wrapper {
      background-color: white;
    }
  div[role='alert'] {
      color: #D90007 !important; 
      margin-top: 39px; 
      outline: none !important;
  }
`;
export const DoeImageDescription = styled(OfferFormTextArea)`
      div[role='alert'] {
      color: #D90007 !important; 
      
  }
`;

export const DoeImageTags = styled(NewSelectbox)`
   display: flex;
   margin-bottom: 21px;
` as unknown as typeof NewSelectbox;

export const CancelButton = styled(NewButtonText)`
  width: 44px;
  height: 23px;
  gap: 0px;
  opacity: 0px;
  font-family: ${newTypography.primaryFont};
  font-size: 14px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1D6CA9;
`;

export const NewDoeStyledTooltip=styled(OfferToolTip)`
button{
font-size: 14px;
font-weight: 700;
}
`;

export const NewDoeSaveButton = styled(StyledGoldButton)`
width: 54px;
height: 32px;
padding: 8px 12px 8px 12px;
gap: 8px;
border-radius: 5px 5px 5px 5px;
border: 1px 0px 0px 0px;
opacity: 0px;
color: black;
background-color: #FFBC0D;
border: 1px solid #C08B00;
`;
export const DoeButtonContainer = styled(StyledButtonsContainer)`
  margin: 0px ${newTypography.unit * 9}px ${newTypography.unit * 9}px 0px;
  display: flex;
  justify-content: space-between; ;
  align-items: center;
  gap: ${newTypography.unit * 5}px;
  flex:1 1;
  flex-direction:row;
`

export const StyledCheckboxGroup = styled(CheckboxGroup)`
  margin-top: ${newTypography.unit * 3}px;
`

