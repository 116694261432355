import React, { useEffect } from 'react';
import {
  ExchangeLimitContainer,
  StyledNumberField,
  StyledInfoTooltip,
  StyledLabel,
  StyledLabelTooltipContainer,
  FormRow,
} from './ExternalVoucher.style';
import { useFormContext, useFormState } from 'react-hook-form';
import { ScheduleSectionProps } from '../../ScheduleSection.consts';
import { CampaignType } from 'utils/types/campaigns';
import { convertUtcDateToTimezoneDate, getMarketDatetime, getMarketNextDayDatetime } from 'utils/date';
import { FormMode } from 'utils/types';
import {
  shouldTriggerCampaignScheduleValidation,
  shouldTriggerGlobalLimitValidation,
  validateCustomerLifeTimeLimit,
  validateGlobalLimit,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/scheduleSection/ScheduleSection.util';
import { isUndefinedOrEmpty } from 'utils/form';
import { getVoucherDetails } from 'pages/campaigns/campaignManagement/components/campaignForm/utils/CampaignActions';

const ExternalVoucherDetails = ({
  mode,
  status,
  isDisabled,
  dateTimeConfig,
  className,
  voucherState,
}: ScheduleSectionProps) => {
  const { startTimezone, endTimezone } = dateTimeConfig;
  const { register, unregister, watch, trigger, setValue, getValues } = useFormContext();
  const { errors } = useFormState();
  const {
    isExternalVoucher,
    setIsExternalVoucher,
    voucherApiLoading,
    setVoucherError,
    voucherDetails,
    setVoucherDetails,
  } = voucherState;

  const scheduleGlobalLimit = 'schedule.exchangeLimit.globalLimit';
  const scheduleCustomerLifetimeLimit = 'schedule.exchangeLimit.customerLifetimeLimit';

  const [globalLimit, customerLifetimeLimit, campaignType, offerTemplateValues] = watch([
    scheduleGlobalLimit,
    scheduleCustomerLifetimeLimit,
    'type',
    'offerVersion.templateValues',
  ]);

  useEffect(() => {
    if (!isUndefinedOrEmpty(String(customerLifetimeLimit))) {
      trigger(scheduleCustomerLifetimeLimit);
    }
  }, [globalLimit]);

  useEffect(() => {
    if (voucherDetails) {
      if ([FormMode.New, FormMode.Duplicate].includes(mode)) {
        const start = convertUtcDateToTimezoneDate(voucherDetails.startAtUtc, startTimezone);
        const end = convertUtcDateToTimezoneDate(voucherDetails.endAtUtc, endTimezone);
        setValue(
          'schedule.campaignStart',
          start > getMarketDatetime(startTimezone) ? start : getMarketNextDayDatetime(startTimezone, true),
        );
        setValue('schedule.campaignEnd', end);
      } else if (shouldTriggerCampaignScheduleValidation(mode, status)) {
        trigger(['schedule.campaignEnd', 'schedule.campaignStart']);
      }
      if (shouldTriggerGlobalLimitValidation(globalLimit, mode, status)) {
        trigger(scheduleGlobalLimit);
      }
    }
  }, [voucherDetails, mode]);

  useEffect(() => {
    if (offerTemplateValues?.templateType === '14') {
      trigger('type');
      unregister(['restaurantEligibility', 'schedule.recurrance']);
      getVoucherDetails(mode, +offerTemplateValues?.nonFoodDiscountRewards, voucherState);
    }
  }, [offerTemplateValues]);

  useEffect(() => {
    if (campaignType && campaignType !== CampaignType.LoyaltyExternalVoucher && isExternalVoucher) {
      if (offerTemplateValues?.templateType === '14') {
        setValue('offerVersion', null);
        if (getValues('title')) {
          trigger();
        }
      }
      unregister('schedule.exchangeLimit');
      setVoucherError(null);
      setVoucherDetails(null);
      setIsExternalVoucher(false);
    } else if (campaignType === CampaignType.LoyaltyExternalVoucher) {
      setIsExternalVoucher(true);
      if (offerTemplateValues && offerTemplateValues?.templateType !== '14') {
        setValue('offerVersion', null);
      }
      if (!customerLifetimeLimit) {
        setValue(scheduleCustomerLifetimeLimit, '');
      }
      if (!globalLimit) {
        setValue(scheduleGlobalLimit, '');
      }
    }
  }, [campaignType]);

  return (
    isExternalVoucher && (
      <ExchangeLimitContainer className={className} data-automation-id="external-voucher-section">
        <FormRow>
          <StyledLabelTooltipContainer>
            <StyledLabel disabled={isDisabled}>Global Limit*</StyledLabel>
            <StyledInfoTooltip>Available Count: {voucherDetails?.availableCount ?? ''}</StyledInfoTooltip>
          </StyledLabelTooltipContainer>
          <StyledNumberField
            register={register}
            name="schedule.exchangeLimit.globalLimit"
            validation={{
              pattern: { value: /^[1-9][0-9]*$/, message: 'Number must be larger than 0' },
              validate: (value: string) =>
                validateGlobalLimit(typeof value === 'number' ? String(value) : value, voucherDetails?.availableCount),
            }}
            errors={errors}
            disabled={isDisabled || voucherApiLoading}
            type="number"
          />
        </FormRow>
        <FormRow>
          <StyledLabel disabled={isDisabled}>Customer Lifetime Limit*</StyledLabel>
          <StyledNumberField
            register={register}
            name="schedule.exchangeLimit.customerLifetimeLimit"
            validation={{
              pattern: { value: /^[1-9][0-9]*$/, message: 'Number must be larger than 0' },
              validate: (value: string) =>
                validateCustomerLifeTimeLimit(
                  typeof value === 'number' ? String(value) : value,
                  globalLimit,
                  !!voucherDetails,
                ),
            }}
            errors={errors}
            disabled={isDisabled || voucherApiLoading}
            type="number"
          />
        </FormRow>
      </ExchangeLimitContainer>
    )
  );
};

export default ExternalVoucherDetails;
