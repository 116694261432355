import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import ErrorComponent from '../error/Error';
import { StyleMultipleChoiceProps, MultipleChoiceProps } from './MultipleChoice.consts';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const StyledContainer = styled.div<MultipleChoiceProps>`
  display: flex;
  flex-direction: ${({ labelIsHorizontal }) => labelIsHorizontal && 'column'};
  position: relative;
`;

export const StyledMultipleChoiceContainer = styled.ul<MultipleChoiceProps>`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: ${typography.unit * 2}px 0;
  font-size: 12px;
  width: 530px;
`;

export const ItemLabel = styled.label<StyledWithThemeProps>``;

export const ItemInput = styled.input<StyledWithThemeProps &{disabled:boolean}>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 65px;
  height: 27px;
  margin: 0;
  &:checked ~ label {
    color: ${({ disabled }) => (disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary)};
    border-color: ${newGlobalTheme.colors.global.brandMain};
    border-color : color: ${({ disabled }) => (disabled ? newGlobalTheme.colors.global.disabledGold : newGlobalTheme.colors.global.brandMain)};
    border-width: 2px;
    font-weight: 700;
  }
`;

export const StyledChoiceItem = styled.div<StyleMultipleChoiceProps>`
  margin-right: ${typography.unit}px;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  color: ${({ theme, disabled }) => disabled && theme.colors.text.disabled};

  position: relative;
  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.17px;
    color: ${newGlobalTheme.colors.global.borderColorboxes};
    padding: 6px 10px 6px 10px;
    gap: 4px;
    background-color: ${({ theme }) => theme.colors.global.background};
    border: ${({ disabled }) =>
      `1px solid ${
        disabled ? newGlobalTheme.colors.checkbox.disabledCheckedBg : newGlobalTheme.colors.global.inputDisabledSlide
      }`};
    border-radius: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 27px;
  }
`;

export const StyledLabel = styled.label<MultipleChoiceProps & {disabled:boolean}>`
  text-transform: capitalize;
  font-size: 13px;
  color: ${({ disabled }) => (disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary)};
  display: flex;
  align-items: center;
  margin-right: ${({ labelIsHorizontal }) => !labelIsHorizontal && `${typography.unit * 3}px`};
  margin-bottom: ${({ labelIsHorizontal }) => labelIsHorizontal && `${typography.unit}px`};
`;

export const StyledError = styled(ErrorComponent)`
  margin-top: 2px;
`;
