import styled from '@emotion/styled';
import typography from 'styles/typography';
import TextField from 'components/shared/textField/TextField';
import { DateTimePicker } from 'components/shared/picker/datetimePicker/DateTimePicker';
import {
  LanguageNavigationItemProps,
  StyledNotificationLanguageProps,
  StyledNotificationTextFields,
} from './PushNotificationSection.consts';
import TextArea from 'components/shared/textArea/TextArea';
import { appTheme } from 'styles/themes/defaultTheme';
import { TimePicker } from 'components/shared/picker/timePicker/TimePicker';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { OfferFormTextArea } from 'components/shared/textArea/OfferFormTextArea';
import { OfferFormTextField } from 'components/shared/textField/OfferFormTextField';

export const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const Section = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: ${typography.unit * 5}px;
`;

export const StyledDateTimePicker = styled(DateTimePicker)<{version?: string, disabled?: boolean}>`
  margin-right: ${typography.unit * 5}px;
  width: 100%;
  font-weight:700;

  label {
    font-size: 12px; 
  }

  .MuiInputBase-root {
      height: 36px; 
       input {
        font-size: 12px; 
      }
  }
  .MuiInputBase-root:before {
      ${({disabled, version}) => disabled && version === 'campaign-form' && 'border-bottom-color: #ADADAD; :hover { border-bottom-color: #ADADAD; } '}
  }

  [data-automation-id~='error-message'] {
    font-size: 10px;
    margin-top: 29px;
  }
`;

export const PushNotificationContainer = styled.div<{ hasExtraMargin?: boolean }>`
  display: flex;
  margin-bottom: ${({ hasExtraMargin }) => (hasExtraMargin ? `${typography.unit * 7}px` : `${typography.unit * 3}px`)};

  div:last-of-type {
    margin-right: 0;
  }
`;

export const ExpirationDate = styled(TextField)`
  min-width: 50px;
  input {
    width: 50px;
  }
`;

export const FormRow = styled.div<{marginBottom?: number, marginTop?: number, alignItems?: string}>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({marginBottom})=> `${marginBottom||8}px`};
  margin-top: ${({marginTop})=>`${marginTop|| 0}px`};
  align-items: ${({alignItems})=>alignItems||`baseline`};
  height:36px;
`;

export const PushNotificationRow = styled(FormRow)`
  height: 23px;
`;

export const SubHeader = styled.div`
  display: flex;
  width: 530px;
  height: 23px;
  gap: 8px;
  opacity: 0px;
`;

export const ToggleTitle = styled.div<{disabled:boolean}>`
  margin-right: ${typography.unit * 2}px;
  font-weight: 600;
  font-family: Speedee;
  font-size: 12px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  color: ${({ disabled }) => (disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary)};
`;

export const LanguageNavigationContainer = styled.div`
  display: flex;
  width: 100%;
  height:31px;
  font-size: 12px; 
`;

export const LanguageContentContainer = styled.div<{ theme?: appTheme }>`
  flex-direction: row;
  width: 530px;
  &.selected {
    border-top: ${({ theme }) => `1px solid ${theme.colors.global.border}`};
    font-weight:700;
  }

`;

export const StyledContainer = styled.div`
  width: 100%;
`;

export const LanguageNavigationItem = styled.div<LanguageNavigationItemProps>`
  display: flex;
  font-size: 12px !important; 
  padding: ${typography.unit}px 0;
  margin-right: ${typography.unit * 3}px;
  border-bottom: ${({ isActive, theme }) => isActive && `2px solid ${theme.colors.global.brandMain}`};
  font-weight: ${({ isActive }) => isActive && 700};
  cursor: pointer;
  font-size: ${typography.fontSize}px;
  text-transform: capitalize;
  color: ${({ isActive }) => isActive ? newGlobalTheme.colors.text.primary : newGlobalTheme.colors.text.disabledSave};

`;

export const StyledLabel = styled.label<LanguageNavigationItemProps>`
  font-size: 13px;
  margin-bottom: ${typography.unit * 2}px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const NotificationLanguage = styled(TextArea)<StyledNotificationLanguageProps>`
  width: 100%;
  display: none;
  height:59px;
  margin-top:16px;
`;

export const NotificationTitleStyledTextField = styled(OfferFormTextField)<StyledNotificationTextFields>`
  margin-bottom:16px;
  margin-top:16px;
  width: 257px;
  height:59px;
  display: ${({ show }) => !show && 'none'};
`;

export const StyledTextArea = styled(OfferFormTextArea)<StyledNotificationLanguageProps>`
  margin-bottom: ${typography.unit * 4}px;
  margin-top: ${typography.unit * 2}px;
  display: ${({ show }) => !show && 'none'};a
  font-size: 12px;
  font-weight: 700;
  line-height: 15.17px;
  text-align: left;

  placeholder {
      color: #ADADAD; 
  }

`;

export const StyledTimePicker = styled(TimePicker)`
  margin-right: ${typography.unit * 5}px;

  label {
    font-size: 12px; 
  }

  .MuiInputBase-root {
      height: 36px; 
      input {
        font-size: 12px; 
      }
  }

  [data-automation-id~='error-message'] {
    margin-top: 50px;
    left: 115px;
  }
`;

export const DashedLine = styled.div`
  margin-top: 36px;
  border: 0.8px dashed transparent; /* Transparent border to define size */
  border-image-source: repeating-linear-gradient(
    90deg, #6F6F6F 0px, #6F6F6F 9px, transparent 9px, transparent 16px
  );
  border-image-slice: 1;
  width: 100%;
 `
// export const DashedLine = styled(Icon)`
// width: 100%;
// `

export const AddNotificationButton = styled.button`
  margin-top: 36px;
  border-radius: 24px;
  padding: 8px 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #ADADAD;
  background-color: #FFFFFF;
font-family: Speedee;
font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: -0.15000000596046448px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #292929;
`
export const InLineToastContainer = styled.div`
  margin-top: 36px;
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #6F6F6F;
  border-left-color: #6F6F6F;
  border-left-width: 6px;
  padding: 16px 24px 16px 16px;
  display: flex;
  justify-content: space-between;
  background-color: #F9F9F9;
`
export const InLineTM = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`