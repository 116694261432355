import styled from '@emotion/styled/macro';
import typography, {newTypography} from 'styles/typography';
import Banner from 'components/shared/notifications/banner/Banner';
import { StyledWithThemeProps, StyledWithNewThemeProps } from 'utils/types';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const StyledBanner = styled(Banner)<StyledWithThemeProps>`
  margin-bottom: ${typography.unit * 3}px;
`;

export const NewStyledBanner = styled(Banner)<StyledWithNewThemeProps & { haveCampaign?: boolean }>`
  margin-bottom: ${newTypography.unit * 3}px;
  width: ${({ haveCampaign }) => (haveCampaign ? '100%' : '467.5px')};
  max-height: 72px;
  padding: 12px;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid #C08B00;
  background: #FFFEF5;
  opacity: 0px;
`;

export const StyledBannerContent = styled.div<StyledWithThemeProps>`
  font-size: ${typography.fontSize}px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const NewStyledBannerContent = styled.div<StyledWithNewThemeProps>`
  font-size: 14px;
  color: #292929;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.03em;
  text-align: left;
  width: 100%;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const StyledSpan = styled.span`
  margin-top: ${typography.unit * 5}px;
  margin-bottom: ${typography.unit * 3}px;
  font-size: ${typography.fontSize}px;
`;

export const NewStyledSpan = styled.span`
  margin-top: ${typography.unit * 1}px;
  margin-bottom: ${typography.unit * 3}px;
  font-size: ${typography.fontSize}px;
`;

export const NewStyledBannerImage = styled(Banner)<StyledWithNewThemeProps & { haveCampaign?: boolean }>`
  margin-bottom: ${newTypography.unit * 6}px;
  min-width: 395px;
  max-width: 1000px;
  max-height: 72px;
  padding: 10px;
  padding-right: 15px;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid ${newGlobalTheme.colors.button.new.border};
  background:${newGlobalTheme.colors.notifications.background.newWarning};
  opacity: 0px;
`;

export const NewStyledBannerContentImage = styled.div<StyledWithNewThemeProps>`
  font-size: 14px;
  color: ${newGlobalTheme.colors.text.primary};
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.03em;
  text-align: left;
  min-width: 290px;
  text-decoration-skip-ink: none;
`;