import styled from '@emotion/styled';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Icon, IconProps } from 'components/shared/icon';
import { defaultTheme } from 'styles/themes/defaultTheme';
import InputFiles from 'components/shared/inputFiles/InputFiles';
import NewPageContainer from 'pages/shared/pageContainer/NewPageContainer';
import { StyledTotalAmount } from 'components/shared/totalAmount/NewTotalAmount.style';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { ThemeProps } from '../termsConditions/TermsConditions.consts';
import { newTypography } from 'styles/typography';

export const ImagePageContainer = styled(NewPageContainer)`
    margin: 0px 80px;
`;
export const ImageNewStyledHeader = styled.header<ThemeProps>`
  display: flex;
  flex-direction: column;
  padding: ${newTypography.unit * 16.25}px ${newTypography.unit * 6}px 0 ${newTypography.unit * 6}px;
  width:94%;
  padding-bottom:30px;
  margin-left: auto;
  margin-right:auto;

`;
export const ImagesListWrapper = styled.div`
  overflow: auto;
`;

export const ImagesList = styled(InfiniteScroll)`
  display: flex;
  flex-wrap: wrap;
  padding: 0 ${newTypography.unit * 5}px ${newTypography.unit * 10}px ${newTypography.unit * 6}px;
  overflow-y: auto;
  column-gap: ${newTypography.unit * 9}px;
  row-gap: ${newTypography.unit * 6}px;
  margin-left: 50px;
`;

export const StyledInputFiles = styled(InputFiles)`
  padding: ${newTypography.unit * 3}px;
  width: 36px;
  height: 36px;
  gap: 0px;
  border-radius: 5.02px;
  opacity: 0px;
  padding: ${newTypography.unit * 2}px;
  border: 1.26px solid #C08B00;
  background-color: #FFBC0D;
  :hover{
      border: 1.26px solid #C08B00;
      background-color: #FFBC0D;
}
      . MuiSvgIcon-root{
      fill:black;
      }
`;

export const StyledPlus = styled(Icon) <IconProps>`
  fill: ${defaultTheme.colors.text.white};
  width: 10px;
   
`;
export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: ${newTypography.unit * 4}px;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: -50px;
`;

