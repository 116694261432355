import React from 'react';
import { ButtonBaseProps, NewActionButtonBaseProps, NewButtonBaseProps } from '../buttonBase/ButtonBase.consts';
import {
  ImageCancelStyledButtonBase,
  StyledButtonBase,
  StyledButtonBaseAdd,
  StyledButtonBaseCancel,
  StyledButtonBaseHeader,
  StyledButtonBaseSelect,
  StyledNewButtonBase,
  StyledRestoreButtonBase,
} from './ButtonText.style';

export const ButtonText = (props: ButtonBaseProps) => {
  return <StyledButtonBase {...props} />;
};

export const ImageCancelButtonText = (props: ButtonBaseProps) => {
  return <ImageCancelStyledButtonBase {...props} />;
};

export const ButtonTextHeader = (props: ButtonBaseProps) => {
  return <StyledButtonBaseHeader {...props} />;
};

export const ButtonTextSelect = (props: ButtonBaseProps) => {
  return <StyledButtonBaseSelect {...props} />;
};


export const ButtonTextCancel = (props: ButtonBaseProps) => {
  return <StyledButtonBaseCancel {...props} />;
};



export const NewButtonText = (props: NewButtonBaseProps) => {
  return <StyledNewButtonBase {...props} />;
};

export const NewActionButtonText = (props: NewActionButtonBaseProps) => {
  return <StyledNewButtonBase {...props} />;
};

export const ButtonTextAdd = (props: ButtonBaseProps) => {
  return <StyledButtonBaseAdd {...props} />;
};

export const RestoreButtonText = (props: NewButtonBaseProps) => {
  return <StyledRestoreButtonBase {...props} />;
};
