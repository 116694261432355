import { useOperationCounter } from 'app/apollo/operationCounter';
import { ImageModalProps } from './Modal.const';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '../backdrop/Backdrop';
import { ImageContainer } from 'pages/settings/images/components/imageForm/ImageForm.style';
import { Loading } from './NewModal.style';
import { Loader } from '../loader';
import difference from 'lodash/difference';
import LockIcon from 'pages/shared/lockIcon/LockIcon';
import { FormMode } from 'utils/types';
import { ImageSource } from 'pages/settings/images/Images.consts';
import { StyledButtonDropdown } from 'pages/offers/offerManagement/components/offerForm/OfferForm.style';
import { ArchiveImageActionsList, ImageActionsList, ImageActionsListForViewer } from 'utils/types/images';
import { ImageContent, ImageModalHeader, ImageTitle } from './ImageModal.style';
import NewTextModalTitle from '../text/textModalTitle/NewTextModalTitle';
import { UserRole } from 'utils/types/users';
import { users } from 'app/slices/users';
import { useSelector } from 'react-redux';
import { handleKeyDownModal } from 'utils/modal';

export const ImageModal = ({
  children,
  mode,
  title,
  subtitle,
  onClose,
  isLocked = false,
  className,
  withLoader = true,
  ignoreOperations = [],
  modalMode,
  showMoreActions = true,
  isArchive,
  contentWidth,
  onActionClicked,
  imageSource = ImageSource.VCE,
  fromOfferScreen = false,
  ...rest
}: ImageModalProps) => {
  const { reqs: pendingRequests } = useOperationCounter();
  const { user } = useSelector(users);

  useEffect((): (() => void) => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const headerRef = useRef(null); 

  useEffect(() => {
    // Focus on the header when the modal opens
    if (headerRef.current) {
      headerRef.current.focus();
    }
    // add event listener to prevent focus from leaving the modal 
    const querySelector = (modalMode === FormMode.New || !modalMode) ? 'modal' : 'image-form'
    const keyDownListener : any = (event: KeyboardEvent) => handleKeyDownModal(event, querySelector);

    document.addEventListener('keydown', keyDownListener);

    return () => {
      document.removeEventListener('keydown', keyDownListener)
    }
  }, [title, modalMode]);


  return ReactDOM.createPortal(
    <>
      <Backdrop {...(onClose && { onClick: onClose })} />
      <ImageContainer className={className} data-automation-id="modal" {...rest}>
        {withLoader && (
          <Loading isLoading={difference(pendingRequests, ignoreOperations).length > 0}>
            <Loader />
          </Loading>
        )}
        <>
          <ImageModalHeader ref={headerRef} tabIndex={0} >
            <ImageTitle>
              {title && <NewTextModalTitle text={title} />}
              {isLocked && <LockIcon />}
              {modalMode === FormMode.View && !fromOfferScreen && (
                <>
                  {showMoreActions && (
                    <StyledButtonDropdown
                      version="image"
                      items={
                        !isLocked && mode !== FormMode.View
                          ? isArchive
                            ? (Object.values(ArchiveImageActionsList) as any[])
                            : user.role != UserRole.Viewer
                            ? (Object.values(ImageActionsList) as any[])
                            : (Object.values(ImageActionsListForViewer) as any[])
                          : []
                      }
                      onItemClick={(item: any) => onActionClicked(item.name)}
                      position="bottom-left"
                    ></StyledButtonDropdown>
                  )}
                </>
              )}
            </ImageTitle>
            {subtitle ?? null}
          </ImageModalHeader>
          <ImageContent contentWidth={contentWidth} data-automation-id="modal-content">
            {children}
          </ImageContent>
        </>
        ,
      </ImageContainer>
    </>,
    document.querySelector('#modal'),
  );
};

export default ImageModal;
