import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import NewModal from 'components/shared/modal/NewModal';
import { OfferFormTextField } from 'components/shared/textField/OfferFormTextField';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { ModalProps } from 'components/shared/modal/Modal.const';
import zIndex from 'styles/zIndex';

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${typography.unit * 4}px;
  align-items: baseline;
`;

export const EntityCheckboxFormRow = styled(FormRow)`
  margin-bottom: ${typography.unit * 3}px;
`;

export const TagName = styled(OfferFormTextField)`
  width: 295px;
  div[role='alert'] {
      color: #D90007 !important; 
      margin-top: 39px; 
  }  
`;

export const SectionTitle = styled.div<StyledWithThemeProps>`
  font-size: 12px;
  line-height: 20px;
  margin-bottom: ${typography.unit * 0.5}px;
  margin-top: ${typography.unit * 0.5}px;
  font-weight: 700;
  color: ${newGlobalTheme.colors.global.primaryTextColor};

  &::after {
    content: '*';
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${typography.unit * 9}px;
  &:focus-visible {
    outline: none;
  }
`;

export const TagContainer = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  width: 602px;
  height: 387px;
  padding: ${typography.unit * 9}px;
  position: fixed;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity ${typography.transition}s;
  z-index: ${zIndex.modal};
  background: ${({ theme }) => theme.colors.global.background};
  border-radius: ${typography.borderRadius}px;
  box-shadow: ${({ theme }) => newGlobalTheme.colors.global.boxShadow};
`;

export const TagModalHeader = styled.div`
  font-family: Speedee;
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  font-weight: 700;
  line-height: 22.834px; /* 81.55% */
  letter-spacing: -0.84px;
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const TagTitle = styled.div`
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  height: 27px;
  :focus-visible {
    outline: none !important;
  }
`;

export const TagContent = styled.div<{ contentWidth?: number}>`
  min-height: 0;
  display: flex;
  flex-direction: column;
  ${({ contentWidth }) => contentWidth && `width: ${contentWidth}px;`}
`;