import React from 'react';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { users } from 'app/slices/users';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { ApprovalStatus } from 'utils/types';

export const GMAPreviewButton = (props: any) => {
  const { user } = useSelector(users);
  const { entity, children, entityStatus } = props;
  const isApprovedOrViewer = entityStatus === ApprovalStatus.Approved || user.role === UserRole.Viewer;
  if (isApprovedOrViewer) {
    return <>{children}</>;
  }
  const isDraftOrAdmin =
    entityStatus === ApprovalStatus.Draft || [UserRole.Admin, UserRole.SysAdmin].includes(user.role);
  if (isDraftOrAdmin) {
    return null;
  }
  const { config } = useSelector(marketConfig);
  const { enableOfferSelfApproval } = config;
  const isEntityCreatorOrUpdater = user.id === entity.createdBy?.id || user.id === entity.updatedBy?.id;
  if (isEntityCreatorOrUpdater && enableOfferSelfApproval) {
    return <RoleGuard roles={[UserRole.Trainee, UserRole.Creator]}>{children}</RoleGuard>;
  }

  return null;
};
