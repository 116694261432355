import styled from '@emotion/styled';
import zIndex from 'styles/zIndex';
import { ButtonText, NewButtonText } from 'components/shared/button';
import { Icon, IconProps } from 'components/shared/icon';
import { StyledWithThemeProps } from 'utils/types';
import StatusLabel from 'components/shared/statusLabel/StatusLabel';
import { ImageItemContainerProps } from 'pages/settings/images/components/imageItem/ImageItem.consts';
import { newTypography } from 'styles/typography';

export const Container = styled.div<StyledWithThemeProps>`
  width: 254.8px;
  height: 234px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  display: flex;
  flex-direction: column;
  transition: all ${newTypography.transition}s ease;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 5.3px 1px;
`;

export const ImageWrapper = styled.div<ImageItemContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  word-break: break-all;
  width: 254.4px;
  height: 141px;
  gap: 8px;
  opacity: 0px;
  border-radius: 5px 0px 0px 0px;
  opacity: 0px;
`;

export const ImageBackdrop = styled.div<StyledWithThemeProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${zIndex.hoverBackdrop};
  background-color: ${({ theme }) => theme.colors.global.backdrop};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: ${newTypography.unit * 2}px ${newTypography.unit * 2}px ${newTypography.unit}px ${newTypography.unit}px;
  display: flex;
  flex-direction: column;
`;

export const ImageStatusWrapper = styled.div<{ isArchive?: boolean }>`
  flex: 1;
  padding: 0 ${newTypography.unit * 3}px;
  display: flex;  
  width: 254.4px;
  height: 97px;
  gap: ${({ isArchive }) => (isArchive ? "60px" : "0px")};
  justify-content: ${({ isArchive }) => (isArchive ? "flex-start" : "flex-end")};
  opacity: 1;
`;
export const ImageNameWrapper = styled.div`
  flex: 1;
  padding: 0 ${newTypography.unit * 3}px;
  display: flex;  
  width: 222.4px;
  height: 23px;
  gap: 0px;
  opacity: 0px;
  font-family: ${newTypography.primaryFont};
  font-size: 18px;
  font-weight: 400;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #292929;


`;

export const ImageName = styled.div`
  flex: 1;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 40px;
`;

export const StyledImage = styled.img`
  width: 116px;
  height: 116px;
  object-fit: scale-down;
`;

export const ImgContainer = styled.div`
width: 254.4px;
height: 140px;
display: flex;
align-items: center;
justify-content: center;
padding: 16px 0px 8px 0px;
gap: 8px;
border-radius: 5px 0px 0px 0px;
opacity: 0px;

`;

export const StyledStatusLabel = styled(StatusLabel)`
  height: 10px;
  position: relative;
  top: 90;
  left: 20;
  border-top-left-radius: ${newTypography.borderRadius}px;
  font-family: ${newTypography.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 15.17px;
  text-align: center;
  background-color:#F9F9F9;
`;



export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledButtonText = styled(ButtonText)`
  margin-left: ${newTypography.unit * 2}px;
`;

export const StyledIcon = styled(Icon) <IconProps>`
  width: 18px;
  height: 14px;
  fill:  #292929A6;
;

  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
  transition: all ${newTypography.transition}s ease;

  :hover {
    fill: ${({ theme }) => theme.colors.global.inputPrimary};
  }
`;

export const StyledArchiveIcon = styled(StyledIcon)`
  width: 22px;
  height: 14px;
`;

export const StyledView = styled(StyledIcon) <IconProps>`
  width: 24px;
`;

export const ImageId = styled.div`
  font-size: 12px;
  font-weight: 400;
  align-self: end;
  padding-right: ${newTypography.unit}px;
`;


export const ImageStatusContainer = styled.div`
width: 68px;
height: 26px;
padding: 6px 10px 6px 10px;
gap: 4px;
border-radius: 36px 36px 36px 36px;
border: 2px 0px 0px 0px;
opacity: 0px;
background: #F9F9F9;
border: 2px solid #6F6F6F;
`;


export const ImageViewDetailContainer = styled.div`
height:26px;
gap: 0px;
opacity: 0px;
color: #292929;
font-family: ${newTypography.primaryFont};
font-size: 12px;
font-weight: 400;
line-height: 15.17px;
text-align: right;
height: 26px;
padding: 6px 10px 6px 10px;
gap: 4px;
opacity: 0px;
`;

export const StyledViewDetailText = styled(NewButtonText)`
width: 77px;
height: 23px;
gap: 0px;
opacity: 0px;
font-family: ${newTypography.primaryFont};
font-size: 14px;
font-weight: 700;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: left;
color:#006BAE;
padding-bottom:5px;
`;
export const StyledSeprator = styled.span`
width: 254px;
height: 0px;
gap: 0px;
border: 1px 0px 0px 0px;
opacity: 0px;
angle: -0 deg;
border: 1px solid #D6D6D6
`;
