import styled from '@emotion/styled';
import typography from 'styles/typography';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import { StyledWithThemeProps } from 'utils/types';
import Banner from 'components/shared/notifications/banner/Banner';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip'
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import { OfferFormTextField } from 'components/shared/textField/OfferFormTextField';
import { OfferFormTextArea } from 'components/shared/textArea/OfferFormTextArea';

export const ImageContainer = styled.div<StyledWithThemeProps>`
  width: 166px;
  height: 249px;
  padding: 8px;
  margin: ${typography.unit*0.5}px ${typography.unit*0.5}px ${typography.unit*0.5}px ${typography.unit*0.5}px;
  background-color: ${({ theme }) => theme.colors.global.imageBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow:hidden;
  background: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 5.3px 2px;
`;

export const NewImageContainer = styled.div<StyledWithThemeProps>`
  width: 166px;
  height: 256px;
  object-fit: scale-down;
  background-color: ${({ theme }) => theme.colors.global.imageBackground};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
`;

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  word-break: break-all;
  object-fit: scale-down;
`;

export const NewStyledImage = styled.img`
  height: 142px;
  width: 142px;
  word-break: break-all;
  object-fit: scale-down;
`;

export const ImagesFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 0; 
`;

export const ImagesFormDataContainer = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* min-height: 256px; */
`;

export const ImageUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;

  button {
    width: 100px;
    height: 23px;

    font-family: 'Speedee';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;    
    line-height: 23px;
    /* identical to box height, or 163% */
    letter-spacing: -0.03em;

    /* Action Blue */
    color: #006BAE;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }

`;

export const ImageName = styled(OfferFormTextField)`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 0px;
  height: 59px;


  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  & label {
    width: 75px;
    height: 15px;

    font-family: 'Speedee';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    /* Black */
    color: #292929;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  & input {
  width: 100%;
    height: 36px;
    font-family: 'Speedee';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.17px;
    display: flex;
    align-items: center;
    color: #292929;
    flex: none;
    order: 0;
    flex-grow: 1;
  }  

  div[role='alert'] {
      color: #D90007 !important; 
      margin-top: 39px; 
      outline: none !important;
  }
`;

export const NewImageTags = styled(NewSelectbox)`
  display: flex;
  width: 100%;

  .css-io947i-control {
    background-color: white !important;
    border-color: transparent !important; /* Remove border */
  }

  label {
    margin-bottom: 8px; 
  }


  .css-1u7q4t9-ValueContainer {
    background-color: white !important;
    border: none !important; /* Ensure no border is applied */
  }

  .css-1nvw16u-placeholder {
    background-color: white !important;
    color: #ADADAD !important;
  }

  .css-qbdosj-Input {
    background: white !important;
    color: inherit; 
  }

  .css-yb2c7a-indicatorContainer {
    background-color: white !important;
  }

  .css-uahybi {
    background-color: white !important;
    color: #ADADAD !important;
  }
  
   & label {
    width: 65px;
    height: 15px;

    font-family: 'Speedee';
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }  
` as unknown as typeof NewSelectbox;

  export const ImageTags = styled(Selectbox)`
    display: flex;
    width: 100%;
    margin-bottom: ${typography.unit * 7}px;
` as unknown as typeof Selectbox;

export const ImageDescription = styled(OfferFormTextArea)`
    width: 100%;
  display: flex;
  & div {
    width: 66px;
    height: 15px;
    font-family: 'Speedee';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    /* Black */
    color: #292929;
    /* Inside auto layout */
    order: 0;
  }
  & textarea {
    //width: 348px;
    width: 100%;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    /* Dark Gray */
    border: 1px solid #6F6F6F;
    border-radius: 5px;
    font-family: 'Speedee';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    letter-spacing: -0.03em;
    /* Black */
    color: #292929;
    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    overflow: scroll;
  }
  }
`;

export const StyledButtonsContainer = styled.div<{marginTop ?: number;}>`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ marginTop }) => `${(marginTop || 7)*typography.unit}px`};
  gap: ${typography.unit * 5}px;
`;

export const StyledImagesBanner = styled(Banner)<StyledWithThemeProps>`
  margin-bottom: ${typography.unit * 3}px;
`;

export const ImageTooltip = styled(InfoTooltip)`
  width: 100%;
  display: flex;
  margin-bottom: ${typography.unit * 7}px;
  & label {
    width: 75px;
    height: 15px;

    font-family: 'Speedee';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    /* Black */
    color: #292929;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;

export const ImageModalContentForm = styled.div`
  display: flex;
`;
