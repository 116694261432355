import React, { useReducer } from 'react';
import { StyledNewTitle } from 'components/header/Header.style';
import BrandLogo from 'components/shared/brandLogo/BrandLogo';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { users } from 'app/slices/users';
import { LoginPage, LoginContainer, LoginContainerHeader } from './Login.style';
import { ErrorMessages, ErrorComponent, FormState, LoginReducer, loginReducer } from './Login.consts';
import { LoginForm } from './components/loginForm/LoginForm';
import { ForgotPasswordForm } from './components/forgotPasswordForm/ForgotPasswordForm';
import { ResetPasswordForm } from './components/resetPasswordForm/ResetPasswordForm';

const FormComponentsMap: Record<FormState, (props: any) => JSX.Element> = {
  [FormState.Login]: LoginForm,
  [FormState.ForgotPassword]: ForgotPasswordForm,
  [FormState.ResetPassword]: ResetPasswordForm,
};

const getErrorMsg = (encoded: string) => {
  const decoded = decodeURIComponent(window.atob(encoded));
  return ErrorMessages[decoded as any as ErrorComponent] ?? ErrorMessages[ErrorComponent.Default];
};

export const Login = () => {
  const query = new URLSearchParams(useLocation().search);
  const [state, dispatch] = useReducer<LoginReducer>(loginReducer, {
    formState: FormState.Login,
    error: query.has('error') ? getErrorMsg(query.get('error')) : '',
  });
  const FormComponent = FormComponentsMap[state.formState];

  const user = useSelector(users);
  const history = useHistory();

  if (process.env.NODE_ENV !== 'development') {
    if (user && user.user !== undefined) {
      history.push('/');
    }
  }

  return (
    <LoginPage>
      <LoginContainer>
        <LoginContainerHeader>
          <BrandLogo width={74} height={65} />
          <StyledNewTitle>
            Value
            <br />
            Creation
            <br />
            Engine
          </StyledNewTitle>
        </LoginContainerHeader>
        <FormComponent state={state} dispatch={dispatch} />
      </LoginContainer>
    </LoginPage>
  );
};
