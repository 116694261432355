import styled from '@emotion/styled';
import Modal from 'components/shared/modal/Modal';
import typography from 'styles/typography';
import { ButtonOutlined, ButtonText, NewButtonContained } from '../../shared/button';
import ImageModal from 'components/shared/modal/ImageModal';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { StyledWithThemeProps } from 'utils/types';

export const StyledModal = styled(Modal)`
  min-width: initial;
`;

export const StyledModalForImage = styled(ImageModal)`
  display: flex;
  width: max-content;
  min-height: 510px;
  justify-content: center;
`;

export const ImageUsageSection = styled.div`
  display: flex;
  width: max-content;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  color: ${newGlobalTheme.colors.text.primary};
`;

export const DownloadUsageButton = styled(NewButtonContained)<{ submitButtonString?: string }>`
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  background-color: ${({ submitButtonString, theme }) => 
    submitButtonString ? newGlobalTheme.colors.button.tertiary : newGlobalTheme.colors.button.secondary};
  height: 32px;
  font-size: 14px;
  border: 1px solid #6f6f6f;
  border-radius: 5px;
  :hover {
    background-color: ${newGlobalTheme.colors.button.secondary};
  }
`;

export const UsageFormContainer = styled.div<StyledWithThemeProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: min-content;
  padding-left: 36px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin: 0%;
  div[role='alert'] {
    margin-top: 42px;
    color: ${newGlobalTheme.colors.text.error} !important;
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${typography.unit * 4}px;
`;

export const UsageButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 36px ;
  padding-right: 15px;
`;

export const RightButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const StyledButtonText = styled(ButtonText)`
  margin-right: ${typography.unit * 5}px;
`;

export const StyledButtonOutlined = styled(ButtonOutlined)`
  margin-right: ${typography.unit * 5}px;
`;
