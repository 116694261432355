import styled from '@emotion/styled';
import typography from 'styles/typography';
import ErrorComponent from 'components/shared/error/Error';
import { StyleDatePickerProps } from 'components/shared/picker/datePicker/DatePicker.consts';

export const StyledDateContainer = styled.div<StyleDatePickerProps>`
  display: flex;
  flex-direction: ${({ labelIsHorizontal }) => labelIsHorizontal && 'column'};
  position: relative;

  .MuiInputBase-root {
    font-size: 13px;
    max-width: fit-content;
    color: ${({ theme }) => theme.colors.text.primary} !important;

    &:hover:before {
      border-bottom: 1px solid ${({ theme }) => theme.colors.text.disabled} !important;
    }

    &:before {
      border-color: ${({ theme }) => theme.colors.global.border};
    }

    &:after {
      border-bottom: 1px solid ${({ theme }) => theme.colors.button.hover} !important;
    }

    input {
      width: 75px;
    }

    button {
      width: 20px;

      &:disabled .MuiSvgIcon-root {
        fill: ${({ theme }) => theme.colors.text.disabled};
      }

      &:hover {
        background-color: initial;
      }

      .MuiSvgIcon-root {
        fill: ${({ theme }) => theme.colors.global.inputPrimary};
        width: 18px;

        &:hover {
          fill: ${({ theme }) => theme.colors.button.hover};
        }
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }
`;

export const StyledLabel = styled.label<StyleDatePickerProps>`
  text-transform: capitalize;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.text.primary};
  display: flex;
  align-items: center;
  margin-right: ${({ labelIsHorizontal }) => !labelIsHorizontal && `${typography.unit * 3}px`};
  margin-bottom: ${({ labelIsHorizontal }) => labelIsHorizontal && `${typography.unit}px`};
`;

export const StyledError = styled(ErrorComponent)`
  position: absolute;
  margin-top: ${typography.unit * 8}px;
  white-space: nowrap;
`;
