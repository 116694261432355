import styled from "@emotion/styled";
import { newTypography } from "styles/typography";

export const MetaDataContainer = styled.div`
  padding: ${newTypography.unit * 4}px;
  gap: 8px; 
  display: flex; 
  flex-direction: column; 
  width: 530px; 

`;