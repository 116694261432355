import React, { useRef, useState } from 'react';
import { GenericItem } from 'utils/types';
import { MoreActionDropdownContainer, MoreActionStyledDropDown } from './MoreActionsDropDown.style';
import { Icon } from 'components/shared/icon';
import { MoreActionDropdownProps } from './MoreActionsDropdown.consts';
import useOnClickOutside from 'hooks/use-onclick-outside';

export function MoreActionsDropDown({
  className,
  position = 'top-right',
  items,
  onItemClick,
  children,
  version,
  tagImpacts,
  tag,
  ...rest
}: MoreActionDropdownProps) {
  const [open, setOpen] = useState(false);

  const dropdownRef = useRef();
  const onItemChange = (item: GenericItem) => {
    if (onItemClick) {
      setOpen(false);
      onItemClick(item);
    }
  };

  const toggleDropdown = () => {
    setOpen(!open);
  };

  const closeDropdown = () => {
    setOpen(false);
  };

  useOnClickOutside(dropdownRef, closeDropdown);

  const handleKeyDown = <T extends HTMLElement>(event: React.KeyboardEvent<T>) => {
    if (event.key === 'Enter' || event.key === ' ' || event.keyCode === 13) {
      event.preventDefault();
      toggleDropdown();
    }
    if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  return (
    <MoreActionDropdownContainer
      ref={dropdownRef}
      open={open}
      className={className}
      {...rest}
      tabIndex={0}
      onClick={(e) => {
        e.stopPropagation();
        toggleDropdown();
      }}
      onKeyDown={(event) => handleKeyDown(event)}
    >
      {open && (
        <MoreActionStyledDropDown
          items={items}
          onClick={onItemChange}
          position={position}
          tagImpacts={tagImpacts}
          tag={tag}
        />
      )}
      <Icon name="threeDots" />
    </MoreActionDropdownContainer>
  );
}
