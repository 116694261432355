import styled from '@emotion/styled';
import { StyledWithNewThemeProps } from 'utils/types';
import OfferModal from 'components/shared/modal/OfferModal';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { NewButtonContained, NewButtonText } from 'components/shared/button';
import NewImpactItem from '../impactItem/NewImpactItem';

export const NewStyledModal = styled(OfferModal)`
  max-height: 789px;
  height: auto;
  max-width: 1043px;
  display: flex;
  justify-content: center;
  padding: 36px 0px 36px 0px;
  border-radius: 5px;
  min-width: unset;
`;

export const TCStyledImpactTablesContainer = styled.div`
  width: 100%;
  max-height: 497px;
  height: auto;
`;

export const TCStyledImpactTables = styled.div`
  display: flex;
  gap: 36px;
  width: 100%;
  max-height: 497px;
  height: auto;
`;

export const TCStyledImpactItem = styled(NewImpactItem)`
  width: 467.5px;
  h4 {
    height: 23px;
    line-height: 22.83px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
  }
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-columnHeaderTitleContainer,
  .MuiDataGrid-cell {
    min-width: unset;
  }
  .StyledTableContainer {
    display: flex;
    column-gap: 8px;
  }
`;

export const TCUsageFormContainer = styled.div<StyledWithNewThemeProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 0px 36px;
  div[role='alert'] {
    margin-top: 42px;
    color: ${newGlobalTheme.colors.text.error} !important;
  }
`;

export const TCUsageButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 12px;
  width: 100%;
  padding: 36px 36px 0px 36px;
`;

export const TCDownloadUsageButton = styled(NewButtonContained)`
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  background-color: ${newGlobalTheme.colors.global.background};
  height: 32px;
  max-width: 173px;
  width: auto;
  font-family: Speedee;
  font-size:14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  border: 1px solid #6f6f6f;
  padding: 8px 12px 8px 12px;
  border-radius: 5px;
  :hover {
    background-color: inherit;
  }
`;

export const GoldenDownloadUsageButton = styled(TCDownloadUsageButton)`
  color: ${newGlobalTheme.colors.text.primary};
  border: 1px solid var(--Accent-Gold, #C08B00);
  background: var(--McDonalds-Gold, #FFBC0D);
  :hover {
    border: 1px solid var(--Accent-Gold, #C08B00);
    background: var(--McDonalds-Gold, #FFBC0D);
`;

export const NewCloseButton = styled(NewButtonText)`
  font-family: Speedee;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #006BAE;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  width: 100%;
  justify-content: left;
  height: 32px;
  padding: 8px 0px 8px 0px;
`

export const NewStyledGoldButton = styled(NewButtonContained)`
  color: ${newGlobalTheme.colors.text.primary};
  height: 32px;
  min-width: 55px;
  border-radius: 5px;
  border: 1px solid var(--Accent-Gold, #C08B00);
  background: var(--McDonalds-Gold, #FFBC0D);
  font-size: 14px;
  padding: 8px 12px 8px 12px;

  &:disabled {
        border: none;
        background: ${newGlobalTheme.colors.button.disabledSave};
        color: ${newGlobalTheme.colors.text.disabledSave};
    }

  :hover {
    border: 1px solid var(--Accent-Gold, #C08B00);
    background: var(--McDonalds-Gold, #FFBC0D);
  }
`