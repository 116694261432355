import React from 'react';
import { MessageType } from '../../shared/notifications/notifications';
import { StyledBanner, NewStyledBanner, StyledBannerContent, NewStyledBannerContent, StyledSpan, NewStyledBannerContentImage, NewStyledBannerImage, NewStyledSpan } from './shared.style';
import { UsageStyledSpan } from './UsageModal.style';

interface ModalHeaderProps {
  haveCampaign?: boolean;
}

export const OfferSaveNotificationHeader = () => (
  <StyledSpan>Saving this Offer will affect the following Campaigns:</StyledSpan>
);

export const OfferArchiveNotification = () => (
  <StyledBanner type={MessageType.Warning}>
    <StyledBannerContent>
      This Offer can not be archived because it is used in the following Campaigns
    </StyledBannerContent>
  </StyledBanner>
);

export const OfferEditSegmentIdNotification = () => (
  <StyledBanner type={MessageType.Warning}>
    <StyledBannerContent>Group ID can not be edited for Deployed or Active Campaigns</StyledBannerContent>
  </StyledBanner>
);

export const OfferUsage = () => <UsageStyledSpan>The Offer is used in the following Campaigns:</UsageStyledSpan>;

export const OfferRejectNotification = () => (
  <StyledSpan>Rejecting this Offer will affect the following Campaigns:</StyledSpan>
);

export const ImageCantArchiveNotification = () => (
  <NewStyledBannerImage type={MessageType.NewWarning}>
    <NewStyledBannerContentImage>
      This image can not be archived because it is used in the following Offers and Campaigns:
    </NewStyledBannerContentImage>
  </NewStyledBannerImage>
);

export const ImageArchiveNotification = () => (
  <StyledSpan>Archiving this image will affect the following Offers and Campaigns:</StyledSpan>
);

export const ImageSaveNotification = () => (
  <StyledSpan>Saving this Image will affect the following Offers and Campaigns:</StyledSpan>
);

export const ImageUsage = () => <NewStyledSpan>The Image is used in the following Campaigns and Offers: </NewStyledSpan>;

export const TermCantArchiveNotification: React.FC<ModalHeaderProps> = ({ haveCampaign }) => (
  <NewStyledBanner type={MessageType.NewWarning} haveCampaign={haveCampaign}>
    <NewStyledBannerContent>
      This T&C can not be archived because it is used in the following Offers and Campaigns
    </NewStyledBannerContent>
  </NewStyledBanner>
);

export const TermArchiveNotification = () => (
  <StyledSpan>Archiving this T&C will affect the following Offers and Campaigns:</StyledSpan>
);

export const TermSaveNotification: React.FC<ModalHeaderProps> = ({ haveCampaign }) => (
  <NewStyledBanner type={MessageType.NewWarning} haveCampaign={haveCampaign}>
    <NewStyledBannerContent>
      <div>The updated content will impact the Offers and Campaigns as follows:</div>
      <li>Offers using default T&C will be overridden with new updates.</li>
      <li>
        Offers <b>not</b> using default T&C will <b>not</b> be overridden. You must update the T&C for each Offer
        manually.
      </li>
    </NewStyledBannerContent>
  </NewStyledBanner>
);

export const ProductSetUsage = () => (
  <StyledSpan>The Products Set is used in the following Campaigns and Offers:</StyledSpan>
);

export const ProductSetSaveNotification = () => (
  <StyledSpan>Saving this Product Set will affect the following Offers and Campaigns:</StyledSpan>
);

export const ProductSetDeleteSubtitle = () => (
  <StyledSpan>Deleting this Products Set will affect the following Offers and Campaigns:</StyledSpan>
);

export const ProductSetDeleteNotification = () => (
  <StyledBanner type={MessageType.Warning}>
    <StyledBannerContent>
      This set <b>can not</b> be deleted as the following offers will remain with no active products. Please update the
      products in these offers or archive these offers first.
    </StyledBannerContent>
  </StyledBanner>
);

export const LocationSetUsage = () => <StyledSpan>The Locations Set is used in the following Campaigns:</StyledSpan>;

export const LocationSetSaveNotification = () => (
  <StyledSpan>Saving this Location Set will affect the following Campaigns:</StyledSpan>
);

export const LocationSetDeleteNotification = () => (
  <StyledBanner type={MessageType.Warning}>
    <StyledBannerContent>
      This set <b>can not</b> be deleted as the following campaigns will remain with no locations. Please update the
      locations in these campaigns or archive these campaigns first.
    </StyledBannerContent>
  </StyledBanner>
);

export const LocationSetDeleteSubtitle = () => (
  <StyledSpan>Deleting this Locations Set will affect the following Campaigns:</StyledSpan>
);

export const TagUsageDeleteNotification = () => (
  <StyledSpan>Deleting this Tag will affect the following Campaigns, Offers and Images:</StyledSpan>
);

export const TagUsageNotification = () => (
  <StyledSpan>The tag is used in the following Campaigns, Offers and Images:</StyledSpan>
);
export const TagSaveNotificationType = () => (
  <StyledSpan>Saving this tag will affect the following Offers and Campaigns:</StyledSpan>
);

export const ProductUsage = () => <StyledSpan>The product is used in the following Campaigns and Offers:</StyledSpan>;

export const LocationUsage = () => <StyledSpan>The location is used in the following Campaigns: </StyledSpan>;
