import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Language, LanguageRecord } from 'utils/languages';
import { marketConfig } from 'app/slices/config';
import { hasValue } from 'utils/text';
import { TermTranslation } from '../../../TermsConditions.consts';
import { TermConditionTranslationsProps } from './TermConditionTranslations.const';
import {
  LanguageNavigationContainer,
  LanguageContentContainer,
  LanguageNavigationItem,
  StyledContainer,
  LanguageContent,
  StyledLabel,
} from './TermConditionTranslations.style';
import { ValidationMessages } from 'utils/types/common';
import { handleKeyPress } from 'utils/keyPress';

function TermConditionTranslations({
  register,
  setValue,
  translationsMap,
  errors,
  className,
  isDirty, 
  watch, 
  reset, 
  term,
  setIsFormDirty
}: TermConditionTranslationsProps) {
  const { config, languages } = useSelector(marketConfig);
  const [selectedLanguage, setSelectedLanguage] = useState(config.primaryLanguage);


  const watchValues = watch();

  useEffect(() => {
    if (isDirty) {
      let foundDirtyValue = false; 
      if (translationsMap) {
        languages.forEach((lang: any, index: number) => {
          if (translationsMap[`${lang.toString()}`]) {
            if (translationsMap[lang].content !== watchValues.translations[index].content) {
              foundDirtyValue = true; 
            }
          }
          else if (watchValues.translations[index].content && !translationsMap[`${lang.toString()}`]) {
              foundDirtyValue = true; 
          }
        });

      }
      if (term.name !== watchValues.name) {
        foundDirtyValue = true; 
      }
      if (JSON.stringify(term.template) !== JSON.stringify(watchValues.template)) {
        foundDirtyValue = true; 
      }
      if (!foundDirtyValue) {
        setIsFormDirty(false); 
        reset(undefined, { keepValues: true, keepDirty: false });
      }
      else if (foundDirtyValue) {
        setIsFormDirty(true); 
      }
    }
  }, [watchValues]);

  
  useEffect(() => {
    languages.forEach((lang: any, index: number) => {
      setValue(`translations.${index}.language`, lang);
    });
  }, [setValue]);

  return (
    <StyledContainer className={className}>
      <StyledLabel>Content*</StyledLabel>
      <LanguageNavigationContainer data-automation-id="languages">
        {languages.map((lang: Language, index: number) => {
          return (
            <LanguageNavigationItem
              key={`${index}_${lang}`}
              tabIndex={0}
              isActive={selectedLanguage === lang}
              onKeyDown={(event) =>
                handleKeyPress(event, (e: any) => {
                  setSelectedLanguage(languages[index]);
                })
              }
              onClick={() => setSelectedLanguage(lang)}
              data-automation-id="language-item"
            >
              {LanguageRecord[lang]}
            </LanguageNavigationItem>
          );
        })}
      </LanguageNavigationContainer>
      {languages.map((lang: Language, index: number) => {
        const relevantTranslation: TermTranslation = translationsMap && translationsMap[lang];
        return (
          <LanguageContentContainer key={`${index}_${lang}`}>
            <LanguageContent
              register={register}
              name={`translations.${index}.content`}
              placeholder="Enter content"
              value={relevantTranslation?.content}
              validation={{
                required:
                  (config.isSecondaryLanguageEnforced && lang === config.secondaryLanguage) ||
                  lang === config.primaryLanguage,
                maxLength: {
                  value: 100000,
                  message: 'Up to 100K characters',
                },
                validate: (value: string) => {
                  if (lang === config.primaryLanguage) {
                    return !hasValue(value) ? ValidationMessages.RequiredField : true;
                  }
                  if (config.isSecondaryLanguageEnforced) {
                    return !hasValue(value) ? ValidationMessages.RequiredField : true;
                  }
                  return !value || (!hasValue(value) ? ValidationMessages.RequiredField : true);
                },
              }}
              errors={errors}
              rows={8}
              show={selectedLanguage === lang}
            />
          </LanguageContentContainer>
        );
      })}
    </StyledContainer>
  );
}

export default TermConditionTranslations;
