import React, { useEffect, useState } from 'react';
import { getItemTitle } from 'components/shared/dropdown/Dropdown.utils';
import { GenericItem } from 'utils/types';
import { AdornmentPosition, DropdownProps } from './Dropdown.consts';
import {
  AddNew,
  AddNewText,
  StyledCheckbox,
  StyledDropdown,
  StyledDropdownItem,
  StyledDropdownSearch,
  StyledSearch,
  StyledTextField,
} from './Dropdown.style';
import { handleKeyPressLi } from 'utils/keyPress';
import OfferToolTip from '../tooltip/OfferToolTip';
import { hideTooltip } from 'utils/tooltip';
import { handleDeleteTag } from 'pages/settings/tags/components/tagForm/utils/TagActions';

function defaultFilterFunction<T extends GenericItem>(q: string) {
  return (item: T) => item.name.toString().toLowerCase().includes(q.toLowerCase());
}

export default function Dropdown<T extends GenericItem>({
  items,
  onClick,
  selectedItems = {},
  className,
  multiple,
  withSearch,
  withAmount,
  filterFunction = defaultFilterFunction,
  onCreateOption,
  titleFormatter,
  onServerSearchChange,
  tagImpacts,
  tag,
  ...rest
}: DropdownProps<T>) {
  const [query, setQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);

  const onSearchChange = (searchValue: string) => {
    if (!withSearch) {
      return;
    }

    if (onServerSearchChange) {
      onServerSearchChange(searchValue);
    } else {
      setQuery(searchValue);
    }
  };

  useEffect(() => {
    if (filterFunction && withSearch && !onServerSearchChange) {
      setFilteredItems(items?.filter(filterFunction(query)));
    } else {
      setFilteredItems(items);
    }
  }, [items, query]);

  useEffect(() => {
    onSearchChange('');
  }, []);

  const onActionClicked = async () => {
    if (!tagImpacts) {
      await handleDeleteTag(tag.id);
    }
  }

  return (
    <StyledDropdown className={className} {...rest} data-automation-id="dropdown">
      {(filterFunction || onServerSearchChange) && withSearch && (
        <StyledDropdownSearch>
          <StyledTextField
            type="text"
            name="query"
            value={query}
            debounceTime={400}
            onChange={(e) => {
              onSearchChange(e.target.value);
            }}
            autoFocus
            adornmentPosition={AdornmentPosition.End}
          >
            <StyledSearch name="search" />
          </StyledTextField>
        </StyledDropdownSearch>
      )}
      {filteredItems?.map((item: T) =>
        multiple ? (
          <StyledDropdownItem key={item.id} data-automation-id="dropdown-item">
            <StyledCheckbox
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(item);
              }}
              checked={!!selectedItems[item.id]}
              label={withAmount && item.amount ? `${item.name} (${item.amount})` : `${item.name}`}
            />
          </StyledDropdownItem>
        ) : (
          <StyledDropdownItem
            key={item.id}
            onClick={() => onClick(item)}
            selected={!!selectedItems[item.id]}
            data-automation-id="dropdown-item"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault(); 
                e.stopPropagation();
                e.currentTarget.click(); 
              }
            }}
            {...(item.id === 'delete' ? { 'data-for': 'delete-tooltip', 'data-tip': true } : {})}
          >
            {getItemTitle(item, titleFormatter)}
          </StyledDropdownItem>
        ),
      )}
      {!tagImpacts && (
        <OfferToolTip
          id="delete-tooltip"
          content="Are you sure you want to delete?"
          approveMsg="Yes, Delete"
          onDisapproveClick={() => {
            hideTooltip('#delete-tooltip');
          }}
          onApproveClick={() => onActionClicked()}
          eventOff={null}
          place = {'left'}
        />)}
      {Boolean(query?.length && withSearch && onCreateOption) && (
        <AddNew onClick={() => onCreateOption(query)}>
          {query} <AddNewText>(Add New)</AddNewText>
        </AddNew>
      )}
      
    </StyledDropdown>
    
  );
}
