import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const StyledTitle = styled.h3`
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-style: normal;
  letter-spacing: -0.84px;
  color: ${newGlobalTheme.colors.text.primary};
`;

export const NewStyledTitle = styled(StyledTitle)`
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
`;
