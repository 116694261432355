import { gql } from '@apollo/client';

export const doeImagesGqls = {
  queries: {
    getAll: gql`
      query DoeImages($data: GetAllQueryDto) {
        getImages(data: $data) {
          items {
            ... on Image {
              id
              name
              tags {
                id
                name
              }
              file
              fileName
              isArchive
              updatedAt
              isLocked
              imageSource
            }
          }
          total
        }
      }
    `,
    getSpecialOptionsConfig: gql`
      query GetSpecialOptionsConfig {
        getSpecialOptionsConfig {
          specialOptions {
            key
            name
          }
        }
      }
    `,
    getById: gql`
      query GetDoeImageById($id: Int!) {
        getDoeImage(id: $id) {
          id
          name
          description
          tags {
            id
            name
          }
          file
          fileName
          isArchive
          metadata
          selectedLanguages
          defaultTemplates
          defaultTemplateToggle
          discountComponentCheckbox
          imageSource
        }
      }
    `,
  },
  mutations: {
    addDoe: gql`
      mutation CreateDoeImages($data: [CreateImageDto!]!) {
        createDoeImages(data: $data) {
          id
          name
        }
      }
    `,
    updateDoe: gql`
      mutation UpdateDoeImages($data: [UpdateImageDto!]!) {
        updateDoeImages(data: $data) {
          isProcessed
          message
        }
      }
    `,
  },
};
