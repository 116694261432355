import React, { useState, useRef } from 'react';
import {
  DropdownContent,
  DropdownItem,
  NewDropdownButton,
  StyledRedemptionOverviewDropdown,
} from './RedemptionOverviewDropdown.style';
import { Icon } from 'components/shared/icon';
import useOnClickOutside from 'hooks/use-onclick-outside';
import { handleKeyPress } from 'utils/keyPress';

export const RedemptionOverviewDropdown = ({ options, onSelect, defaultOption }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption || null);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const selectOption = (option: any) => {
    setSelectedOption(option);
    onSelect(option.id);
    setIsOpen(false);
  };

  return (
    <StyledRedemptionOverviewDropdown ref={dropdownRef} className={`custom-dropdown ${isOpen ? 'open' : ''}`}>
      <NewDropdownButton
        tabIndex={0}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onKeyDown={(event) => handleKeyPress(event, () => setIsOpen(!isOpen))}
        role="combobox"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
      >
        {selectedOption ? selectedOption.name : 'Select an option'}
        <Icon name="arrowDown" width={8.65} height={8.65} />
      </NewDropdownButton>
      {isOpen && (
        <DropdownContent>
          {options.map((option: any) => (
            <DropdownItem
              key={option.id}
              tabIndex={0}
              onClick={() => selectOption(option)}
              onKeyDown={(event) => handleKeyPress(event, () => selectOption(option))}
              role="option"
            >
              {option.name}
            </DropdownItem>
          ))}
        </DropdownContent>
      )}
    </StyledRedemptionOverviewDropdown>
  );
};
