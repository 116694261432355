import React, { useCallback, useEffect, useState } from 'react';
import { SortingKey } from 'utils/sorting';
import { useFormContext } from 'react-hook-form';
import {
  AccordionContentType,
  ListType,
  SetItemsSelectionFormState,
} from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { useSetsFilter } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/selectedSetsAccordion/hooks/useSetsFilter';
import {
  getSetsToDisplay,
  getAllItemsRecursively,
  getSelectedItems,
  getSetsFringesItems,
  getSetsNestedSetsRecursively,
} from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import { SelectedDisplayMode } from 'pages/shared/setItemsSelectionForm/setListItem/SetListItem.consts';
import SetListItem from 'pages/shared/setItemsSelectionForm/setListItem/SetListItem';
import { SelectedSetsAccordionProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/selectedSetsAccordion/SelectedSetsAccordion.consts';
import { Virtuoso } from 'react-virtuoso';
import { GenericItem, GenericSet } from 'utils/types';
import { StyledCenteredLoaderContainer, StyledItemsSelectionAccordion } from '../../SetItemsListPanel.style';
import { StyledLoader } from 'pages/shared/shared.style';
import { LoaderSize } from 'components/shared/loader/Loader.consts';

const SelectedSetsAccordion = ({
  accordion,
  isSingleAccordionPanel,
  setOf,
  itemFormatter,
  itemSetTitleFormatter,
  onExcludeItemClick,
  onExcludeSetClick,
  onItemSelect,
  onItemSetActionClick,
  removeAll,
  searchValue,
  fetchRootSetsByIds,
  forcedExcludedSetsIds,
  forcedExcludedItemsIds,
  supportSetFringes,
  items: selectedItems,
  sets: selectedSets,
  disabled: disabled,
  isDoe,
  sort,
}: SelectedSetsAccordionProps) => {
  const [expanded, setExpanded] = useState(true);
  const [sets, setSets] = useState([]);
  const [sortingKey, setSortingKey] = useState(SortingKey.Az);
  const [expandedSetsIds, setExpandedSetsIds] = useState<Set<number>>(new Set<number>());
  const [isLoading, setIsLoading] = useState(false);
  const { watch } = useFormContext<SetItemsSelectionFormState>();
  const selectedItemsById = watch('selectedItemsById') ?? selectedItems ?? {};
  const selectedItemSetsById = watch('selectedItemSetsById') ?? selectedSets ?? {};
  const excludedItemsById = watch('excludedItemsById') ?? {};
  const excludedItemSetsById = watch('excludedItemSetsById') ?? {};
  const { name, headline, contentType, sortOptions, searchItemSet, searchItem, filterItemSet } = accordion;
  const fetchIndividualItemsSets = async (items: GenericItem[]) => {
    const individualItemsSetsIds = items.filter((item) => item.setId).map((item) => item.setId);
    const setsToFetch = uniq(individualItemsSetsIds).map((id) => ({
      id,
    })) as GenericSet[];
    return setsToFetch.length ? fetchRootSetsByIds(setsToFetch) : ([] as GenericItem[]);
  };




  const initSets = async (abortActionStatus: { abort: boolean }) => {
    setIsLoading(true);
    const fringes = supportSetFringes
      ? getSetsFringesItems(Object.values(selectedItemSetsById).filter((set) => !set.custom))
      : [];
    const individualItemsSets =
      contentType === AccordionContentType.SetWithIndividualItems
        ? await fetchIndividualItemsSets([...Object.values(selectedItemsById), ...fringes])
        : [];

    let allRootSets = uniqBy([...individualItemsSets, ...Object.values(selectedItemSetsById)], 'id');

    const nonRootSets = allRootSets.filter((set) => fetchRootSetsByIds && !set.custom && set.nonCustomParentSetId);

    if (nonRootSets.length) {
      const nonRootSetsIdsSet = new Set<number>(nonRootSets.map((s) => s.id));
      const fetchedRootSets = await fetchRootSetsByIds(nonRootSets);
      allRootSets = allRootSets.filter((set) => !nonRootSetsIdsSet.has(set.id));
      allRootSets.push(...fetchedRootSets);
    }

    const allSetsToDisplay = getSetsToDisplay(
      uniqBy(allRootSets, 'id'),
      setOf,
      false,
      ListType.Selected,
      supportSetFringes,
      selectedItemSetsIds,
      selectedItemsIds,
    );

    if (abortActionStatus.abort) {
      return;
    }
    setExpandedSetsIds(
      new Set<number>(allSetsToDisplay.map((set) => set.id).filter((setId) => expandedSetsIds.has(setId))),
    );

    setSets(allSetsToDisplay);
    setIsLoading(false);
  };

  const onExpandSetClick = useCallback(
    (setId: number) => {
      if (expandedSetsIds.has(setId)) {
        expandedSetsIds.delete(setId);
        setExpandedSetsIds(new Set<number>(expandedSetsIds));
      } else {
        setExpandedSetsIds(new Set<number>(expandedSetsIds.add(setId)));
      }
    },
    [expandedSetsIds],
  );

  const selectedItemsIds = new Set(
    getSelectedItems(
      Object.values(watch('selectedItemSetsById') ?? selectedSets ?? {}),
      Object.values(watch('selectedItemsById') ?? selectedItems ?? {}),
      setOf,
      supportSetFringes,
    ).map((item) => Number(item.id)),
  );

  const selectedFringesIds = new Set(
    getSetsFringesItems(Object.values(watch('selectedItemSetsById') ?? selectedSets ?? {})).map((item) =>
      Number(item.id),
    ),
  );

  const selectedItemSetsIds = new Set(
    getSetsNestedSetsRecursively(Object.values(watch('selectedItemSetsById') ?? selectedSets ?? {}))
      .filter((set) => !set.dummy)
      .map((set) => Number(set.id)),
  );

  const filteredSetsToDisplay = useSetsFilter(
    sets,
    searchValue,
    searchItemSet,
    searchItem,
    setOf,
    sortingKey,
    filterItemSet,
  );

  useEffect(() => {
    const abortActionStatus = { abort: false };
    initSets(abortActionStatus);

    return () => {
      abortActionStatus.abort = true;
    };
  }, [selectedItemSetsById, selectedItemsById]);



  const sortSets = (sets: any, sortType: string): any[] => {
    const hasProductsKey = sets.length > 0 && Object.hasOwn(sets[0], "products");
    if (hasProductsKey){
    return [...sets]
      .sort((a, b) => {
        switch (sortType) {
          case "AlphaAsc":
            return a.name.trim().localeCompare(b.name.trim());
          case "AlphaDesc":
            return b.name.trim().localeCompare(a.name.trim());
          case "PluAsc":
            return a.id - b.id;
          case "PluDesc":
            return b.id - a.id;
          default:
            return sets;
        }
      })
      .map((set) => ({
        ...set,
        products: [...set.products].sort((a, b) => {
          switch (sortType) {
            case "AlphaAsc":
              return a.name.trim().localeCompare(b.name.trim());
            case "AlphaDesc":
              return b.name.trim().localeCompare(a.name.trim());
              case "PluAsc":
                return (a.plu ?? Number.MAX_SAFE_INTEGER) - (b.plu ?? Number.MAX_SAFE_INTEGER);
              case "PluDesc":
                return (b.plu ?? Number.MIN_SAFE_INTEGER) - (a.plu ?? Number.MIN_SAFE_INTEGER);    
            default:
              return sets;
          }
        }),
      }));
    }else{
      return sets;
    }
  };


  return (
    <>
      {accordion.headline === 'Sets' && isDoe ? null : (
        <StyledItemsSelectionAccordion
          listName={name}
          headline={headline}
          showExpandArrow={!isSingleAccordionPanel}
          expanded={expanded}
          onChange={(event, isExpanded) => setExpanded(isExpanded)}
          onSortChange={(listName, key) => setSortingKey(key)}
          removeAll={
            removeAll
              ? () =>
                  removeAll(
                    getSetsNestedSetsRecursively(filteredSetsToDisplay).flatMap((set) =>
                      selectedItemSetsById[set.id] ? set : getAllItemsRecursively([set], setOf),
                    ),
                  )
              : null
          }
          sortOptions={sortOptions}
          disabled={disabled}
        >
          <>
            {isLoading && (
              <StyledCenteredLoaderContainer>
                <StyledLoader size={LoaderSize.Medium} />
              </StyledCenteredLoaderContainer>
            )}
            <Virtuoso
              tabIndex={-1}
              data={sortSets(filteredSetsToDisplay,sort)}
              itemContent={(index, set) => {
                return (
                  <SetListItem
                    key={`s-${set.id}`}
                    itemSet={set}
                    setOf={setOf}
                    listType={ListType.Selected}
                    onItemSetActionClick={onItemSetActionClick}
                    onItemActionClick={onItemSelect}
                    onExcludeItemClick={onExcludeItemClick}
                    onExcludeSetClick={onExcludeSetClick}
                    titleFormatter={itemSetTitleFormatter}
                    selectedItemSetsIds={selectedItemSetsIds}
                    selectedFringesIds={selectedFringesIds}
                    selectedItemsIds={selectedItemsIds}
                    excludedItemsIds={new Set(Object.keys(excludedItemsById).map(Number))}
                    excludedSetsIds={new Set(Object.keys(excludedItemSetsById).map(Number))}
                    selectedSetsDisplayMode={SelectedDisplayMode.Display}
                    selectedItemsDisplayMode={SelectedDisplayMode.Display}
                    expandedSetsIds={expandedSetsIds}
                    onExpandSetClick={onExpandSetClick}
                    itemFormatter={itemFormatter}
                    forcedExcludedItemsIds={forcedExcludedItemsIds}
                    forcedExcludedSetsIds={forcedExcludedSetsIds}
                    supportSetFringes={supportSetFringes}
                  />
                );
              }}
            />
          </>
        </StyledItemsSelectionAccordion>
      )}
    </>
  );
};

export default SelectedSetsAccordion;
