import styled from '@emotion/styled';
import typography, { newTypography } from 'styles/typography';
import { Icon, IconProps } from 'components/shared/icon';
import Tooltip from 'components/shared/tooltip/Tooltip';
import TabStrip from 'components/shared/tabStrip/TabStrip';
import { StyledWithThemeProps } from 'utils/types';
import { ButtonText, NewButtonText } from 'components/shared/button';
import StatusLabel from 'components/shared/statusLabel/StatusLabel';
import NewStatusLabel from 'components/shared/statusLabel/NewStatusLabel';

export const HeaderContainer = styled.div`
  width: 100%;
  padding: ${typography.unit * 4}px;
  display: flex;
  flex-direction: column;
  margin: 0 0px 0px 0 !important;
  height: 47px;
  gap: 0px;
  border-radius: 5px 5px 0px 0px;
  opacity: 0px;
  justify-content: center;
`;

export const Container = styled.div<StyledWithThemeProps>`
  width: 324px;
  height: 299px;
  gap: 0px;
  opacity: 0px;
  margin-left: 24px;

  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  display: flex;
  flex-direction: column;
  transition: all ${typography.transition}s ease;
  box-shadow:rgba(0, 0, 0, 0.16) 0px 0px 5.3px 1px;

  &:hover,&:focus-within  {
    #headerContainer {
      background-color: gray;
    }
  }
 
  
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: ${typography.unit}px;
  position: relative;
`;

export const HeaderActions = styled.div`
  display: flex;
`;

export const StyledButtonText = styled(ButtonText)`
  margin-left: ${typography.unit * 1.5}px;
`;

export const StyledStatusLabel = styled(StatusLabel)`
  position: absolute;
  top: -${typography.unit * 4}px;
  left: -${typography.unit * 4}px;
  border-top-left-radius: ${typography.borderRadius}px;
`;

export const TermName = styled.span<StyledWithThemeProps>`
  font-size: 16px;
  font-family: ${newTypography.primaryFont};
  color: ${({ theme }) => theme.colors.text.primary};
  overflow: hidden;
  width: 292px;
  height: 23px;
  gap: 0px;
  opacity: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const StyledIcon = styled(Icon)<IconProps>`
  width: 20px;
  height: 20px;
  fill: #fff;
  cursor: pointer;

  :hover {
    fill: ${({ theme }) => theme.colors.global.inputPrimary};
  }
`;

export const StyledArchiveIcon = styled(StyledIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;
  :hover {
    fill: ${({ theme }) => theme.colors.global.inputPrimary};
  }
`;

export const OfferTemplates = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${typography.unit * 1}px;
  margin-top: 8px;
  font-size: 12px;
`;

export const StyledOfferTemplatesText = styled.span<StyledWithThemeProps>`
  color: ${({ theme }) => theme.colors.text.secondary};
  //margin-right: ${typography.unit * 4}px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const StyledFirstOffer = styled.span<StyledWithThemeProps>`
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const TranslationAndButtonContainer = styled.div`
  width: 292px;
  height: 190px;
  gap: 8px;
  margin: 0px 16px 16px 16px;
  justify-content: center;
`;

export const Translations = styled.div`
  width: 292px;
  height: 159px;
  opacity: 0px;
`;

export const StyledTabStrip = styled(TabStrip)`
  height: 31px;
  font-size: 14px;
  width: 292px;
  gap: 8px;
  opacity: 0px;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 8px;

  a {
    padding: 0 ${typography.unit * 2}px;
  }
`;

export const LastUpdatedText = styled.span<StyledWithThemeProps>`
  font-size: 12px;
  text-transform: capitalize;
  align-self: flex-end;
`;

export const TranslationBox = styled.div`
  width: 292px;
  height: 120px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid #adadad;
  overflow-y: auto;
`;

export const TranslationContent = styled.div<StyledWithThemeProps>`
  flex: 1;
  font-size: ${newTypography.fontSize / 1.5}px;
  word-break: break-word;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const StyledTooltip = styled(Tooltip)`
  opacity: 1 !important;
`;

export const TermConditionId = styled.div`
  font-family: Speedee;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  gap: 8px;
`;

export const StyledDownloadButton = styled(ButtonText)`
  margin-right: 1px;
`;

export const StyledViewDetailText = styled(NewButtonText)`
  gap: 0px;
  opacity: 0px;
  font-family: ${newTypography.primaryFont};
  font-size: 14px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: right;
  color: #006bae;
  cursor: pointer;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const NewStyledStatusLabel = styled(NewStatusLabel)`
  font-family: ${newTypography.primaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 15.17px;
  text-align: center;
`;

export const TermsStatusContainer = styled.div`
  height: 26px;
  gap: 0px;
  opacity: 0px;
  font-family: ${newTypography.primaryFont};
`;

export const TermsGridItemBottomFooter = styled.div`
  height: 26px;
  width: 292px;
  opacity: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 0.5;
  margin-top: 8px;
`;

export const TemsEditDetailContainer = styled.div`
  display: flex;
  gap: 0px;
  width: 100%;
  opacity: 0px;
  color: #292929;
  font-family: ${newTypography.primaryFont};
  opacity: 0px;
  justify-content: flex-end;
  align-items: center;
`;

export const TermDetailsContainer = styled.div`
  margin-left: ${newTypography.unit * 4}px;
`;
