import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import LockIcon from 'pages/shared/lockIcon/LockIcon';
import TextModalTitle from 'components/shared/text/textModalTitle/TextModalTitle';
import { useOperationCounter } from 'app/apollo/operationCounter';
import difference from 'lodash/difference';
import Backdrop from '../backdrop/Backdrop';
import { DOEImageModalProps } from './Modal.const';
import { Container, Content, Loading, MainTitle, ModalHeader, TitleWithProgress } from './Modal.style';
import { Loader } from '../loader';
import ProgressLoader from 'pages/shared/progressIndicator/ProgressLoader';
import { FormMode } from 'utils/types';
import { UserRole } from 'utils/types/users';
import { DOEImageActionsList, DOEImageActionsListForNonAdmin } from 'utils/types/images';
import { useSelector } from 'react-redux';
import { users } from 'app/slices/users';
import { StyledButtonDropdown } from 'pages/offers/offerManagement/components/offerForm/OfferForm.style';
import { handleKeyDownModal } from 'utils/modal';

const Modal = ({
  children,
  title,
  subtitle,
  onClose,
  isLocked = false,
  className,
  withLoader = true,
  ignoreOperations = [],
  inProgress = false,
  onHover = false,
  message,
  modalMode,
  onActionClicked,
  showMoreActions,
  status,
  fromOfferScreen = false,
  ...rest
}: DOEImageModalProps) => {
  const { reqs: pendingRequests } = useOperationCounter();

  const { user } = useSelector(users);

  const headerRef = useRef(null); 

  useEffect((): (() => void) => {
    if (headerRef.current) {
      headerRef.current.focus(); 
    }
    document.body.style.overflow = 'hidden';
    // add event listener to prevent focus from leaving the modal 
    const keyDownListener : any = (event: KeyboardEvent) => handleKeyDownModal(event, 'modal');
    document.addEventListener('keydown', keyDownListener);
    return () => {
      document.removeEventListener('keydown', keyDownListener)
      document.body.style.overflow = 'unset';
    };
  }, [modalMode]);

  return ReactDOM.createPortal(
    <>
      <Backdrop {...(onClose && { onClick: onClose })} />
      <Container className={className} data-automation-id="modal" {...rest}>
        {withLoader && (
          <Loading isLoading={difference(pendingRequests, ignoreOperations).length > 0}>
            <Loader />
          </Loading>
        )}
        <ModalHeader className="ModalHeader" ref={headerRef} tabIndex={0}>
          <MainTitle>
            <TitleWithProgress>
              {title && <TextModalTitle text={title} />}
              {inProgress && <ProgressLoader size={35} message={message} onHover={onHover} />}
            </TitleWithProgress>
            {isLocked && <LockIcon />}
            {modalMode === FormMode.View && (
                <>
                {showMoreActions && !fromOfferScreen && (
                <StyledButtonDropdown
                    items={
                        user.role == UserRole.Admin || user.role === UserRole.SysAdmin ? 
                        Object.values(DOEImageActionsList) :
                        Object.values(DOEImageActionsListForNonAdmin)
                    }
                    onItemClick={(item: any) => onActionClicked(item.name)}
                    position="bottom-left"
                ></StyledButtonDropdown>
                )}
                </>
            )}
          </MainTitle>
          {subtitle ?? null}
        </ModalHeader>
        <Content data-automation-id="modal-content">{children}</Content>
      </Container>
    </>,
    document.querySelector('#modal'),
  );
};

export default Modal;