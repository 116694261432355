import styled from '@emotion/styled';
import typography from 'styles/typography';
import ImpactItem from '../impactItem/ImpactItem';

export const StyledImpactTablesContainer = styled.div`
  width: fit-content;
`;

export const StyledImpactTables = styled.div`
  display: flex;
`;

export const StyledImpactItem = styled(ImpactItem)`
  width: 400px;

  &:not(:first-of-type) {
    margin-left: ${typography.unit * 10}px;
  }
`;
