import { ProductSetType } from 'utils/types';
import { LineWrapper, ProductLine } from '../../../OfferForm.style';
import { ProductPicker, StyledLabel } from '../Template.style';
import { checkForEmptyData } from './ProductsActionCondition.utils';
import getProductsAction from './ProductsActionCondition';
import { calcProductsHint } from 'utils/product';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { store } from 'app/store';
import { setData } from 'app/slices/modals';

export const templateValueIncludeNFPC = 'versions.0.templateValues.includeNonFoodProductCodes';
export const templateValueIncludeNP = 'versions.0.templateValues.includeNonProduct';

export const NonProductPLUsSection = ({
  disabled,
  includeNonProduct,
  setIncludeNonProduct,
  includeNonFoodProductCodes,
  register,
  getValues,
  onProductSelection,
  offerID,
  onProductPreviewClick,
}: any) => {
  return (
    <LineWrapper>
      <StyledLabel>Non-Product PLUs to Include</StyledLabel>
      <ProductPicker>
        <ProductLine>{calcProductsHint(includeNonFoodProductCodes)}</ProductLine>
        {getProductsAction(
          disabled || includeNonProduct,
          includeNonFoodProductCodes,
          getValues,
          'Select Products',
          'includeNonFoodProductCodes',
          (data: any) => ({ includeNonFoodProductCodes: !checkForEmptyData(data) ? data : undefined }),
          onProductSelection,
          true,
          ProductSetType.NonFoodProduct,
          null,
          offerID,
          onProductPreviewClick,
        )}
        <input
          type="hidden"
          {...register(templateValueIncludeNFPC, {
            value: includeNonFoodProductCodes,
            shouldUnregister: true,
            setValueAs: (v: any) => (v === '' ? undefined : v),
          })}
        />
      </ProductPicker>
      <Checkbox
        checked={includeNonProduct}
        disabled={disabled}
        onClick={() => {
          store.dispatch(setData({ data: { isFormDirty: true } }));
          setIncludeNonProduct(!includeNonProduct);
          store.dispatch(
            setData({
              data: {
                templateProducts: {
                  includeNonFoodProductCodes: includeNonProduct ? undefined : includeNonFoodProductCodes,
                },
              },
            }),
          );
        }}
        label="Include All"
      />
    </LineWrapper>
  );
};
