import React from 'react';
import { modal as modalSlice, Modals } from 'app/slices/modals';
import { useSelector } from 'react-redux';
import OfferForm from 'pages/offers/offerManagement/components/offerForm/OfferForm';
import { ImagesSelectionModal } from 'pages/offers/offerManagement/components/imagesSelectionModal';
import TermConditionForm from 'pages/settings/termsConditions/components/termConditionForm/TermConditionForm';
import CampaignForm from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm';
import ImageForm from 'pages/settings/images/components/imageForm/ImageForm';
import ImagesForm from 'pages/settings/images/components/imagesForm/ImagesForm';
import OfferSelection from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSelection/OfferSelection';
import ProductSetModal from 'pages/settings/productSets/components/productSetModal/ProductSetModal';
import LocationSetModal from 'pages/settings/locationSets/components/locationSetModal/LocationSetModal';
import UserManagementModal from 'pages/settings/userManagement/components/userManagementModal/UserManagementModal';
import TagForm from 'pages/settings/tags/components/tagForm/TagForm';
import ImpactModal from 'components/impact/impactModal/ImpactModal';
import CampaignSelection from 'pages/reports/redemptions/components/redemptionsFilterBar/components/campaignSelection/CampaignSelection';
import PeriodsForm from 'pages/settings/schedulePeriods/components/periodsForm/PeriodsForm';
import { ZonesManagementModal } from 'pages/offers/offerManagement/components/zonesManagementModal/ZonesManagementModal';
import MultipleDatesSelection from 'pages/campaigns/campaignManagement/components/campaignForm/components/multipleDatesSelection/MultipleDatesSelection';
import AuditImpactModal from 'components/impact/impactModal/AuditImpactModal';
import UsageModal from 'components/impact/impactModal/UsageModal';
import DoeImageForm from 'pages/settings/doeImages/components/DoeImageForm/DoeImageForm';
import SegmentIdModal from 'pages/campaigns/campaignManagement/components/campaignForm/components/audienceSection/components/SegmentIdModal/SegmentIdModal';
import { ProductViewModal } from '../productViewModal/ProductViewModal';
import CustomLocationSetModal from 'pages/settings/locationSets/components/locationSetModal/CustomLocationSetModal';
import OldUiUsageModal from 'components/impact/impactModal/OldUiUsageModal';
import ImageImpactModal from 'components/impact/impactModal/ImageImpactModal';
import TermConditionImpactModal from 'components/impact/impactModal/TermConditionImpactModal';
import GmaViewModal from 'components/shared/modal/gmaViewForm/GmaViewModal';

const ModalComponents: Record<Modals, (props: any) => JSX.Element> = {
  [Modals.OfferModal]: OfferForm,
  [Modals.ImpactModal]: ImpactModal,
  [Modals.ImageModal]: ImageForm,
  [Modals.ImagesModal]: ImagesForm,
  [Modals.ImageSelectionModal]: ImagesSelectionModal,
  [Modals.TermConditionModal]: TermConditionForm,
  [Modals.CampaignModal]: CampaignForm,
  [Modals.OfferSelectionModal]: OfferSelection,
  [Modals.ProductSetModal]: ProductSetModal,
  [Modals.LocationSetModal]: LocationSetModal,
  [Modals.CustomLocationSetModal]: CustomLocationSetModal,
  [Modals.UserManagementModal]: UserManagementModal,
  [Modals.TagModal]: TagForm,
  [Modals.CampaignSelectionModal]: CampaignSelection,
  [Modals.PeriodModal]: PeriodsForm,
  [Modals.ZonesManagementModal]: ZonesManagementModal,
  [Modals.MultipleDatesSelectionModal]: MultipleDatesSelection,
  [Modals.AuditImpactModal]: AuditImpactModal,
  [Modals.UsageModal]: UsageModal,
  [Modals.OldUiUsageModal]: OldUiUsageModal,
  [Modals.DoeImagesModal]: DoeImageForm,
  [Modals.SegmentIdModal]: SegmentIdModal,
  [Modals.ProductViewModal]: ProductViewModal,
  [Modals.ImageImpactModal]: ImageImpactModal,
  [Modals.TermConditionImpactModal]: TermConditionImpactModal,
  [Modals.GmaViewModal]: GmaViewModal,
};

export const ModalHolder = () => {
  const { modal, props } = useSelector(modalSlice);
  const ModalComponent = ModalComponents[modal];

  return ModalComponent ? <ModalComponent {...props} /> : null;
};
