import styled from '@emotion/styled';
import { NewButtonText } from 'components/shared/button';
import { Icon } from 'components/shared/icon';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import Tooltip from 'components/shared/tooltip/Tooltip';
import typography, { newTypography } from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';

export const PLUContainer = styled.div`
  display: flex;
`;

export const PLUListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.label<StyledWithThemeProps & { disabled: boolean, fieldsLength: number }>`
  height: 15px;
  gap: 0px;
  opacity: 0px;
  font-family: ${newTypography.primaryFont};
  font-size: 12px;
  font-weight: 700;
  line-height: 15.17px;
  padding-right: ${newTypography.unit * 2}px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  justify-content: flex-start;
  color: ${({ disabled }) => (disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary)};
  padding-top: ${({ fieldsLength }) => (fieldsLength > 0 ? '14px;' : '0x;')}

`;

export const StyledImagePLUList = styled.ul`
  display: flex;
  font-size: 13px;
  gap: ${typography.unit * 2}px;
  flex-direction: column;
  list-style-type: none;
  margin-top: 0;
  padding: 0;
`;

export const StyledImagePLUListItem = styled.li`
  display: flex;
  align-items: center;
  gap: ${newTypography.unit * 2}px;
  &:first-of-type {
    padding-top: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  width: 16px;
`;

export const StyledButtonTextView = styled(NewButtonText)`
  align-self: center;
  margin-left: ${typography.unit * 2}px;
  width: 16px;
`;
export const StyledButtonTextAdd = styled(NewButtonText)`
  align-self: flex-start;
  width: 16px;
`;

export const DoeProductPicker = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 12px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  padding-left: 15px;
  & button[data-automation-id="button"] {
    font-weight: 700;
    color: #1D6CA9;
  }

  ${({ disabled }) => disabled && `color: ${newGlobalTheme.colors.text.disabledSave};`}

  span {
    padding-right: 8px;
    ${({ disabled }) =>
    disabled ? `color: ${newGlobalTheme.colors.text.disabledSave};` : `color: ${newGlobalTheme.colors.text.primary};`}
  }

  #text {
    ${({ disabled }) =>
    disabled ? `color: ${newGlobalTheme.colors.text.disabledSave};` : `color: ${newGlobalTheme.colors.text.primary};`}
  }
`;

export const StyledProductTooltip = styled(Tooltip)`
  width: 500px;
  max-width: 500px;
  height: 520px;

  > div {
    height: 100%;
  }
`;
