import { useForm, useWatch } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import pick from 'lodash/pick';
import { FormMode } from 'utils/types';
import { FormTitle, PeriodFormProps } from 'pages/settings/schedulePeriods/components/periodsForm/PeriodsForm.consts';
import { useSelector } from 'react-redux';
import { addDays, convertUtcDateToTimezoneDate, convertUtcDateToTimezoneDateUsingLibraryStartDate, getMarketConfigurationTimezoneOffsetLocalTime, getYesterday } from 'utils/date';
import { PeriodProps } from 'pages/settings/schedulePeriods/SchedulePeriods.consts';
import ReactTooltip from 'react-tooltip';
import {
  FormContainer,
  FormFooter,
  FormRow,
  PeriodName,
  StartDate,
  StyledDatePicker,
  StyledMessage,
  StyledModal,
} from 'pages/settings/schedulePeriods/components/periodsForm/PeriodsForm.style';
import { ButtonContained, ButtonText } from 'components/shared/button';
import { store } from 'app/store';
import { closeModal } from 'app/slices/modals';
import { hideTooltip } from 'utils/tooltip';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { MessageType } from 'components/shared/notifications/notifications';
import { marketConfig } from 'app/slices/config';
import { periodsGqls } from 'pages/settings/schedulePeriods/SchedulePeriods.gqls';
import { useToastError } from 'hooks/use-toast-error';
import { useQuery } from '@apollo/client';
import { createPeriod, editPeriod } from './utils/PeriodsActions';
import { Period } from 'utils/types/period';
import { FetchPolicies, ValidationMessages } from 'utils/types/common';

const PeriodsForm = ({ mode, period, periods }: PeriodFormProps) => {
  const getModalTitle = () => `${FormTitle[mode]} ${period?.name ? period.name : ''}`;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [overlap, setOverlap] = useState(null);
  let periodById: { id: number; name: any; startDate: string | number | Date; endDate: string | number | Date; hasCampaigns: any; };
  const [formValues, setFormValues] = useState({
    name: true,
    startDate: true,
    endDate: true
  })
  const [canSave, setCanSave] = useState(true)


  if (mode === FormMode.Edit) {
    const { data, error } = useQuery(periodsGqls.queries.getById, {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      variables: {
        id: Number(period.id),
      },
    });
    
    if (data && data.getPeriod) {
      const { getPeriod } = data;
      periodById = getPeriod;
    }
  
    useToastError(error, 'Error loading Period');
  }
  const allPeriods = periods?periods:undefined;
  let latestPeriod: Period = null;
  allPeriods.forEach((period1: Period) => {
    if (!latestPeriod || period1.startDate > latestPeriod.startDate) {
      latestPeriod = period1;
    }
  })

  let periodNextToEditPeriod: Period;
  if (mode === FormMode.Edit) {
    const idOfcurrentPeriod = allPeriods?.indexOf(period);
    if (idOfcurrentPeriod > 0) {
      periodNextToEditPeriod = allPeriods[idOfcurrentPeriod - 1];
    }
  }

  const { config } = useSelector(marketConfig);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      id: period?.id,
      name: period?.name || '',
      startDate: period
        ? convertUtcDateToTimezoneDate(period.startDate, config.startTimezone)
        : latestPeriod
        ? convertUtcDateToTimezoneDateUsingLibraryStartDate(latestPeriod.endDate, config.startTimezone,1)
        : convertUtcDateToTimezoneDate(new Date(addDays(new Date(), 1).setHours(0, 0, 0, 0)),config.startTimezone),
      endDate: period
        ? convertUtcDateToTimezoneDate(period.endDate, config.endTimezone)
        : latestPeriod
        ? convertUtcDateToTimezoneDateUsingLibraryStartDate(latestPeriod.endDate, config.startTimezone,2)
        : convertUtcDateToTimezoneDate(new Date(addDays(new Date(), 2).setHours(23, 59, 59, 999)),config.endTimezone),
    } as any,
  });
 
  const startDate = useWatch({ control, name: 'startDate' });
  const endDate = useWatch({ control, name: 'endDate' });

  const startOffsetHours = getMarketConfigurationTimezoneOffsetLocalTime(config.startTimezone, startDate) / 3600000;
  const endOffsetHours = getMarketConfigurationTimezoneOffsetLocalTime(config.endTimezone, endDate) / 3600000 + 23;
  const startOffsetMinutes = (startOffsetHours % 1) * 60;
  const endOffsetMinutes = ((endOffsetHours % 1) * 60) + 59;

  const onSubmit = async (formData: any) => {
    setIsSubmitting(true);
    const periodToSave = { ...pick(formData, ['name', 'startDate', 'endDate']) } as any as PeriodProps;
    periodToSave.startDate = new Date(formData.startDate);
    periodToSave.endDate = new Date(formData.endDate);
    periodToSave.startDate.setHours( startOffsetHours<0?Math.ceil(startOffsetHours) :Math.floor(startOffsetHours), startOffsetMinutes, 0, 0)
    periodToSave.endDate.setHours(Math.floor(endOffsetHours), endOffsetMinutes, 59, 999)

    if (mode === FormMode.New) {
      await createPeriod(periodToSave);
    } else if (mode === FormMode.Edit) {
      periodToSave.id = Number(periodById?.id);
      await editPeriod(periodToSave);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isDirty, overlap]);

  useEffect(() => {
    if (mode === FormMode.Edit) {setCanSave(!(formValues.name && formValues.startDate && formValues.endDate))}
  }, [canSave, formValues.name, formValues.startDate, formValues.endDate])

  useEffect(() => {
    if (startDate && endDate) {
      trigger('startDate');
      setOverlap(
        allPeriods.find(
          (p: PeriodProps) =>
            (p.id !== period?.id &&
              new Date(startDate).setHours(startOffsetHours<0?Math.ceil(startOffsetHours) :Math.floor(startOffsetHours), startOffsetMinutes, 0, 0) >
                new Date(p.startDate).getTime() &&
              new Date(startDate).setHours(startOffsetHours<0?Math.ceil(startOffsetHours) :Math.floor(startOffsetHours), startOffsetMinutes, 0, 0) <
                new Date(p.endDate).getTime()) ||
            (p.id !== period?.id &&
              new Date(endDate).setHours(Math.floor(endOffsetHours), endOffsetMinutes, 59, 999) >
                new Date(p.startDate).getTime() &&
              new Date(endDate).setHours(Math.floor(endOffsetHours), endOffsetMinutes, 59, 999) <
                new Date(p.endDate).getTime()),
        ),
      );
    }
  }, [startDate, endDate]);


  return (
    <StyledModal title={getModalTitle()}>
      <FormContainer>
        <FormRow>
          <PeriodName
            register={register}
            errors={errors}
            name="name"
            label="Name"
            placeholder="Enter"
            onChange={mode === FormMode.Edit ?
              name => {
                setFormValues({...formValues, [name.target.name]: name.target.value === periodById.name});
              }:null}
            validation={{
              required: ValidationMessages.RequiredField,
              maxLength: { value: 100, message: 'Up to 100 characters' },
            }}
            labelIsHorizontal
          />
        </FormRow>
        <FormRow>
          <StartDate
            control={control}
            name="startDate"
            label="Start Date"
            errors={errors}
            disabled
            validation={{
              validate: {
                inputValidity: (date: Date) => {
                  const currDateTime = new Date(date).getTime();
                  if (Number.isNaN(currDateTime)) {
                    return `Date is not valid`;
                  }
                },
                range: (date: Date) => {
                  const start = new Date(date) as any;
                  const end = new Date(endDate) as any;
                  const diffDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
                  if (diffDays < 0) {
                    return `Start date should be before end date`;
                  }
                },
              },
            }}
            onChange={mode===FormMode.Edit ? date => {
              if (date) {
                const dateCleaned = new Date(date)
                const periodDateCleaned = convertUtcDateToTimezoneDate(new Date(periodById.startDate), config.startTimezone)
                dateCleaned.setHours(Math.floor(startOffsetHours), startOffsetMinutes, 0, 0)
                setFormValues({...formValues, ["startDate"]: dateCleaned.toDateString() === periodDateCleaned.toDateString()})
              }
            }:null}
            minDate={new Date()}
          />
          <StyledDatePicker
            control={control}
            name="endDate"
            label="End Date"
            errors={errors}
            validation={{
              validate: {
                inputValidity: (date: Date) => {
                  const currDateTime = new Date(date).getTime();
                  if (Number.isNaN(currDateTime)) {
                    return `Date is not valid`;
                  }
                  if (currDateTime < getYesterday().getTime()) {
                    return `End time is in the past`;
                  }
                },
              },
            }}
            onChange={mode === FormMode.Edit ? date => {
              if (date) {
                const dateCleaned = new Date(date)
                const periodDateCleaned = convertUtcDateToTimezoneDate(new Date(periodById.endDate), config.endTimezone)
                dateCleaned.setHours(Math.floor(endOffsetHours), endOffsetMinutes, 0, 0)
                setFormValues({...formValues, ["endDate"]: dateCleaned.toDateString() === periodDateCleaned.toDateString()})
              }
            }:null}
            minDate={addDays(startDate,1) || new Date()}
            maxDate={
              mode === FormMode.Edit && periodNextToEditPeriod
                ? convertUtcDateToTimezoneDate(
                    addDays(periodNextToEditPeriod.startDate, -1).toISOString(),
                    config.endTimezone,
                  )
                : undefined
            }
          />
        </FormRow>
        {mode === FormMode.Edit && periodById?.hasCampaigns && (
          <StyledMessage type={MessageType.Info}>
            <b>Note:</b> This period contains campaigns. Please adjust campaign schedule if needed.
          </StyledMessage>
        )}
      </FormContainer>
      <FormFooter>
        <ButtonText
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              store.dispatch(closeModal());
            }
          }}  
          {...(isDirty ? { 'data-tip': true, onClick: () => null } : { onClick: () => store.dispatch(closeModal()) })}
          data-for="cancel-tooltip"
        >
          Cancel
        </ButtonText>
        {overlap ? (
          <ButtonContained
            onClick={() => null}
            data-tip
            data-for="overlap-tooltip"
            disabled={(!isValid || isSubmitting) || !canSave}
            key="overlap"
          >
            Save
          </ButtonContained>
        ) : (
          <ButtonContained onClick={handleSubmit(onSubmit)} disabled={(!isValid || isSubmitting) ||!canSave} key="no-overlap">
            Save
          </ButtonContained>
        )}
        <Tooltip
          id="overlap-tooltip"
          content={`Overlap with ${overlap?.name}.\nAre you sure?`}
          onDisapproveClick={() => {
            hideTooltip('#overlap-tooltip');
          }}
          approveMsg="Yes, Save"
          onApproveClick={handleSubmit(onSubmit)}
        />
        <Tooltip
          id="cancel-tooltip"
          content="Are you sure you want to cancel?"
          onDisapproveClick={() => {
            hideTooltip('#cancel-tooltip');
          }}
          onApproveClick={() => store.dispatch(closeModal())}
        />
      </FormFooter>
    </StyledModal>
  );
};

export default PeriodsForm;
