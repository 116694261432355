import React from 'react';
import { StyledButton,StyledNewButton  } from './ButtonBase.style';
import { ButtonBaseProps, NewButtonBaseProps } from './ButtonBase.consts';

const ButtonBase = ({ disabled = false, type = 'button', onClick, onKeyDown, children, className,hide=false, ...rest }: ButtonBaseProps) => {
  return (
    <StyledButton
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={className}
      data-automation-id="button"
      hide={hide}
      tabIndex={0}
      onKeyDown={onKeyDown}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export const NewButtonBase = ({ disabled = false, type = 'button', onClick, children, className, onKeyDown, noStopPropagation, hide=false, ...rest }: NewButtonBaseProps) => {
  return (
    <StyledNewButton
      disabled={disabled}
      onClick={onClick}
      onKeyDown={noStopPropagation ? (onKeyDown ? onKeyDown : null)  : (e: any) => {
        e.stopPropagation(); // Stop propagation when noKeyDown is falsy
        if (onKeyDown) {
          onKeyDown(e); // Call the parent's onKeyDown handler
        }
      }}
      type={type}
      className={className}
      data-automation-id="button"
      hide={hide}
      tabIndex={0}
      {...rest}
    >
      {children}
    </StyledNewButton>
  );
};

export default ButtonBase;
