import React from 'react';
import { getIsControlWithError } from 'utils/form';
import { TextAreaProps } from './TextArea.consts';
import { StyledContainer, StyledError, StyledLabel, StyledTextArea } from './TextArea.style';

const TextArea = ({
  register,
  name,
  validation,
  value,
  placeholder,
  disabled = false,
  errors,
  label,
  rows,
  cols,
  className,
  labelIsHorizontal,
  scrollOnDisabled = false,
  shouldUnRegister = true,
  ...rest
}: TextAreaProps) => {
  return (
    <StyledContainer className={className}>
      {label && (
        <StyledLabel labelIsHorizontal={labelIsHorizontal} disabled={disabled}>{`${label}${validation?.required ? '*' : ''}`}</StyledLabel>
      )}
      <StyledTextArea
        {...(register &&
          register(name, { ...validation, shouldUnregister: shouldUnRegister , setValueAs: (v: string) => v?.trim() }))}
        placeholder={placeholder}
        readOnly={disabled}
        defaultValue={value}
        rows={rows}
        cols={cols}
        error={getIsControlWithError(name, errors)}
        data-automation-id="textarea"
        disabled={disabled}
        scrollOnDisabled={scrollOnDisabled}
        {...rest}
      />
      <StyledError name={name} errors={errors} />
    </StyledContainer>
  );
};

export default TextArea;
