import styled from '@emotion/styled';
import { ButtonContained } from 'components/shared/button';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import typography from 'styles/typography';
import zIndex from 'styles/zIndex';

export const FormFooter = styled.footer`
  display: flex;
  margin-top: ${typography.unit * 9}px;
  gap: ${typography.unit * 5}px;
`;

export const StyledButtonContained = styled(ButtonContained)`
  margin-left: ${typography.unit * 5}px;
  &:focus-visible {
  outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
  outline-offset: 2px;
  z-index: ${zIndex.tabNavigation};
  transition: none;
  }
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  height: 32px;
  font-size: 14px;
  font-weight: 700;
  color: black;
  &:hover {
    background-color: ${({ theme }) => '#FFBC0D'};
  }
  /* McDonald's Gold */
  background: #FFBC0D;
  /* Accent Gold */
  border: 1px solid #C08B00;
  border-radius: 5px;
  flex: none;
  order: 1;
  flex-grow: 0;

  &:disabled {
  background-color: ${newGlobalTheme.colors.global.disabledGold};
  border:none;
  color: ${newGlobalTheme.colors.global.borderColorboxes};
`;