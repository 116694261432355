import React from 'react';
import { DateTimePicker as MuiDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Controller } from 'react-hook-form';
import { ThemeProvider } from '@mui/material/styles';
import { DateFormats } from 'utils/types';
import { marketConfig } from 'app/slices/config';
import { useSelector } from 'react-redux';
import { convertUtcDateToTimezoneDate, dayOfWeekRecord } from 'utils/date';
import locale from 'date-fns/locale/en-US';
import { DateTimePickerProps, materialTheme } from './DateTimePicker.consts';
import { StyledDateTimeContainer, StyledError, StyledLabel } from './DateTimePicker.style';

export const DateTimePicker = ({
  validation,
  errors,
  name,
  withAmPm = true,
  label,
  labelIsHorizontal,
  disabled,
  minDate,
  maxDate,
  defaultValue,
  className,
  control,
  timezone,
  shouldUnRegister,
}: DateTimePickerProps) => {
  const { config } = useSelector(marketConfig);
  const formattedDate = `${config.dateFormat === DateFormats.DayMonthYear ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}  ${
    withAmPm ? 'hh:mm a' : 'HH:mm'
  }`;
  if (locale && locale.options) {
    locale.options.weekStartsOn = dayOfWeekRecord[config.calendarWeekStartsOn] || 0;
  }

  return (
    <StyledDateTimeContainer
      className={className}
      labelIsHorizontal={labelIsHorizontal}
      disabled={disabled}
      data-automation-id="datetime-picker"
      withAmPm={withAmPm}
    >
      {label && (
        <StyledLabel labelIsHorizontal={labelIsHorizontal} disabled={disabled}>{`${label}${
          validation?.required ? '*' : ''
        }`}</StyledLabel>
      )}
      <Controller
        name={name}
        {...(shouldUnRegister ? ({shouldUnregister: shouldUnRegister}):({}))}
        control={control}
        rules={validation}
        defaultValue={defaultValue}
        render={({ field }: any) => (
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale} localeText={{
            okButtonLabel: 'Close',
          }}>
            <ThemeProvider theme={materialTheme}>
              <MuiDateTimePicker
               slotProps={{ textField: { variant: 'standard', } }} 
                data-automation-id={`data-time-picker-${label}`}
                ampm={withAmPm}
                value={convertUtcDateToTimezoneDate(field.value, timezone)}
                onChange={(date) => {
                  field.onChange(date);
                }}
                format={formattedDate}
                disabled={disabled}
                minDateTime={minDate}
                maxDateTime={maxDate}
              />
            </ThemeProvider>
          </LocalizationProvider>
        )}
      />
      <StyledError name={name} errors={errors} />
    </StyledDateTimeContainer>
  );
};
