import React from 'react';
import { GridCellParams, GridColumns } from '@mui/x-data-grid-pro';
import { ApprovalStatus } from 'utils/types';
import { capitalize } from 'utils/text';
import { StyledIcon } from 'pages/settings/tags/Tags.style';
import { ImpactType } from '../impactItem/ImpactItem.consts';
import NewStatusLabel from 'components/shared/statusLabel/NewStatusLabel';

export const getColumns = (impactType: ImpactType, entityType?: 'TermCondition' | 'Offer' | 'Image' | 'Tag') => {
  const generateColumns = (impactType: ImpactType): GridColumns => {
    const isDefaultColumn = entityType === 'TermCondition' &&
      impactType === ImpactType.Offer && {
        field: 'isDefault',
        headerName: 'Default T&C',
        width: 86,
        type: 'boolean',
        sortable: false,
        align: 'left',
        renderCell: (params: GridCellParams) => {
          const { value }: any = params;
          return value ? <StyledIcon name="checkmark" /> : '';
        },
      };
    const columns = [
      {
        field: `title`,
        headerName: `${impactType} Title`,
        width: impactType === ImpactType.Offer ? 120 : 160,
        sortable: false,
      },
      {
        field: `entityId`,
        headerName: `${impactType} ID`,
        width: impactType === ImpactType.Offer ? 64 : 95,
        sortable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 125,
        renderCell: (params: GridCellParams) => {
          const { value } = params;
          return impactType === ImpactType.Image ? (
            capitalize(value as string)
          ) : (
            <NewStatusLabel type={value as ApprovalStatus} />
          );
        },
        sortable: false,
      },
    ];
    if (isDefaultColumn) {
      columns.push(isDefaultColumn);
    }
    return columns;
  };
  return generateColumns(impactType);
};
