import React, { useState } from 'react';
import { CopyToClipboardButtonProps } from './CopyButton.props';
import { StyledClipboard, StyledCopyButton } from './CopyButton.style';

const CopyButton = ({ textToCopy }: CopyToClipboardButtonProps) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
  };

  // Handles accessibility for copy button
  const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleCopyClick(); 
        setCopied(false); 
      }   
  }

  const handleFocus = () => {
    // Reset to "Copy" when the element gains focus, for accessibility
    setCopied(false);
  };

  const handleBlur = () => {
    // Reset to "Copy" when the element loses focus (after tabbing past)
    setCopied(false);
  };

  return (
    <div 
      tabIndex={0} 
      onKeyDown={handleKeyDown} 
      onMouseLeave={() => setCopied(false)}
      onFocus={handleFocus}
      onBlur={handleBlur} 
      >
      <StyledCopyButton 
          data-automation-id="copy-image-view"  
          aria-live="assertive" 
          onClick={handleCopyClick} 
          aria-label={copied ?  'Copied!' : 'Copy'} 
          data-title={copied ? 'Copied!' : 'Copy'} >
        <StyledClipboard name="newCopy" height={20} width={20}/>
      </StyledCopyButton>
    </div>
  );
};

export default CopyButton;
