import styled from '@emotion/styled/macro';
import newTypography from 'styles/typography';
import { CheckboxContainerProps, NewStyledCheckboxProps, NewStyledLabelProps } from './Checkbox.consts';
import { Icon, IconProps } from '../icon';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  line-height: 15px;
`;

export const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckmark = styled(Icon)<IconProps>`
  width: 16px;
  height: 16px;
`;

export const StyledIntermediate = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.checkbox.iconColor};
  width: 10px;
`;

export const StyledLabel = styled.label<NewStyledLabelProps>`
  font-size: 12px;
  font-weight: 400;
  margin-left: ${newTypography.unit * 2}px;
  color: ${newGlobalTheme.colors.text.primary};
  cursor: pointer;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: initial;
`;

export const StyledCheckbox = styled.div<NewStyledCheckboxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  border: 
    ${({ checked, intermediate, disabled, theme }) =>
    checked || intermediate
        ? `none`
        : disabled
        ? `1px solid ${theme.colors.global.inputDisabled};` 
        : `1px solid ${theme.colors.global.border};`}

  border-radius: 3px;
  transition: all 150ms;
`;
