import { timeZonesSelectionList } from 'utils/timezones';
import {
  CalendarWeekStartsOn as CalendarWeekStartsOnOptions,
  CalendarWeekStartsOnRecord,
  Currency as CurrencyOptions,
  DateFormats,
  TimeFormats,
} from 'utils/types';
import { DoubleDippingRule, DoubleDippingRuleRecord, FilteringLogic, OfferTemplates } from 'utils/types/offers';
import { LanguageCodesSelectBoxValues } from 'utils/languages';

export interface ConfigurationDefinition {
  label: string;
}

export const enum ComponentType {
  Select = 'select',
  Text = 'text',
  Boolean = 'boolean',
  TimesArray = 'timesArray',
  TextSelect = 'textSelect',
}

export interface SelectConfig extends ConfigurationDefinition {
  componentType: ComponentType.Select;
  items: any;
  initialSelectedItems?: any;
  withSearch?: boolean;
}

export interface TextConfig extends ConfigurationDefinition {
  componentType: ComponentType.Text;
  inputType: 'string' | 'number';
  validation ?: any;
}

export interface TextSelectConfig extends ConfigurationDefinition {
  componentType: ComponentType.TextSelect;
  multiSelect: SelectConfig;
}

export interface BooleanConfig extends ConfigurationDefinition {
  componentType: ComponentType.Boolean;
}

export interface TimesArrayConfig extends ConfigurationDefinition {
  componentType: ComponentType.TimesArray;
  selectMultiple: boolean;
}

export type ConfigurationsDefinition = SelectConfig | TextConfig | BooleanConfig | TimesArrayConfig | TextSelectConfig;

export enum DOEMarketConfigurationKey {
  EnableDigitalOffersEngine = 'enableDigitalOffersEngine',
  doeTnCs = 'doeTnCs',
  bogo = 'bogo',
  singleItemDiscount = 'singleItemDiscount',
  multiItemDiscount = 'multiItemDiscount',
  substitution = 'substitution',
  orderDiscount = 'orderDiscount',
  buyBuyGet = 'buyBuyGet',
  buyGetGet = 'buyGetGet',
  priceDeal = 'priceDeal',
  comboPriceDeal = 'comboPriceDeal',
  productCombo = 'productCombo',
}

export enum MarketConfigurationKey {
  PrimaryLanguage = 'primaryLanguage',
  SecondaryLanguage = 'secondaryLanguage',
  IsSecondaryLanguageEnforced = 'isSecondaryLanguageEnforced',
  DateFormat = 'dateFormat',
  TimeFormat = 'timeFormat',
  CalendarWeekStartsOn = 'calendarWeekStartsOn',
  Currency = 'currency',
  EnableOfferSelfApproval = 'enableOfferSelfApproval',
  EnableCampaignSelfApproval = 'enableCampaignSelfApproval',
  DefaultDoubleDippingRule = 'defaultDoubleDippingRule',
  StartTimezone = 'startTimezone',
  EndTimezone = 'endTimezone',
  ApprovalRequiredNotificationThreshold = 'approvalRequiredNotificationThreshold',
  NoRedemptionsNotificationThreshold = 'noRedemptionsNotificationThreshold',
  NoRedemptionsNotificationTimezone = 'noRedemptionsNotificationTimezone',
  NoRedemptionsNotificationTime = 'noRedemptionsNotificationTime',
  CampaignArchivePeriod = 'campaignArchivePeriod',
  OfferArchivePeriod = 'offerArchivePeriod',
  EnableManagementByZone = 'enableManagementByZone',
  EnableSchedulePeriods = 'enableSchedulePeriods',
  SyncTimeZone = 'syncTimeZone',
  ProductSyncTime = 'productSyncTime',
  LocationSyncTime = 'locationSyncTime',
  AutoDeleteLocationCutOff = 'autoDeleteLocationCutOff',
  AutoDeleteProductCutOff = 'autoDeleteProductCutOff',
  AutoDeleteAuditCutOff = 'autoDeleteAuditCutOff',
  RedemptionsDataRetentionLimit = 'autoDeleteRedemptionsDataCutOff',
  BulkRevokeStopAssociationBatchSize = 'bulkRevokeStopAssociationBatchSize',
  BulkRevokeStopAutoRetryLimit = 'bulkRevokeStopAutoRetryLimit',
  DigitalDownload = 'enableDigitalDownload',
  PushNotificationLimit = 'pushNotificationLimit',
  EnableEOLV = 'enableEOLV',
  FilterLogic = 'filterLogic'
}

export const DOEConfigurationsDefinitions: Record<DOEMarketConfigurationKey, ConfigurationsDefinition> = {
  [DOEMarketConfigurationKey.EnableDigitalOffersEngine]: {
    label: 'Enable Digital Offers Engine',
    componentType: ComponentType.Boolean,
  },
  [DOEMarketConfigurationKey.doeTnCs]: {
    label: '',
    componentType: ComponentType.TextSelect,
    multiSelect: {
      componentType: ComponentType.Select,
      items: Object.values(OfferTemplates).map((offer) => {
        return {
          id: offer.id,
          name: offer.name,
        };
      }),
      label: '',
    },
  },
  [DOEMarketConfigurationKey.bogo]: {
    label: 'BOGO',
    componentType: ComponentType.Text,
    inputType: 'string',
  },
  [DOEMarketConfigurationKey.singleItemDiscount]: {
    label: 'Single Item Discount',
    componentType: ComponentType.Text,
    inputType: 'string',
  },
  [DOEMarketConfigurationKey.multiItemDiscount]: {
    label: 'Multi Item Discount',
    componentType: ComponentType.Text,
    inputType: 'string',
  },
  [DOEMarketConfigurationKey.substitution]: {
    label: 'Substitution',
    componentType: ComponentType.Text,
    inputType: 'string',
  },
  [DOEMarketConfigurationKey.orderDiscount]: {
    label: 'OrderDiscount',
    componentType: ComponentType.Text,
    inputType: 'string',
  },
  [DOEMarketConfigurationKey.buyBuyGet]: {
    label: 'Buy, Buy, Get',
    componentType: ComponentType.Text,
    inputType: 'string',
  },
  [DOEMarketConfigurationKey.buyGetGet]: {
    label: 'Buy, Get, Get',
    componentType: ComponentType.Text,
    inputType: 'string',
  },
  [DOEMarketConfigurationKey.priceDeal]: {
    label: 'Price Deal',
    componentType: ComponentType.Text,
    inputType: 'string',
  },
  [DOEMarketConfigurationKey.comboPriceDeal]: {
    label: 'Combo Price Deal',
    componentType: ComponentType.Text,
    inputType: 'string',
  },
  [DOEMarketConfigurationKey.productCombo]: {
    label: '2/3/4 Product Combo',
    componentType: ComponentType.Text,
    inputType: 'string',
  },
};

export const ConfigurationsDefinitions: Record<MarketConfigurationKey, ConfigurationsDefinition> = {
  [MarketConfigurationKey.PrimaryLanguage]: {
    label: 'Primary Language',
    componentType: ComponentType.Select,
    items: LanguageCodesSelectBoxValues,
    withSearch: true,
  },
  [MarketConfigurationKey.SecondaryLanguage]: {
    label: 'Secondary Language',
    componentType: ComponentType.Select,
    items: [{ id: 'none', name: 'None' }, ...LanguageCodesSelectBoxValues],
    initialSelectedItems: ['none'],
    withSearch: true,
  },
  [MarketConfigurationKey.IsSecondaryLanguageEnforced]: {
    label: 'Enforce Second Language',
    componentType: ComponentType.Boolean,
  },
  [MarketConfigurationKey.DateFormat]: {
    label: 'Date Format',
    componentType: ComponentType.Select,
    items: Object.values(DateFormats).map((format) => {
      return {
        id: format,
        name: format,
      };
    }),
  },
  [MarketConfigurationKey.TimeFormat]: {
    label: 'Time Format',
    componentType: ComponentType.Select,
    items: Object.values(TimeFormats).map((format) => {
      return {
        id: format,
        name: format,
      };
    }),
  },
  [MarketConfigurationKey.CalendarWeekStartsOn]: {
    label: 'Calendar Week Starts On',
    componentType: ComponentType.Select,
    items: Object.values(CalendarWeekStartsOnOptions).map((day) => {
      return {
        id: day,
        name: CalendarWeekStartsOnRecord[day],
      };
    }),
  },
  [MarketConfigurationKey.Currency]: {
    label: 'Currency',
    componentType: ComponentType.Select,
    items: Object.values(CurrencyOptions).map((cur) => {
      return {
        id: cur,
        name: cur,
      };
    }),
  },

  [MarketConfigurationKey.EnableOfferSelfApproval]: {
    label: 'Enable Self Approval for Offers',
    componentType: ComponentType.Boolean,
  },
  [MarketConfigurationKey.EnableCampaignSelfApproval]: {
    label: 'Enable Self Approval for Campaigns',
    componentType: ComponentType.Boolean,
  },
  [MarketConfigurationKey.DefaultDoubleDippingRule]: {
    label: 'Double Dipping Default Rule',
    componentType: ComponentType.Select,
    items: Object.values(DoubleDippingRule).map((ddr) => {
      return {
        id: ddr,
        name: DoubleDippingRuleRecord[ddr],
      };
    }),
  },
  [MarketConfigurationKey.StartTimezone]: {
    label: 'Start Time Zone',
    componentType: ComponentType.Select,
    items: timeZonesSelectionList,
    withSearch: true,
  },
  [MarketConfigurationKey.EndTimezone]: {
    label: 'End Time Zone',
    componentType: ComponentType.Select,
    items: timeZonesSelectionList,
    withSearch: true,
  },
  [MarketConfigurationKey.ApprovalRequiredNotificationThreshold]: {
    label: 'Approval Required Notification Threshold',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.NoRedemptionsNotificationThreshold]: {
    label: 'No Redemptions Notification Threshold',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.NoRedemptionsNotificationTimezone]: {
    label: 'No Redemptions Notification Time Zone',
    componentType: ComponentType.Select,
    items: timeZonesSelectionList,
    withSearch: true,
  },
  [MarketConfigurationKey.NoRedemptionsNotificationTime]: {
    label: 'No Redemptions Notification Times',
    componentType: ComponentType.TimesArray,
    selectMultiple: true,
  },
  [MarketConfigurationKey.CampaignArchivePeriod]: {
    label: 'Campaign Archive Period',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.OfferArchivePeriod]: {
    label: 'Offer Archive Period',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.EnableManagementByZone]: {
    label: 'Enable Management by Zone',
    componentType: ComponentType.Boolean,
  },
  [MarketConfigurationKey.EnableSchedulePeriods]: {
    label: 'Enable Schedule Periods',
    componentType: ComponentType.Boolean,
  },
  [MarketConfigurationKey.SyncTimeZone]: {
    label: 'Sync Time Zone',
    componentType: ComponentType.Select,
    items: timeZonesSelectionList,
    withSearch: true,
  },
  [MarketConfigurationKey.ProductSyncTime]: {
    label: 'Product Changes Sync Time',
    componentType: ComponentType.TimesArray,
    selectMultiple: false,
  },
  [MarketConfigurationKey.LocationSyncTime]: {
    label: 'Location Changes Sync Time',
    componentType: ComponentType.TimesArray,
    selectMultiple: false,
  },
  [MarketConfigurationKey.AutoDeleteLocationCutOff]: {
    label: 'Location changes data - Retention Limit',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.AutoDeleteProductCutOff]: {
    label: 'Product changes data - Retention Limit',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.AutoDeleteAuditCutOff]: {
    label: 'Audit report - Retention Limit',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.RedemptionsDataRetentionLimit]: {
    label: 'Redemptions data - Retention Limit',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.BulkRevokeStopAssociationBatchSize]: {
    label: 'Bulk Revoke/Stop Association Batch Size',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.BulkRevokeStopAutoRetryLimit]: {
    label: 'Bulk Revoke/Stop Auto-Retry Limit',
    componentType: ComponentType.Text,
    inputType: 'number',
  },
  [MarketConfigurationKey.DigitalDownload]: {
    label: 'Digital Download',
    componentType: ComponentType.Boolean,
  },
  [MarketConfigurationKey.PushNotificationLimit]: {
    label: 'Push Notification Limit',
    componentType: ComponentType.Text,
    inputType: 'number',
    validation: {
      min: { value: 1, message: 'Value should be greater than or equal to 1' },
      max: { value: 10, message: 'Value should be less than or equal to 10' },
    },
  },
  [MarketConfigurationKey.EnableEOLV]: {
    label: 'Enable EOLV For BBG & BGG',
    componentType: ComponentType.Boolean,
  },
  [MarketConfigurationKey.FilterLogic]: {
    label: 'Filtering Logic',
    componentType: ComponentType.Select,
    items: Object.values(FilteringLogic).map((operatorType) => {
      return {
        id: operatorType,
        name: operatorType,
      };
    }),
  },
};
