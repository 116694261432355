import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { TableProps } from 'components/shared/table/Table.consts';
import ActionsCell from 'components/shared/actionsCell/ActionsCell';
import { ActionType } from 'components/shared/actionsCell/ActionsCell.consts';
import { PeriodProps, PeriodStatus, PeriodStatusLabel } from 'pages/settings/schedulePeriods/SchedulePeriods.consts';
import { StatusCircle, StatusWrapper } from 'pages/settings/schedulePeriods/SchedulePeriods.style';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { DateTimeConfig } from 'utils/types';
import { formatDate } from 'utils/date';

const getDates = (params: any) => {
  const { config } = useSelector(marketConfig);
  const { dateFormat, startTimezone, endTimezone } = config;
  const dateTimeConfig: DateTimeConfig = { dateFormat, startTimezone, endTimezone };
  const startDate = formatDate(new Date(params.row.startDate), dateTimeConfig, null, {
    timeZone: dateTimeConfig.startTimezone,
  });
  const endDate = formatDate(new Date(params.row.endDate), dateTimeConfig, null, {
    timeZone: dateTimeConfig.endTimezone,
  });
  return `${startDate || ''} - ${endDate || ''}`;
};

export const getSchedulePeriodsTableProps = (
  onActionClick: (actionType: ActionType, schedulePeriod: PeriodProps) => void,
): TableProps => ({
  columns: [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      sortable: true,
    },
    {
      field: 'dates',
      headerName: 'DateComponent',
      width: 250,
      sortable: true,
      valueGetter: getDates,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 135,
      sortable: true,
      renderCell: (params: GridCellParams) => {
        const { value }: any = params;
        return (
          <StatusWrapper>
            <StatusCircle status={value} />
            {PeriodStatusLabel[value as PeriodStatus]}
          </StatusWrapper>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 80,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        const { id, row } = params;
        return (
         row?.status === PeriodStatus.NotStarted && (
            <div id={`Actions-${id}`} style={{ display: 'none' }}>
              <ActionsCell
                actionTypes={[ActionType.Edit]}
                onActionClick={(actionType: ActionType) => onActionClick(actionType, row as PeriodProps)}
              />
            </div>
          )
        );
      },
    },
  ],
  rows: [] as any[],
  sortingMode: 'server',
  sortingOrder: ['desc', 'asc'],
});
