import React, { useEffect, useState } from 'react';
import { DiscountType } from 'utils/types/offers';
import { useFormContext, useWatch } from 'react-hook-form';
import { calcProductsHint } from 'utils/product';
import useDiscountType from 'hooks/use-discount-type';
import { EnterValueWrapper, ProductPicker, StyledForSelectbox, StyledLabel, StyledCheckbox } from '../Template.style';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import getProductsAction from '../shared/ProductsActionCondition';
import { Currency } from '../shared/Currency/Currency';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { LineWrapper, OfferInfoWrapper, ProductLine } from '../../../OfferForm.style';
import { getIsControlWithError } from 'utils/form';
import OfferTemplateTextField from 'components/shared/textField/OfferTemplateTextField';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import {
  NonProductPLUsSection,
  templateValueIncludeNFPC,
  templateValueIncludeNP,
} from '../shared/NonProductPLUsSection';

export const BuyBuyGet = ({
  disabled,
  onProductSelection,
  offerSource,
  mode,
  offerID,
  onProductPreviewClick,
}: TemplateProps) => {
  const {
    register,
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { config } = useSelector(marketConfig);
  const isEOLVEnabled = config.enableEOLV;

  const selectProducts = 'Select Products';
  const { discountType, isFree, isPercent, shouldDisplayCurrency } = useDiscountType();
  const [eolv, setEolv] = useState(watch('versions.0.templateValues.eolvEnhancedLogic'));
  const [buyProductsA, buyProductsB, getProducts] = useWatch({
    control,
    name: [
      'versions.0.templateValues.buyProductsA',
      'versions.0.templateValues.buyProductsB',
      'versions.0.templateValues.getProducts',
    ],
  });

  useEffect(() => {
    if (!discountType) {
      setValue('versions.0.templateValues.discountType', DiscountType.MoneyOff);
    }
  }, []);

  useEffect(() => {
    setValue('versions.0.templateValues.eolvEnhancedLogic', eolv);
  }, [eolv]);

  const [includeNonProduct, setIncludeNonProduct] = useState(watch(templateValueIncludeNP));
  const [includeNonFoodProductCodes] = watch([templateValueIncludeNFPC]);

  useEffect(() => {
    setValue(templateValueIncludeNP, includeNonProduct);
    if (includeNonProduct) {
      setValue(templateValueIncludeNFPC, undefined);
    }
  }, [includeNonProduct]);

  return (
    <OfferInfoWrapper disabled={disabled}>
      <LineWrapper gap={8}>
        <StyledLabel>Buy</StyledLabel>
        <ProductPicker disabled={disabled}>
          <span>1</span>
          <ProductLine>{calcProductsHint(buyProductsA)}</ProductLine>
          {getProductsAction(
            disabled,
            buyProductsA,
            getValues,
            selectProducts,
            'buyProductsA',
            (data: any) => ({
              buyProductsA: data,
            }),
            onProductSelection,
            null,
            null,
            null,
            offerID,
            onProductPreviewClick,
          )}
          <input
            type="hidden"
            {...register('versions.0.templateValues.buyProductsA', {
              value: buyProductsA,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
        <ProductPicker disabled={disabled}>
          <span style={{ minWidth: '40px' }}>and 1</span>
          <ProductLine>{calcProductsHint(buyProductsB)}</ProductLine>
          {getProductsAction(
            disabled,
            buyProductsB,
            getValues,
            selectProducts,
            'buyProductsB',
            (data: any) => ({
              buyProductsB: data,
            }),
            onProductSelection,
            null,
            null,
            null,
            offerID,
            onProductPreviewClick,
          )}
          <input
            type="hidden"
            {...register('versions.0.templateValues.buyProductsB', {
              value: buyProductsB,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
      </LineWrapper>
      <LineWrapper gap={8}>
        <StyledLabel disabled={disabled}>Get</StyledLabel>
        <ProductPicker disabled={disabled}>
          <span>1</span>
          <ProductLine>{calcProductsHint(getProducts)}</ProductLine>
          {getProductsAction(
            disabled,
            getProducts,
            getValues,
            selectProducts,
            'getProducts',
            (data: any) => ({
              getProducts: data,
            }),
            onProductSelection,
            null,
            null,
            null,
            offerID,
            onProductPreviewClick,
          )}
          <input
            type="hidden"
            {...register('versions.0.templateValues.getProducts', {
              value: getProducts,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
      </LineWrapper>
      {discountType === DiscountType.Percent && (
        <LineWrapper gap={8}>
          <NonProductPLUsSection
            disabled={disabled}
            includeNonProduct={includeNonProduct}
            setIncludeNonProduct={setIncludeNonProduct}
            includeNonFoodProductCodes={includeNonFoodProductCodes}
            register={register}
            getValues={getValues}
            onProductSelection={onProductSelection}
            offerID={offerID}
            onProductPreviewClick={onProductPreviewClick}
          />
        </LineWrapper>
      )}
      <LineWrapper gap={8}>
        <StyledLabel disabled={disabled}>For</StyledLabel>
        <StyledForSelectbox
          name="versions.0.templateValues.discountType"
          control={control}
          disabled={disabled || (offerSource === OfferSource.DOE && mode === 'edit')}
          validation={{
            required: true,
          }}
          items={
            Object.entries(DiscountTypeRecord()).map(([key, value]) => {
              return {
                id: key,
                name: value,
              };
            }) as any[]
          }
          selectWidth={164}
          defaultValue={DiscountType.MoneyOff}
          initialSelectedItems={discountType ? [discountType] : [DiscountType.MoneyOff]}
          version="offer-form"
        />
        {!isFree && (
          <EnterValueWrapper
            width={136}
            disabled={disabled}
            errors={getIsControlWithError('versions.0.templateValues.discountValue', errors)}
          >
            {shouldDisplayCurrency && <Currency />}
            <OfferTemplateTextField
              disabled={disabled || (offerSource === OfferSource.DOE && mode === 'edit')}
              register={register}
              validation={{
                required: true,
                min: isPercent ? 1 : 0.01,
                max: isPercent ? 99 : null,
                pattern: isPercent ? /^([1-9][0-9]?|99)$/ : /^\d+(?:\.\d{1,2})?$/,
                validate: (v: string) =>
                  (!Number.isNaN(Number(v)) && Number(v) >= 0.01) || (isPercent && Number(v) < 100 && Number(v) >= 1),
              }}
              errors={errors}
              name="versions.0.templateValues.discountValue"
              placeholder="Enter value"
              selectWidth={120}
            />
          </EnterValueWrapper>
        )}
        {isEOLVEnabled && (
          <StyledCheckbox
            checked={eolv}
            disabled={disabled}
            onClick={() => setEolv(!eolv)}
            label="Equal or Lesser Value"
          />
        )}
      </LineWrapper>
    </OfferInfoWrapper>
  );
};
