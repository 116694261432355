import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';
import zIndex from 'styles/zIndex';
import ButtonBase, { NewButtonBase } from '../buttonBase/ButtonBase';
import typography from 'styles/typography';


export const StyledButtonBase = styled(ButtonBase)`
  
  color: ${({ theme }) => theme.colors.global.inputPrimary};
  background-color: transparent;
  border: none;
  padding: 0;
  &:hover {
    color: ${({ theme }) => theme.colors.button.hover};
  }

  &:focus{
    border:2px solid #045fcc;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`;

export const  ImageCancelStyledButtonBase = styled(StyledButtonBase)`
  
  color: ${({ theme }) => '#006BAE'};
  background-color: transparent;
  border: none;
  padding: 0;
  width: 44px;
  height: 32px;
  font-family: 'Speedee';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  /* identical to box height, or 163% */
  letter-spacing: -0.03em;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  
  &:hover {
    color: ${({ theme }) => theme.colors.button.hover};
  }

  &:focus{
    border:2px solid #045fcc;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`;

export const StyledButtonBaseHeader = styled(ButtonBase)`
  color: ${({ theme }) => theme.colors.global.inputPrimary};
  background-color: transparent;
  border: none;
  padding: 0;
  &:hover {
    color: ${({ theme }) => theme.colors.button.hover};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`;

export const StyledButtonBaseAdd = styled(ButtonBase)`
  color: ${newGlobalTheme.colors.global.textSubheading};
  height:36px;
  background-color: transparent;
  border: none;
  padding: 0;
  &:hover {
    color: ${({ theme }) => theme.colors.button.hover};
  }

    &:focus {
    border:2px solid #045fcc;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`;

export const StyledButtonBaseSelect = styled(ButtonBase)`
  color: ${newGlobalTheme.colors.global.textSubheading};
  background-color: transparent;
  border: none;
  padding: 0;
  font-family: Speedee;
font-size: 14px;
font-weight: 700;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: left;


  &:hover {
    color: ${({ theme }) => theme.colors.button.hover};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.disabled};
  }

   &:focus-visible {
    outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
    outline-offset: 4px;
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }
`;


export const StyledButtonBaseCancel = styled(ButtonBase)`

font-family: Speedee;
font-size: 14px;
font-weight: 700;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: left;

  color: ${({ theme }) => newGlobalTheme.colors.global.textSubheading};
  background-color: transparent;
  border: none;
  width:56px;
  margin-right:149px;
  padding: 0;
  display:flex;
  justify-content:flex-start;

  &:hover {
    color: ${({ theme }) => theme.colors.button.hover};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.text.disabled};
  }
`;
export const StyledNewButtonBase = styled(NewButtonBase)`
  color: ${newGlobalTheme.colors.text.secondary};
  background-color: transparent;
  border: none;
  padding: 0;
  font-family: ${newTypography.primaryFont};
  font-size: 16px;
  font-weight: 400;

  &:focus-visible {
    outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
    outline-offset: 4px;
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }
`;

export const StyledRestoreButtonBase = styled(NewButtonBase)`
  color: ${newGlobalTheme.colors.text.secondary};
  background-color: transparent;
  border: none;
  padding: 0;
  font-family: ${newTypography.primaryFont};
  font-size: 14px;
  font-weight: 700;
  margin: ${newTypography.unit * 1}px ${newTypography.unit * 10}px;

  &:focus-visible {
    outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
    outline-offset: 4px;
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }
`;
