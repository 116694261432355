import React from 'react';
import { NewButtonBaseProps } from 'components/shared/button/buttonBase/ButtonBase.consts';
import { OfferNewStyledButton, StyledPlus } from './AddNewButton.style';

const NewOfferAddNewButton = ({ onClick, className }: NewButtonBaseProps) => {
  return (
    <>
      <OfferNewStyledButton onClick={onClick} className={className} data-title="Add New">
          <StyledPlus width={16} height={16} name="newPlus" data-testid="AddIcon"/>
      </OfferNewStyledButton>
    </>
  );
};
export default NewOfferAddNewButton;
