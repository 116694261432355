import React from 'react';
import { QuickFilterProps } from 'pages/campaigns/campaignManagement/components/quickFilter/QuickFilter.consts';
import {
  QuickFilterContainer,
  NumberComponent,
} from 'pages/campaigns/campaignManagement/components/quickFilter/QuickFilter.style';
import { handleKeyPress } from 'utils/keyPress';

const QuickFilter = ({ number, name, isError = false, onClick, className }: QuickFilterProps) => {
  const automationName = name.toLowerCase().replace(/ /g, '-');
  return (
    <QuickFilterContainer className={className} onClick={onClick} tabIndex={0}
    onKeyDown={(event) => handleKeyPress(event, () => onClick())}>
      <NumberComponent isError={isError} data-automation-id={`quick-filters-${automationName}`}>
        {number}
      </NumberComponent>
      {name}
    </QuickFilterContainer>
  );
};

export default QuickFilter;
