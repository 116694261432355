
export const handleKeyDownModal = (event: any, querySelector: string) => {
    const modal = document.querySelector(`[data-automation-id="${querySelector}"]`);

    if (!modal) return; // If no modal element exists, do nothing
    // Extract focusable elements 
    const focusableElements = Array.from(modal.querySelectorAll('a, button, input, select, footer, textarea, [tabindex]:not([tabindex="-1"])')) as any[];
    
    // Exclude disabled elements or any within the tooltip 
    let filtered = focusableElements.filter(el => !el.disabled && !el.closest('[data-id="tooltip"]')); 
  
    const firstElement = filtered[0] as any; 
    const lastElement = filtered[filtered.length - 1] as any;

    // Check if the pressed key is Tab
    if (event.key === 'Tab') {
    
        // If Shift key is not pressed and the focus is on the last element, move focus to the first element
        if (!event.shiftKey && document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
        }
      
        // If Shift key is pressed and the focus is on the first element, move focus to the last element
        if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
        }
    }
  }