import styled from "@emotion/styled";
import SetItemsSelectionForm from "pages/shared/setItemsSelectionForm/SetItemsSelectionForm";
import OfferModal from "../modal/OfferModal";
import { NewButtonText } from "../button";

export const StyledProductSet = styled(SetItemsSelectionForm)`
    gap: 8px; 
    height: 283px; 
    font-family: Speedee;
    input {
     height: 31px; 
    }
    margin-bottom: 20px; 
`;

export const StyledModal = styled(OfferModal)`
    padding: 36px; 
    font-family: Speedee;
    .ModalHeader {
        padding: 0px;
        margin-bottom: 24px; 
    }
`;

export const  ButtonContainer = styled.div`
    display: flex; 
    flex-direction: row; 
    justify-content: flex-end; 
`

export const CancelButton = styled(NewButtonText)`
    font-weight: 700; 
`;
