import styled from '@emotion/styled';
import { ButtonContained, ButtonText, NewButtonText } from 'components/shared/button';
import { newTypography } from 'styles/typography';
import { Icon, IconProps } from 'components/shared/icon';
import { ImageFormContainerProps } from './ImagesForm.consts';
import Modal from 'components/shared/modal/Modal';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import zIndex from 'styles/zIndex';

export const StyledImagesFormModal = styled(Modal)`
  max-height: 90%;
  padding: 36px; 
  max-width: 602px;
  min-width: 602px; 
  
  h3 {
    font-family: ${newTypography.primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  /* or 82% */
  letter-spacing: -0.03em;

  /* Black */
  color: #292929;


  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  }

  .ModalHeader {
      margin-bottom: 36px; 
  }
  
`;

export const SaveButton = styled(ButtonContained)`
  &:focus-visible {
  outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
  outline-offset: 2px;
  z-index: ${zIndex.tabNavigation};
  transition: none;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: 55px;
  height: 32px;
  font-size: 14px;
  font-weight: 700;
  color: black;
  &:hover {
    background-color: ${({ theme }) => '#FFBC0D'};
  }
  /* McDonald's Gold */
  background: #FFBC0D;
  /* Accent Gold */
  border: 1px solid #C08B00;
  border-radius: 5px;
  flex: none;
  order: 1;
  flex-grow: 0;

  &:disabled {
  background-color: ${newGlobalTheme.colors.global.disabledGold};
  border:none;
  color: ${newGlobalTheme.colors.global.borderColorboxes};
  }

   
`;

export const Forms = styled.div<{ isImagesBulk: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  gap: 12px;
  ::-webkit-scrollbar {
    width: 21px;
  }

  ::-webkit-scrollbar-thumb {
    border: 7px transparent solid;
    background-clip: padding-box;
    border-radius: 10px;
  }
`;

export const ImageFormContainer = styled.div<ImageFormContainerProps>`
  display: flex;
  border: ${({ isImagesBulk, theme }) => isImagesBulk && `1px solid ${theme.colors.global.border}`};
  border-radius: ${newTypography.borderRadius}px;
  padding: ${({ isImagesBulk }) => isImagesBulk && `${newTypography.unit * 3}px ${newTypography.unit * 2}px;`};
  position: relative;
  gap: 16px;
`;

export const FormContent = styled.div`
  width: 348px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  gap: 16px;
`;

export const NewFormContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 348px;
  min-height: 256px;
  padding-left: ${newTypography.unit * 4}px;
  overflow: hidden;
`;


export const RemoveButton = styled(ButtonText)`
  position: absolute;
  top: ${newTypography.unit * 2}px;
  right: ${newTypography.unit * 2}px;
`;

export const RemoveIcon = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.text.secondary};
  width: 14px;
`;

export const ImageCancelButton = styled(NewButtonText)`
  font-family: ${newTypography.primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.42px;
`