import { ImageSource } from 'pages/settings/images/Images.consts';
import { UserRole } from './users';

export interface Image {
  id: number;
  name: string;
  fileName: string;
  file?: string;
  description: string;
  isArchive: boolean;
}

export interface DoeImage {
  id: number;
  name: string;
  fileName: string;
  src?: string;
  fileExtension?: string;
  file?: File | string;
  description: string;
  imageSource: ImageSource;
  metadata?: { [key: string]: any };
  metadataHash?: string;
  defaultTemplates?: string[];
  selectedLanguages: string[];
  defaultTemplateToggle?: boolean;
  discountComponentCheckbox?: { checked: boolean };
  isArchive?: boolean;
}

export enum DOEImageActions {
  Archive = 'archive',
  ImageUsage = 'image usage',
}

export enum ImageActions {
  Archive = 'archive',
  Edit = 'edit',
  ImageUsage = 'image usage',
  Unarchive = 'unarchive',
}

export const DOEImageActionsList: { id: DOEImageActions; name: DOEImageActions; }[] = [
  { id: DOEImageActions.ImageUsage, name: DOEImageActions.ImageUsage }, 
  { id: DOEImageActions.Archive, name: DOEImageActions.Archive }, 

]  

export const DOEImageActionsListForNonAdmin: { id: DOEImageActions; name: DOEImageActions; }[] = [
  { id: DOEImageActions.ImageUsage, name: DOEImageActions.ImageUsage }, 

]  

export const ImageActionsListForViewer: { id: ImageActions; name: ImageActions; }[] = [
  { id: ImageActions.ImageUsage, name: ImageActions.ImageUsage }
]  

export const ImageActionsList: { id: ImageActions; name: ImageActions; }[] = [
  { id: ImageActions.ImageUsage, name: ImageActions.ImageUsage },
  { id: ImageActions.Archive, name: ImageActions.Archive },
  { id: ImageActions.Edit, name: ImageActions.Edit },
];

export const ArchiveImageActionsList: { id: ImageActions; name: ImageActions }[] = [
  { id: ImageActions.Unarchive, name: ImageActions.Unarchive },
];


