import React, { useEffect, useRef } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorProps } from './Error.consts';
import { StyledError } from './Error.style';

const ErrorComponent = ({ className, errors, name, ref,tabIndex }: ErrorProps) => {
  const errorRef = useRef(null);
  useEffect(() => {
    if (errorRef.current && (name === 'password' || name === 'passwordConfirm' || name === 'selectedItemSetsById')) {
      errorRef.current.focus();
    }
  }, [errors]);

  return typeof errors === 'string' ? (
    <StyledError className={className} data-automation-id="error" ref={errorRef} tabIndex={-1}>
      {errors}
    </StyledError>
  ) : errors !== undefined ? (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ message }) =>
        message ? (
          <StyledError role={'alert'} className={className} data-automation-id="error-message">
            <span tabIndex={-1} ref={errorRef}>
              {message}
            </span>
          </StyledError>
        ) : null
      }
    />
  ) : null;
};

export default ErrorComponent;
