import styled from '@emotion/styled';
import { StyledLabelProps } from './DiscountComponent.consts';
import { newTypography } from 'styles/typography';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import OfferTemplateTextField from 'components/shared/textField/OfferTemplateTextField';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import NewCheckbox from 'components/shared/checkbox/NewCheckbox';

export const DiscountComponentContainer = styled.div`
  position:relative;
  display: flex;
  flex-direction: column;
  gap: ${newTypography.unit*2}px;
`;

export const DiscountComponentRow = styled.div`
  display: flex;
  gap: ${newTypography.unit * 4}px;
  &:last-of-type {
    margin-bottom: 0px;
  }
`;
export const StyledSelectbox = styled(NewSelectbox)`
  display: flex;  
` as unknown as typeof NewSelectbox;

export const CurrencyContainer = styled.div<{errors?: any}>`
  display: flex;
  align-self: flex-end;
  border-bottom: ${({ errors }) => (errors ? `1px solid ${newGlobalTheme.colors.text.error};` : `1px solid #6F6F6F;`)}

`;

export const StyledTextField = styled(OfferTemplateTextField)`
padding-right:10px;

`;
export const MinimumPurchaseTextField = styled(OfferTemplateTextField)`
`;

export const MinimumPurchase = styled(NewCheckbox)<{disabled: boolean}>`
  label{
    font-family: ${newTypography.primaryFont};
    font-size: 12px;
    font-weight: 700;
    line-height: 22.83px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: ${({ disabled }) => (disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary)};
  }

`;

export const StyledLabel = styled.label<StyledLabelProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${({ disabled }) => (disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.text.primary)};
`;
