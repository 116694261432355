import { IconContainer, StyledIcon } from "./NewLockIcon.style";


const NewLockIcon = ({ dataFor }: { dataFor?: string }) => {
  return (
    <IconContainer data-for={dataFor} data-tip>
      <StyledIcon name="newLock" />
    </IconContainer>
  );
};

export default NewLockIcon;
