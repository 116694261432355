import React from 'react';
import { FilterIcon, Filters, StyledFilterBarSelect } from 'pages/shared/shared.style';
import { OrderDirection } from 'utils/types';
import { useSelector } from 'react-redux';
import { schedule as scheduleSlice, ScheduleFilterType } from 'app/slices/schedule';
import locationSetsGqls from 'pages/settings/locationSets/LocationSets.gqls';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';
import { useQuery } from '@apollo/client';
import { periodsGqls } from 'pages/settings/schedulePeriods/SchedulePeriods.gqls';
import { UrlFilters } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { nationalSelectItem } from 'utils/location';
import { FetchPolicies } from 'utils/types/common';

const ScheduleManagementFilterBar = ({ filter, filterMulti }: UrlFilters) => {
  const { filters } = useSelector(scheduleSlice);
  const { data: zonesData } = useQuery(locationSetsGqls.queries.getZones, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: { [LocationSetsFilters.CustomSets]: false },
        order: { name: OrderDirection.ASC },
      },
    },
  });
  const zoneItems = zonesData?.getLocationSets.items ? [nationalSelectItem, ...zonesData.getLocationSets.items] : [];
  const { data: periodsData } = useQuery(periodsGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: {},
        order: { startDate: OrderDirection.DESC },
      },
    },
  });

  return (
    <Filters>
      <FilterIcon name="filter" />
      <StyledFilterBarSelect
        key={`${JSON.stringify(periodsData?.getPeriods?.items)}`}
        placeholder="Select"
        name="period"
        label="Period"
        items={periodsData?.getPeriods?.items ? periodsData.getPeriods.items : []}
        onChange={(selected: any) => {
          filter(ScheduleFilterType.Period, selected ? [selected.id] : []);
        }}
        initialSelectedItems={periodsData?.getPeriods?.items ? filters[ScheduleFilterType.Period] : []}
        maxItems={1}
        selectWidth={160}
      />
      <StyledFilterBarSelect
        name="zone"
        key={`${JSON.stringify(zoneItems)}`}
        placeholder="Select"
        label="Zone"
        multiple
        items={zoneItems}
        onChange={(selected: any) => {
          let selectIds = [];
          let isNationalSelected = null;
          selectIds = Object.values(selected).map((i: any) => {
            if (i.name === 'National') {
              isNationalSelected = true;
            }
            return i.id;
          });
          filterMulti({
            ...filters,
            [ScheduleFilterType.National]: isNationalSelected,
            [ScheduleFilterType.Zone]: selected ? selectIds : [],
          });
        }}
        initialSelectedItems={filters[ScheduleFilterType.Zone] ?? (filters[ScheduleFilterType.National] ? ['999'] : [])}
        reset
        maxItems={1}
        selectWidth={320}
      />
    </Filters>
  );
};

export default ScheduleManagementFilterBar;
