import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { ApprovalStatus, DateTimeConfig, FormMode } from 'utils/types';
import ErrorComponent from 'components/shared/error/Error';
import { prepareCampaignForUpdate, prepareNewCampaignForSave } from 'utils/api/campaigns';
import { isInArray } from 'utils/array';
import { StyledFormSection } from 'pages/shared/shared.style';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { convertUtcDateToTimezoneDate, convertUtcDateToTimezoneDateUsingLibrary } from 'utils/date';
import LocalCampaignSection from 'pages/campaigns/campaignManagement/components/campaignForm/components/localCampaignSection/LocalCampaignSection';
import { MarketConfigurationKey } from 'pages/configurations/Configurations.consts';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { store } from 'app/store';
import { offersSelection } from 'app/genericSlices/offers';
import { modal as modalSlice, setData } from 'app/slices/modals';
import {
  CampaignFormProps,
  FormTitle,
  SectionTitle,
} from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm.consts';
import {
  FormContainer,
  StyledModal,
  StyledSectionTitleWithLoader,
} from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm.style';
import CampaignFormSubtitle from 'pages/campaigns/campaignManagement/components/campaignForm/components/campaignStatusLabel/CampaignFormSubtitle';
import OfferSection from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerSection/OfferSection';
import GeneralDetailsSection from 'pages/campaigns/campaignManagement/components/campaignForm/components/generalDetailsSection/GeneralDetailsSection';
import CampaignFormFooter from 'pages/campaigns/campaignManagement/components/campaignForm/components/campaignFormFooter/CampaignFormFooter';
import { CampaignCreationProps, CampaignProps, FormSection } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import LocationSection from 'pages/campaigns/campaignManagement/components/campaignForm/components/locationSection/LocationSection';
import ScheduleSection from 'pages/campaigns/campaignManagement/components/campaignForm/components/scheduleSection/ScheduleSection';
import {
  createCampaign,
  createCampaigns,
  editCampaign,
  getVoucherDetails,
} from 'pages/campaigns/campaignManagement/components/campaignForm/utils/CampaignActions';
import useFeatureFlag from 'hooks/use-feature-flag';
import { Feature } from 'utils/types/features';
import { hideTooltip } from 'utils/tooltip';
import ReactTooltip from 'react-tooltip';
import { CampaignType, LockableProcessMessage, VoucherGroup } from 'utils/types/campaigns';
import { OfferFilters, OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { useVoucherState } from 'hooks/use-voucher-state';
import { validateCampaignVoucher } from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm.utils';
import ProgressLoader from 'pages/shared/progressIndicator/ProgressLoader';
import { StyledSectionTitle } from 'pages/offers/components/offerFilterBar/OfferFilterBar.style';
import AudienceSection from './components/audienceSection/AudienceSection';

const CampaignForm = ({ mode, campaign, isLocalCampaign }: CampaignFormProps) => {
  const { config } = useSelector(marketConfig);
  const { data: modalData } = useSelector(modalSlice);
  const { dateFormat, timeFormat, startTimezone, endTimezone, calendarWeekStartsOn } = config;
  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: cloneDeep(
      omitBy(
        {
          ...campaign,
          schedule: campaign?.schedule
            ? {
                ...campaign.schedule,
              campaignStart: convertUtcDateToTimezoneDateUsingLibrary(campaign.schedule.campaignStart, startTimezone),
              campaignEnd: convertUtcDateToTimezoneDateUsingLibrary(campaign.schedule.campaignEnd, endTimezone),
              }
            : undefined,
          push_notification:
            campaign?.push_notification &&campaign?.push_notification?.length ?
            campaign?.push_notification.filter(pn => pn?.startDate !== '' &&
              pn?.push_notification_translation?.length > 0 ).map((pn)=>({
              id: pn?.id,
              startDate: convertUtcDateToTimezoneDate(pn?.startDate, startTimezone),
              push_notification_translation: (pn?.push_notification_translation || []).map(
                (translation: any) => ({
                  title: translation?.title,
                  message: translation?.message,
                  language: translation?.language,
                }),
              ),
            })): null,
        },
        isNil,
      ),
    ) as any,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const voucherState = useVoucherState();

  useEffect(() => {
    if(voucherState.isExternalVoucher) {
      formMethods.clearErrors('restaurantEligibility');
      formMethods.unregister('restaurantEligibility');
    }
  },[voucherState.isExternalVoucher]);

  const isDisabled =
    mode === FormMode.View ||
    (mode === FormMode.Edit &&
      isInArray([ApprovalStatus.Active, ApprovalStatus.Deployed, ApprovalStatus.AssociationStopped, ApprovalStatus.DeploymentPending], campaign?.status));
  const dateTimeConfig: DateTimeConfig = useMemo(
    () => ({ dateFormat, timeFormat, startTimezone, endTimezone, calendarWeekStartsOn }),
    [dateFormat, timeFormat, startTimezone, endTimezone, calendarWeekStartsOn],
  );
  formMethods.register('offerVersion', { required: true });

  // Tracking form isDirty value
  const { isDirty, dirtyFields } = formMethods.formState; 

  const isDirtyForm = isDirty; 

  const stateIsFormDirty = modalData.isFormDirty;

  // Run when isDirty value changes to update the state variable isFormDirty. 
  // If dirtyFields length < 1, there have been no real changes and we need to reset dirty to false 
  useEffect(() => {
    if (mode === FormMode.Edit || mode === FormMode.Duplicate) {
      if (Object.keys(dirtyFields).length < 1) {
        formMethods.reset(undefined, { keepValues: true, keepDirty: false });
      }
      else {
        if (isDirtyForm === true) {
          store.dispatch(
            setData({
              data: {
                isFormDirty: true,
              },
            }),
          );
        }
      }
    }
  }, [isDirtyForm])

  const FormSectionToComponent: Record<FormSection, JSX.Element> = useMemo(
    () => ({
      [FormSection.Offer]: (
        <OfferSection
          mode={mode}
          isDisabled={isDisabled}
          offerSource={campaign?.offerSource}
          isExternalVoucher={voucherState.isExternalVoucher}
        />
      ),
      [FormSection.GeneralDetails]: (
        <GeneralDetailsSection mode={mode} isDisabled={isDisabled} offerSource={campaign?.offerSource} />
      ),
      [FormSection.Schedule]: (
        <ScheduleSection
          mode={mode}
          status={campaign?.status}
          isDisabled={isDisabled}
          dateTimeConfig={dateTimeConfig}
          offerSource={campaign?.offerSource}
          voucherState={voucherState}
        />
      ),
      [FormSection.Locations]: voucherState.isExternalVoucher ? null : (
        <LocationSection mode={mode} campaign={campaign} isDisabled={isDisabled} />
      ),
    }),
    [mode, isDisabled, voucherState, dateTimeConfig, isDirtyForm],
  );

  const getModalTitle = () => {
    let title;
    const externalId = campaign?.externalId ? ` ID ${campaign.externalId}` : '';

    if (mode === FormMode.Edit && campaign?.offerSource === OfferSource.DOE) {
      title = 'Edit DOE Campaign';
    } else {
      title = FormTitle[mode];
    }

    return `${title}${externalId}`;
  };
  const isCampaignBulkOn = useFeatureFlag(Feature.CampaignBulk);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const handleEditCampaign = async (formData: any, isDraft: boolean, startTimezone1: string, endTimezone1: string) => {
    const offerSource = campaign?.offerSource;
    if (!isButtonDisabled) {
      setIsButtonDisabled(true);
      const campaignToSave = prepareCampaignForUpdate(
        formData,
        isDraft,
        startTimezone1,
        endTimezone1,
        voucherState.isExternalVoucher,
      );
      await editCampaign(campaignToSave, offerSource, formData.alert);
      hideTooltip('#deployed-active-tooltip');
      ReactTooltip.hide();
    }
  };

  const onSubmit = (isDraft = false) => {
    return async (formData: any) => {
      store.dispatch(offersSelection.actions.setFilter({ filter: [OfferFilters.Zone], value: null }));
      setIsSubmitting(true);
      if (!isDraft && formData?.type === CampaignType.LoyaltyExternalVoucher) {
        const voucherData: VoucherGroup | boolean = await getVoucherDetails(
          mode,
          +formData?.offerVersion?.templateValues?.nonFoodDiscountRewards,
          voucherState,
        );
        if (!validateCampaignVoucher(voucherData, formData.schedule, dateTimeConfig)) {
          setIsSubmitting(false);
        return;
        }
      }
      if (mode === FormMode.New && isCampaignBulkOn && formData.schedule.dates?.length) {
        const bulkCampaignToSave = prepareNewCampaignForSave(formData, isDraft, startTimezone, endTimezone, true);
        await createCampaigns(bulkCampaignToSave as CampaignCreationProps);
      } else if (mode === FormMode.New || mode === FormMode.Duplicate) {
        const campaignToSave = prepareNewCampaignForSave(
          formData,
          isDraft,
          startTimezone,
          endTimezone,
          false,
          voucherState.isExternalVoucher,
        );
        await createCampaign(campaignToSave as CampaignProps);
      } else if (mode === FormMode.Edit) {
        handleEditCampaign(formData, isDraft, startTimezone, endTimezone);
      }
      setIsSubmitting(false);
    };
  };

  const renderAudienceSection = () => {
    if (mode !== FormMode.New && campaign?.offerSource === OfferSource.DOE) {
      return (
        <StyledFormSection>
          <StyledSectionTitle>Audience</StyledSectionTitle>
          <AudienceSection mode={mode} campaign={campaign} isLocalCampaign={isLocalCampaign}/>
        </StyledFormSection>
      );
    }
    return null;
  };

  const renderedFormSections = useMemo(() => {
    return (
      <>
        {Object.values(FormSection).map((section) => {
          const component = FormSectionToComponent[section];
          if (component) {
            return (
              <StyledFormSection key={section}>
                <StyledSectionTitleWithLoader section={section}>
                  {SectionTitle[section]}
                  {section === FormSection.Schedule && voucherState.voucherApiLoading && (
                    <ProgressLoader size={20} toolTipRequired={false} type="loader" thickness={4} />
                  )}
                  {section === FormSection.Schedule && (
                    <ErrorComponent name="schedule.exchangeLimit" errors={voucherState.voucherError} />
                  )}
                </StyledSectionTitleWithLoader>
                {component}
              </StyledFormSection>
            );
          }
          return null;
        })}
        {renderAudienceSection()}
      </>
    );
  }, [FormSectionToComponent, voucherState]);

  return (
    <FormProvider {...formMethods}>
      <StyledModal
        title={getModalTitle()}
        mode={mode}
        subtitle={
          campaign?.status && (
            <CampaignFormSubtitle
              status={campaign?.status}
              alert={campaign?.alert}
              comment={campaign?.approvals[0]?.comment}
              isTriggerEvent={campaign?.isTriggerEvent}
            />
          )
        }
        isLocked={campaign?.isLocked}
        withLoader
        ignoreOperations={['PeriodsForZone', 'Tags', 'Offer', 'VoucherGroup', 'LocationSets', 'Locations']}
        {...(campaign?.inProgress && {
          inProgress: true,
          message:
            campaign?.inProgress === LockableProcessMessage.BulkSubmitForApproval
              ? 'Campaign submission for approval is in progress'
              : 'Campaign approval is in progress',
        })}
        onHover
        data-automation-id={'modalPart'}
        offerSource={campaign?.offerSource}
      >
        <FormContainer>
          {!voucherState.isExternalVoucher && (
            <MarketConfigurationGuard
              configurations={[{ configKey: MarketConfigurationKey.EnableManagementByZone, value: true }]}
            >
              <LocalCampaignSection mode={mode} isDisabled={isDisabled} />
            </MarketConfigurationGuard>
          )}
          {renderedFormSections}
        </FormContainer>
        <ErrorComponent name="campaign.offerVersion" errors={formMethods.formState.errors} />
        <ErrorComponent name="campaign.restaurantEligibility.restaurants" errors={formMethods.formState.errors} />
        <ErrorComponent name="campaign.restaurantEligibility.restaurantGroups" errors={formMethods.formState.errors} />
        <CampaignFormFooter
          mode={mode}
          campaignStatus={campaign?.status}
          alert={campaign?.alert}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          isLocked={campaign?.isLocked || false}
          isDraft={campaign?.isDraft}
          onSubmit={(isDraft: boolean) => formMethods.handleSubmit(onSubmit(isDraft))}
          fromCalendar={modalData.fromCalendar}
          dateTimeConfig={dateTimeConfig}
          offerSource={campaign?.offerSource}
          voucherState={voucherState}
          {...(campaign?.inProgress && { inProgress: true })}
          isDirty={stateIsFormDirty}
          isLocalCampaign={isLocalCampaign}
        />
      </StyledModal>
    </FormProvider>
  );
};

export default CampaignForm;
