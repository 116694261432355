import styled from "@emotion/styled";
import { newGlobalTheme } from "styles/themes/newGlobalTheme";

export const ImageContent = styled.div<{ contentWidth?: number}>`
  min-height: 0;
  display: flex;
  flex-direction: column;
  ${({ contentWidth }) => contentWidth && `width: ${contentWidth}px;`}
`

export const ImageTitle = styled.div`
  line-height: 21px;
  display: flex;
  justify-content: space-between;
  height: 27px;
  :focus-visible {
    outline: none !important;
  }
`

export const ImageContainer = styled.div`


`

export const ImageModalHeader = styled.div`
  font-family: Speedee;
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  font-weight: 700;
  line-height: 22.834px; /* 81.55% */
  letter-spacing: -0.84px;
  padding-top: 46.75px;
  padding-bottom: 12px;
  padding-left: 36px;
  padding-right: 36px;
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const ImageFormFooter = styled.footer`
  display: flex;
  margin: auto 0 0 0;
  justify-content: flex-end;
  padding: 28px 36px 0px 36px;
  justify-content: space-between; 
  max-width: 602px;
  padding-bottom: 10px;
`;