import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import NewTextField from '../textField/NewTextField';
import { newTypography } from 'styles/typography';

export const StyledOfferFormTextField = styled(NewTextField)<{hasError?: boolean, version?:string}>`
  gap: 8px; 
  display: flex; 
  flex-direction: column; 
  margin-bottom: 18px; 
  font-family: ${newTypography.primaryFont};
  width: 100%; 
  ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave};` : `color: ${newGlobalTheme.colors.text.primary};`}
    
    .input-wrapper {
      border-bottom: ${({hasError}) => hasError ? `1px solid ${newGlobalTheme.colors.global.errorTextColor}` : `1px solid ${newGlobalTheme.colors.text.disabledSave}`};
      :hover {
        border-bottom: ${({hasError}) => hasError ? `1px solid ${newGlobalTheme.colors.global.errorTextColor}` : `1px solid ${newGlobalTheme.colors.text.disabledSave}`};
      }
      :focus {
        border-bottom: ${({hasError}) => hasError ? `1px solid ${newGlobalTheme.colors.global.errorTextColor}` : `1px solid ${newGlobalTheme.colors.text.disabledSave}`};
      }
      :focus-within {
        border-bottom: ${({hasError}) => hasError ? `1px solid ${newGlobalTheme.colors.global.errorTextColor}` : `1px solid ${newGlobalTheme.colors.text.disabledSave}`};
      }
    }

    input {
      ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave};` : `color: ${newGlobalTheme.colors.text.primary};`}
        height: 36px;
        font-size: 12px;
        padding: 8px 0px;
        background: #FFF;
        ::placeholder {
          color: ${newGlobalTheme.colors.toggle.disabled};
        }
    }

    label {
      ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave} !important;` : `color: ${newGlobalTheme.colors.text.primary} !important; `}
      height: 15px; 
      }

      div {
            ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave} !important;` : `color: ${newGlobalTheme.colors.text.primary} !important; `}
}

${({version, disabled }) => version === 'campaign-form' && `
      .input-wrapper {
        border-bottom: ${disabled && version === 'campaign-form' && '1px solid #ADADAD;'};
      }
`}
    
  
`;
