import { client } from 'app/apollo';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import offersGqls from 'pages/offers/offerManagement/Offers.gqls';
import { imagesGqls } from 'pages/settings/images/Images.gqls';
import { tagsGqls } from 'pages/settings/tags/Tags.gqls';
import { capitalize } from 'utils/text';
import { TagsValidEntities } from 'utils/types/tags';
import { TagProps } from 'pages/settings/tags/Tags.consts';
import { clientQuery } from './clientQuery';
import { ImagePropsToSave, ImageSource } from 'pages/settings/images/Images.consts';
import { doeImagesGqls } from 'pages/settings/doeImages/DoeImages.gqls';
import { HeaderComponentType } from 'components/impact/impactModal/ImpactModal.consts';
import { handleOfferImpactChange } from 'utils/offer';
import { handleCampaignImpactChange } from 'utils/campaign';
import { Modals, closeModal, openModal } from 'app/slices/modals';
import { store } from 'app/store';

export const createNewImages = async (images: any) => {
  return client.mutate({
    mutation: imagesGqls.mutations.add,
    variables: { data: images },
    refetchQueries: ['Images'],
  });
};

export const createNewDoeImage = async (image: any) => {
  return client.mutate({
    mutation: doeImagesGqls.mutations.addDoe,
    variables: { data: image },
    refetchQueries: ['DoeImages'],
  });
};

export const bulkUpdateImages = async (images: any) => {
  return client.mutate({
    mutation: imagesGqls.mutations.bulkUpdate,
    variables: { data: images },
    refetchQueries: ['Images'],
  });
};

export const updateImage = async (image: any, imageSource: ImageSource) => {
  if (imageSource === ImageSource.VCE) {
    return client.mutate({
      mutation: imagesGqls.mutations.update,
      variables: { data: [image] },
      refetchQueries: ['Images'],
    });
  } else {
    return client.mutate({
      mutation: doeImagesGqls.mutations.updateDoe,
      variables: { data: [image] },
      refetchQueries: ['DoeImages'],
    });
  }
};

export const saveNotification = async (editedImage: ImagePropsToSave, onImageUpdate: (image: any) => Promise<void>) => {
  const [offerImpacts, campaignImpacts] = await Promise.all([
    getOffersImpactsByImageId(editedImage.id),
    getCampaignsImpactsByImageId(editedImage.id),
  ]);

  if (offerImpacts.length || campaignImpacts.length) {
    const offerImpactProps = handleOfferImpactChange(offerImpacts);
    const campaignsImpactProps = handleCampaignImpactChange(campaignImpacts);
    store.dispatch(
      openModal({
        modal: Modals.ImageImpactModal,
        props: {
          title: 'Save Notification',
          headerComponentType: HeaderComponentType.ImageSaveNotificationType,
          offerImpacts: offerImpactProps,
          campaignImpacts: campaignsImpactProps,
          entityType: 'Image',
          entityId: editedImage.id,
          onCancel: () => store.dispatch(closeModal()),
          onSubmit: () => onImageUpdate(editedImage),
          submitButtonString: 'Yes, Save',
          isFromImageScreen: true,
        },
      }),
    );
  } else {
    await onImageUpdate(editedImage);
  }
};

export const createImageTag = async (name: string) => {
  const tag = {
    name,
    validEntities: [capitalize(TagsValidEntities.Image)],
    isMasterTag: false,
  } as any as TagProps;

  return client.mutate({
    mutation: tagsGqls.mutations.add,
    refetchQueries: ['Tags'],
    variables: {
      data: { ...tag },
    },
  });
};

export const getOffersImpactsByImageId = async (imageId: number) => {
  const filters = {
    bool: {
      must: [
        {
          match: {
            'versions.translations.image.id': imageId,
          },
        },
      ],
    },
  };
  const offersImpacts = await clientQuery(offersGqls.queries.getForImpact, {
    data: { filters, limit: 9999 },
  });

  return offersImpacts.data.getOffers.items;
};

export const getCampaignsImpactsByImageId = async (imageId: number) => {
  const filters = {
    bool: {
      must: [
        {
          match: {
            'offerVersion.translations.image.id': imageId,
          },
        },
      ],
    },
  };
  const campaignsImpacts = await clientQuery(campaignsGqls.queries.getForImpact, {
    data: { filters, limit: 9999 },
  });

  return campaignsImpacts.data.getCampaigns.items;
};

export const getDoeImageById = async (id: number) => {
  return clientQuery(doeImagesGqls.queries.getById, {
    id,
  });
};
