import React, { useEffect, useState } from 'react';
import xorWith from 'lodash/xorWith';
import { CalendarMonth } from './components/calendarMonth/CalendarMonth';
import { DatesSelectorProps } from './DatesSelector.consts';
import { StyledDatesSelector } from './DatesSelector.style';

export const DatesSelector = ({
  startDate = new Date(),
  selectedDates: initialSelectedDates = [],
  months: initialMonthsDisplayed = 14,
  onChange,
  disablePast = true,
  datesToShow,
  footerRef
}: DatesSelectorProps) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>(initialSelectedDates);
  const [displayedMonths, setDisplayedMonths] = useState<number>(initialMonthsDisplayed);
  const nextSetOfMonthsDisplayed = 12;

  const months = new Array(displayedMonths).fill(null).map((_, i) => {
    const newDate = new Date(startDate);
    newDate.setDate(1);
    return new Date(newDate.setMonth(newDate.getMonth() + i));
  });

  useEffect(() => {
    onChange(selectedDates);

  }, [selectedDates]);

  // Logic for handling tab navigation within calendar
  useEffect(() => {
     // Find all spans with role="button" and no 'disabled' attribute
     const roleButtonSpans = Array.from(
      document.querySelectorAll('span[role="button"]:not([disabled])')
    ) as HTMLButtonElement[];

    // Filter elements with a background color that's not "transparent"
    const filteredRoleButtonSpans = roleButtonSpans.filter((span) => {
      const style = window.getComputedStyle(span);
      const backgroundColor = style.backgroundColor;
      return backgroundColor !== "rgba(0, 0, 0, 0)" && backgroundColor !== "transparent";
    });

    // Find the button just selected
    const lastNonTransparentButton = filteredRoleButtonSpans[filteredRoleButtonSpans.length - 1];

    // Find the back up first transparent enabled button
    const firstEnabledButton = roleButtonSpans[0]

    // Focus on the button just selected
    if (lastNonTransparentButton) {
      lastNonTransparentButton?.focus();
    }
    else {
      firstEnabledButton?.focus();
    }

  }, [selectedDates])

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 10) {
      setDisplayedMonths((prev) => prev + nextSetOfMonthsDisplayed);
    }
  };

  return (
    <StyledDatesSelector data-automation-id="calendar-container" onScroll={handleScroll}>
      {months.map((d) => (
        <CalendarMonth
          key={d.toString()}
          year={d.getFullYear()}
          month={d.getMonth()}
          onClick={(date: Date) => {
            const newDates = xorWith([...selectedDates], [date], (a, b) => a.getTime() === b.getTime());
            setSelectedDates(newDates);
          }}
          disablePast={disablePast}
          selectedDates={selectedDates}
          datesToShow = {datesToShow}
          footerRef={footerRef}
        />
      ))}
    </StyledDatesSelector>
  );
};
