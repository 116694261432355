import styled from '@emotion/styled';
import typography from 'styles/typography';
import { ButtonContained, ImageCancelButtonText } from 'components/shared/button';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import TextField from 'components/shared/textField/TextField';
import TermConditionTranslations from './termConditionTranslations/TermConditionTranslations';
import NewModal from 'components/shared/modal/NewModal';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import zIndex from 'styles/zIndex';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import { OfferFormTextField } from 'components/shared/textField/OfferFormTextField';

export const TermConditionName = styled(OfferFormTextField)`
  width: 100%;
  display: flex;
  margin-bottom: ${typography.unit * 7}px;
  height: 59px;
  div[role='alert'] {
      color: #D90007 !important; 
      margin-top: 39px; 
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 354px;
  &:focus-visible {
  outline: none;
  }
`;

export const StyledSelectbox = styled(NewSelectbox)`
  display: flex;
  width: 100%;
  height: 59px;
  gap: 8px;
  div[role='alert'] {
      color: #D90007 !important; 
      margin-top: 11px; 
  }
`;

export const StyledButtonContained = styled(ButtonContained)`
  margin-left: ${typography.unit * 5}px;
  &:focus-visible {
  outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
  outline-offset: 2px;
  z-index: ${zIndex.tabNavigation};
  transition: none;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: 55px;
  height: 32px;
  font-size: 14px;
  font-weight: 700;
  color: black;
  &:hover {
    background-color: ${({ theme }) => '#FFBC0D'};
  }
  /* McDonald's Gold */
  background: #FFBC0D;
  /* Accent Gold */
  border: 1px solid #C08B00;
  border-radius: 5px;
  flex: none;
  order: 1;
  flex-grow: 0;

  &:disabled {
  background-color: ${newGlobalTheme.colors.global.disabledGold};
  border:none;
  color: ${newGlobalTheme.colors.global.borderColorboxes};
`;

export const StyledButtonText = styled(ImageCancelButtonText)`
  display: flex;
  width: 36px;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
  margin-top: ${typography.unit * 7}px;
  align-items: center;
`;

export const TermConditionFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledTermConditionTranslations = styled(TermConditionTranslations)`
  margin-top: ${typography.unit * 7}px;
  height: 204px;
`;

export const StyledModal = styled(NewModal)<{ title?: string }>`
  min-width: unset;
  width: 602px;
  max-height: 558px;
  height: auto;
  padding: ${typography.unit * 9}px;
  .ModalHeader {
    & h3 {
      ${({ title }) => (title.startsWith('Add Terms & Conditions') || title.startsWith('Edit Terms & Conditions')) && 'width: 530px;'};
      height: 28px;
    }
  }
`;
