import { SegmentIdContainer, SegmentIdContent, StyledIcon, StyledTextField } from './AudienceSection.style';
import { AudienceSectionProps } from './AudienceSection.consts';
import { StyledLabel } from 'components/shared/textField/TextField.style';
import { Modals, openModal } from 'app/slices/modals';
import { store } from 'app/store';
import { ButtonText } from 'components/shared/button';

const AudienceSection = ({ mode, campaign, isLocalCampaign }: AudienceSectionProps) => {
  const { oasAudienceId, segmentId } = campaign;

  const onViewAllSegmentIds = () => {
    store.dispatch(
      openModal({
        modal: Modals.SegmentIdModal,
        props: {
          segmentId,
          onCancel: () =>
            store.dispatch(
              openModal({
                modal: Modals.CampaignModal,
                props: { mode, campaign, isLocalCampaign },
              }),
            ),
        },
      }),
    );
  };

  return (
    <div>
      {oasAudienceId && oasAudienceId.length > 0 && (
        <StyledTextField label="OAS Audience ID:" name={'oasAudienceID'} value={oasAudienceId[0]} disabled={true} />
      )}
      {segmentId && segmentId.length > 0 && (
        <SegmentIdContainer>
          <StyledLabel disabled={false}>Cluster:</StyledLabel>
          <SegmentIdContent>
            {`${segmentId[0]} ${segmentId.length > 1 ? `+${segmentId.length - 1}` : ''}`}
            <ButtonText onClick={onViewAllSegmentIds}>
              <StyledIcon name="view" />
            </ButtonText>
          </SegmentIdContent>
        </SegmentIdContainer>
      )}
    </div>
  );
};

export default AudienceSection;
