import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
import { ButtonType } from 'components/shared/inputFiles/InputFiles.consts';
import InputFiles from 'components/shared/inputFiles/InputFiles';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { store } from 'app/store';
import {
  getCampaignsImpactsByImageId,
  getOffersImpactsByImageId,
  saveNotification,
  updateImage,
} from 'utils/api/images';
import { FormMode, isNumber } from 'utils/types';
import { Save } from 'components/shared/button';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { hasValue } from 'utils/text';
import { handleCampaignImpactChange } from 'utils/campaign';
import { hideTooltip } from 'utils/tooltip';
import { HeaderComponentType } from 'components/impact/impactModal/ImpactModal.consts';
import { handleOfferImpactChange } from 'utils/offer';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { ImageProps, ImagePropsToSave } from '../../Images.consts';
import {
  CheckboxWithTooltip,
  FileDetails,
  FileDetailsContainer,
  NewImageDescription,
  NewImageFormContent,
  NewTagsSelectbox,
  NewPropValue,
  NewSaveButton,
  PropName,
  StyledText,
  ViewEditImageName,
  NewStyledText,
} from './ImageForm.style';
import { ImageFormProps } from './ImageForm.consts';
import { archiveImageClicked, onTagCreation, readImages, unarchiveImageClicked } from '../../images.utils';

import {
  ImagesFormDataContainer,
  ImageUploadContainer,
  NewImageContainer,
  NewStyledImage,
} from '../shared.style';
import { NewFormContent } from '../imagesForm/ImagesForm.style';
import Checkbox from 'components/shared/checkbox/Checkbox';
import CopyButton from 'pages/shared/copyButton/CopyButton';
import { ValidationMessages } from 'utils/types/common';
import { isString } from 'lodash';
import { CancelButton } from 'pages/offers/offerManagement/components/offerForm/OfferForm.style';
import { ImageActions } from 'utils/types/images';
import ImageModal from 'components/shared/modal/ImageModal';
import { ImageFormFooter } from 'components/shared/modal/ImageModal.style';
import OfferToolTip from 'components/shared/tooltip/OfferToolTip';
import NewCheckbox from 'components/shared/checkbox/NewCheckbox';

const ImageForm = ({ mode, image, onClose, className, editedImageSrc, viewOnly, fromOfferScreen= false }: ImageFormProps) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: image as any,
  });
  const [isBonusCampaign, setIsBonusCampaign] = useState(false);
  const [isFileChanged, setIsFileChanged] = useState(false);
  const { config } = useSelector(marketConfig);
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Image]);
  const [changedFile, setChangedFile] = useState<any>(editedImageSrc && image?.file);
  const [changedFileExtension, setChangedFileExtension] = useState<string>('');
  const [imgSrc, setImgSrc] = useState<string>(editedImageSrc || image?.file);
  const id = useMemo(() => Math.random().toString(8), [image.updatedAt]);
  const onImageUpdate = async (editedImage: ImagePropsToSave) => {
    try {
      editedImage.tags = editedImage.tags.map((tag: any) =>
        isNumber(tag) || isString(tag) ? Number(tag) : Number(tag.id),
      );
      const res = await updateImage(editedImage, image.imageSource);
      const isProcessed = res.data?.updateImages?.isProcessed;
      if(isProcessed === false){
        showToast(MessageType.Info, `Your image update request has been received, we are working on it, and you'll be notified once it's ready.`);
      }else {
        showToast(MessageType.Success, 'Image updated successfully');
      }
      store.dispatch(closeModal());
    } catch (e) {
      showToast(
        MessageType.Error,
        `Failed to update image${e.message.includes('already exists') ? ` - ${editedImage.name} already exists` : ''}`,
      );
    }
  };

  const onActionClicked = async (actionName: ImageActions) => {
    switch (actionName) {
      case ImageActions.Archive:
        await archiveImageClicked({image: image, isFromImageScreen: true});
        break;
      case ImageActions.Edit:
        await onEditClicked(image);
        break;
      case ImageActions.ImageUsage:
        await onImageImpactClicked(image);
        break;
      case ImageActions.Unarchive:
        await unarchiveImageClicked(image);
        break;
      default:
    }
  };

  const onImageImpactClicked = async (img: ImageProps) => {
    const [offerImpacts, campaignImpacts] = await Promise.all([
      getOffersImpactsByImageId(img.id),
      getCampaignsImpactsByImageId(img.id),
    ]);

    if (offerImpacts.length || campaignImpacts.length) {
      const offerImpactProps = handleOfferImpactChange(offerImpacts);
      const campaignsImpactProps = handleCampaignImpactChange(campaignImpacts);
      store.dispatch(
        openModal({
          modal: Modals.ImageImpactModal,
          props: {
            title: 'View Usage',
            headerComponentType: HeaderComponentType.ImageUsageType,
            offerImpacts: offerImpactProps,
            campaignImpacts: campaignsImpactProps,
            entityType: 'Image',
            entityId: img.id,
            isFromImageScreen: true,
            onCancel: () => store.dispatch(closeModal()),
          },
        }),
      );
    } else {
      showToast(MessageType.Info, `The image is not being used in campaigns or offers`);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>) => {
    if (event.key === 'Enter') {
      store.dispatch(closeModal());
    }
  };

  const onEditClicked = (img: ImageProps) =>
    store.dispatch(
      openModal({
        modal: Modals.ImageModal,
        props: {
          mode: FormMode.Edit,
          image,
          onClose: () => {
            reset(img);
            setIsBonusCampaign(image?.isBonusCampaign);
            store.dispatch(openModal({ modal: Modals.ImageModal, props: { mode: FormMode.View, image: img } }));
          },
        },
      }),
    );

  const onSave = async (data: any) => {
    try {
      const { name, description, tags: iTags, isBonusCampaign: isBonusCampaign1 }: ImageProps = data;
      const tagsToSave = iTags as any as number[];
      const editedImage: any = {
        id: Number(image.id),
        name,
        description,
        tags: tagsToSave,
        isBonusCampaign: isBonusCampaign1,
      };

      if (changedFile) {
        editedImage.file = changedFile;
        editedImage.fileExtension = changedFileExtension;
        await saveNotification(editedImage, onImageUpdate);
      } else {
        await onImageUpdate(editedImage);
      }
    } catch (e) {
      showToast(MessageType.Error, `Failed to update image`);
    }
  };

  const onFileChange = async (files: FileList) => {
    const loadedImages = await readImages(files, config.bulkImagesUploadLimit);
    if (loadedImages && loadedImages[0]) {
      const { src, file, fileExtension } = loadedImages[0];
      setImgSrc(src);
      setChangedFile(file);
      setChangedFileExtension(fileExtension);
      setIsFileChanged(true);
    }
  };

  const shouldShowField = (fieldName: string) => {
    if (mode === FormMode.View && !viewOnly) {
      const fieldVal = getValues(fieldName);
      if (fieldVal && Array.isArray(fieldVal)) {
        return fieldVal.length > 0;
      }
      return fieldVal;
    }
    return true;
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isDirty]);

  useEffect(() => {
    loadTags();
  }, []);

  return (
    <ImageModal
      isArchive={image.isArchive}
      onHover
      onActionClicked={onActionClicked}
      showMoreActions
      isLocked={image?.isLocked}
      data-automation-id="image-form"
      modalMode={mode}
      imageSource={image.imageSource}
      title={`${mode === FormMode.View ? 'View' : 'Edit'} Image ID ${image.id}`}
      fromOfferScreen={fromOfferScreen}
    >
      <NewImageFormContent>
        <ImagesFormDataContainer>
          <ImageUploadContainer>
            <NewImageContainer tabIndex={0}>
              <NewStyledImage
                src={isFileChanged ? imgSrc : `${imgSrc}?${id}`}
                alt={image.name}
                data-automation-id="image"
                crossOrigin="anonymous"
              />
            </NewImageContainer>
            {mode === FormMode.Edit && (
              <InputFiles
                buttonType={ButtonType.Text}
                handleFiles={(files) => onFileChange(files)}
                multiple={false}
                filesType="image/*"
              >
                Upload Image
                <InfoTooltip
                  id="uploadTooltip"
                  content="Image size should be smaller than 100KB. Supported file types: png, jpg, jpeg, gif."
                />
              </InputFiles>
            )}
          </ImageUploadContainer>
          <NewFormContent>
            <ViewEditImageName
              register={register}
              errors={errors}
              version='image-form'
              name="name"
              value={image?.name}
              label="Image Name"
              placeholder="Enter"
              disabled={mode === FormMode.View}
              validation={{
                required: ValidationMessages.RequiredField,
                maxLength: { value: 200, message: 'Up to 200 characters' },
                validate: (value: string) => (!hasValue(value) ? ValidationMessages.RequiredField : true),
              }}
              labelIsHorizontal
            />
            {shouldShowField('tags') && (
              <NewTagsSelectbox
                key={`${Boolean(tags.length)}`}
                control={control}
                errors={errors}
                version='image-form'
                name="tags"
                enableTagHover={mode === FormMode.View ? true : false}
                label="Image Tags"
                placeholder="Select"
                disabled={mode === FormMode.View}
                multiple
                items={tags}
                maxItems={2}
                limit={10}
                withSearch
                withAmount
                labelMarginBottom='8px'
                onCreateOption={(tagName: string) => onTagCreation(tagName)}
                labelIsHorizontal
                reset
                validation={{
                  validate: (value: any) => (value.length > 10 ? 'Select a maximum of 10 tags' : true),
                }}
                selectHeight={47}
              />
            )}
            {shouldShowField('description') && (
              <NewImageDescription
                register={register}
                errors={errors}
                name="description"
                label="Description"
                scrollOnDisabled={true}
                disabled={mode === FormMode.View}
                placeholder="Enter Description"
                validation={{ maxLength: { value: 200, message: 'Up to 200 characters' } }}
                rows={1}
                labelIsHorizontal
              />
            )}
            {!image?.isBonusCampaign ? (
              <CheckboxWithTooltip>
                <NewCheckbox
                  disabled={mode === FormMode.View}
                  checked={isBonusCampaign}
                  id="isBonusCampaign"
                  label="Bonus Campaign"
                  onClick={() => {
                    if (mode !== FormMode.View) {
                      setIsBonusCampaign(!isBonusCampaign);
                      setValue('isBonusCampaign', !isBonusCampaign, { shouldDirty: true });
                    }
                  }}
                />
                <InfoTooltip
                  id="bonusCampaignTooltip"
                  className="test"
                  content="Bonus campaign images are instantly uploaded and made available, while regular offer images are only available after the offer is approved."
                />
              </CheckboxWithTooltip>
            ) : (
              <NewStyledText>Available for Bonus Campaign</NewStyledText>
            )}
          </NewFormContent>
        </ImagesFormDataContainer>
        <FileDetailsContainer>
          <FileDetails>
            <PropName>File Name:</PropName>
            <NewPropValue>{image.fileName}</NewPropValue>
            <CopyButton textToCopy={image.fileName} />
          </FileDetails>
        </FileDetailsContainer>
      </NewImageFormContent>
      <ImageFormFooter>
        {mode === FormMode.View ? (
          <>
            <CancelButton
              noStopPropagation
              data-for="cancel-tooltip"
              {...(isDirty
                ? { 'data-tip': true, onClick: () => null }
                : { onClick: () => (!onClose ? store.dispatch(closeModal()) : onClose()) })}
              onKeyDown={handleKeyDown}
            >
              Close
            </CancelButton>
          </>
        ) : (
          <>
            <CancelButton
              noStopPropagation
              {...(isDirty || editedImageSrc
                ? { 'data-tip': true, onClick: () => null }
                : { onClick: () => store.dispatch(closeModal()) })}
              data-for="cancel-tooltip"
              onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    store.dispatch(closeModal())
                  }
              }}
            >
              Cancel
            </CancelButton>
            <OfferToolTip
              id="cancel-tooltip"
              content="Are you sure you want to cancel?"
              onDisapproveClick={() => {
                hideTooltip('#cancel-tooltip');
              }}
              onApproveClick={() => store.dispatch(closeModal())}
            />
            <NewSaveButton noStopPropagation onClick={handleSubmit(onSave)} disabled={!isValid || (!isDirty && !changedFile)}>
              Save
            </NewSaveButton>
          </>
        )}
      </ImageFormFooter>
    </ImageModal>
  );
};

export default ImageForm;
