import React, { ChangeEvent } from 'react';
import { NewButtonText, NewButtonOutlined, NewButtonContained } from '../button';
import { ButtonType, InputFileProps } from './InputFiles.consts';
import { NewButtonBaseProps } from '../button/buttonBase/ButtonBase.consts';
import { showToast } from '../notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from '../notifications/notifications';

const InputFiles = ({
  handleFiles,
  filesType,
  buttonType = ButtonType.Contained,
  multiple = true,
  children,
  className,
}: InputFileProps) => {
  const accept = filesType || '*';
  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
      return showToast(
        MessageType.Error,
        'Sorry, your browser does not support images upload, please use Chrome with latest version',
      );
    }
    hiddenFileInput.current.click();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleFiles(e.target.files);
    hiddenFileInput.current.value = null;
  };

  const buttonTypeRecord: Record<ButtonType, (props: NewButtonBaseProps) => JSX.Element> = {
    [ButtonType.Contained]: NewButtonContained,
    [ButtonType.Outlined]: NewButtonOutlined,
    [ButtonType.Text]: NewButtonText,
  };

  return (
    <>
      {React.createElement(
        buttonTypeRecord[buttonType],
        {
          onClick: handleClick,
          className,
          onKeyDown: (e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
              handleClick();
            }
          },
        },
        children || 'Upload',
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={accept}
        multiple={multiple}
        data-title="Add New"
      />
    </>
  );
};

export default InputFiles;
