import React, { useEffect, useState } from 'react';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { ButtonContained, ButtonOutlined, ButtonText, ImageCancelButtonText, NewButtonContained } from 'components/shared/button';
import { store } from 'app/store';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { FormMode } from 'utils/types';
import { useFormState } from 'react-hook-form';
import { FormFooter, StyledButtonContained } from 'pages/settings/tags/components/tagFormFooter/TagFormFooter.style';
import { TagActions, TagFormFooterProps } from 'pages/settings/tags/components/tagFormFooter/TagFormFooter.consts';
import { createNewTag } from 'utils/api/tags';
import { handleDeleteTag, showImpactModalAction } from 'pages/settings/tags/components/tagForm/utils/TagActions';
import { hideTooltip } from 'utils/tooltip';
import ReactTooltip from 'react-tooltip';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import OfferToolTip from 'components/shared/tooltip/OfferToolTip';

const TagFormFooter = ({ tag, mode, isSubmitting, isLocked, onSubmit, className, tagImpacts, isCheckboxDirty }: TagFormFooterProps) => {
  const { isDirty, isValid } = useFormState();
  const [isFormValid, setIsFormValid] = useState(isValid && !isSubmitting);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tagImpacts, isDirty, mode !== FormMode.View, isCheckboxDirty]);

  useEffect(() => {
    setIsFormValid(isValid && !isSubmitting);
  }, [isValid, isSubmitting]);

  const getButtonsByModeAndStatus = (): JSX.Element => {
    switch (mode) {
      case FormMode.Edit:
      case FormMode.New:
        return (
          <>
            <StyledButtonContained onClick={onSubmit()} disabled={!isFormValid || (!isDirty && !isCheckboxDirty) }>
              Save
            </StyledButtonContained>
          </>
        );
    default:
        break;
    }
  };

  return (
    <FormFooter className={className}>
      <ImageCancelButtonText
        {...(mode !== FormMode.View && isDirty
          ? { 'data-tip': true, onClick: () => null }
          : { onClick: () => store.dispatch(closeModal()) })}
        data-for="cancel-tooltip"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); 
            store.dispatch(closeModal())
          }
        }}
      >
        {mode === FormMode.View ? 'Close' : 'Cancel'}
      </ImageCancelButtonText>
      {!isLocked && getButtonsByModeAndStatus()}
      <OfferToolTip
        id="cancel-tooltip"
        content="Are you sure you want to cancel?"
        onDisapproveClick={() => {
          hideTooltip('#cancel-tooltip');
        }}
        eventOff={null}
        onApproveClick={() => store.dispatch(closeModal())}
      />
    </FormFooter>
  );
};

export default TagFormFooter;
