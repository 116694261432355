import { TagActions } from "pages/settings/tags/components/tagFormFooter/TagFormFooter.consts";

export interface Tag {
  id: number;
  name: string;
  amount?: number;
  amountByEntities?: any;
  validEntities?: any;
  offers?: any;
  images?: any;
  campaigns?: any;
}

export enum TagsValidEntities {
  Campaign = 'campaign',
  Offer = 'offer',
  Image = 'image',
}

export enum TagDefault {
  Default = 'default',
  NonDefault = 'nonDefault',
}

export const TagActionsListForViewer: { id: TagActions; name: TagActions; }[] = [
  { id: TagActions.ViewUsage, name: TagActions.ViewUsage }
]  

export const TagActionsList: { id: TagActions; name: TagActions; }[] = [
  { id: TagActions.ViewUsage, name: TagActions.ViewUsage },
  { id: TagActions.Delete, name: TagActions.Delete },
  { id: TagActions.Edit, name: TagActions.Edit },
];

