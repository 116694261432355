import styled from '@emotion/styled';
import typography from 'styles/typography';
import { ButtonContained, ButtonText, NewButtonContained, NewButtonText } from 'components/shared/button';
import { appTheme, defaultTheme } from 'styles/themes/defaultTheme';
import { StyledWithThemeProps } from 'utils/types';
import { Icon, IconProps, StyledClickableIconProps } from 'components/shared/icon';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import Tooltip from 'components/shared/tooltip/NewTooltip';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { Product } from 'utils/types/products';
import NewTabStrip from 'components/shared/tabStrip/NewTabStrip';
import { StyledFormSection } from 'pages/shared/shared.style';
import { MoreActionsDropDown } from 'components/shared/button/buttonDropdown/MoreActionsDropDown';
import { OfferFormTextArea } from 'components/shared/textArea/OfferFormTextArea';
import { OfferFormTextField } from 'components/shared/textField/OfferFormTextField';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';

export const ImageContainer = styled.div<StyledWithThemeProps>`

  img {
    width: 164px;
    height: 249px;
    object-fit: scale-down;
    padding: 8px; 
  }
  button {
      position: absolute;
      top: 5px;
      right: 5px;
      opacity: 0; /* Initially hidden */
      transition: opacity ${typography.transition}s ease;
    }

  &:hover  {
      button {
        opacity: 2;
      }
    }
`;

export const StyledTabStrip = styled(NewTabStrip)`
  margin: 0;
  height: 31px;
  font-size: 14px;
  font-family: Speedee;
  font-size: 12px;
  gap: 8px; 
  border-bottom: 1px solid ${newGlobalTheme.colors.status.archived};
  div {
    padding: 8px 0px;
    font-size: 12px;
  }
`;

export const OfferFormContentContainer = styled.div<{ theme?: appTheme; selected: boolean }>`
  display: ${({ selected }) => (selected ? 'flex' : 'none')};
  flex-direction: column; 
  gap: 16px; 
  width: 100%; 
  
`;

export const OfferFormImageContainer = styled.aside`
    height: 251px;
`;

export const OfferFormImage = styled.div<StyledWithThemeProps & {imageSelected?: string}>`
  position: relative;
  width: 166px;
  height: 251px;
  border-radius: 5px;
  background: ${({ theme }) => `${theme.colors.global.background}`};
  display: flex;
  justify-content: center;
  word-break: break-all;
  ${({imageSelected}) => imageSelected ? `border: 1px solid ${newGlobalTheme.colors.global.borderColor};` : `  border: 1px dashed ${newGlobalTheme.colors.toggle.disabled};`}
`;

export const OfferFormContent = styled.div`
  flex: 1 1 auto;
`;



export const StyledTextField = styled(OfferFormTextField)<{disabled?: boolean}>`
`;

export const GroupIdTextField = styled(OfferFormTextField)`
  width: 257px; 
  margin-bottom: 0px;
  input {
    padding: 0px; 
  }

`


export const TermsTextArea = styled(OfferFormTextArea)`
  textarea {
    border-radius: 5px; 
    height: 70px; 
  }
`

export const StyledTermsAndConditionsTextArea = styled.div`
  position: relative;

`;

export const StyledInfoTooltip = styled(InfoTooltip)`
  position: absolute;
  right: ${typography.unit}px;
  top: ${typography.unit}px;
  font-size: 12px; 
`;

export const StyledButtonContained = styled(ButtonContained)`
  margin-top: ${typography.unit * 8}px;
  align-self: self-start;
`;

export const OfferFormAdditionalDetails = styled.div`
  display: flex;
  flex-direction: column; 
  gap: 21px; 
 
`;


export const OfferFormFooter = styled.footer`
  display: flex;
  margin: auto 0 0 0;
  justify-content: flex-end;
  padding: 36px 36px 0px 36px; 
  justify-content: space-between; 
  max-width: 602px;
`;

export const StyledSelectbox = styled(NewSelectbox)`
  width: 100%;
  gap: 8px;
`;

export const StyledButtonText = styled(ButtonText)`
  margin: ${typography.unit * 2}px 0 0 0;
`;

export const OfferFormContainer = styled.div<StyledWithThemeProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px; 
  width: 594px;
  overflow-y: scroll; 
  padding-left: 36px; 
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 19px;
    background: ${newGlobalTheme.colors.toggle.disabled};
    padding-left: 20px; 
  }
`;



export const TagsSelectbox = styled(NewSelectbox)`
` as unknown as typeof NewSelectbox;

export const TermsConditionsSelectbox = styled(NewSelectbox)<{version?: string}>`
  label {
      ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave};` : `color: ${newGlobalTheme.colors.text.primary};`}
  }

`;

export const TemplateSelectbox = styled(NewSelectbox)`
  label {
      ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave};` : `color: ${newGlobalTheme.colors.text.primary};`}
  }

` as unknown as typeof NewSelectbox;

export const StyledLabelContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const StyledInfo = styled(Icon)<StyledClickableIconProps>`
  fill: ${defaultTheme.colors.global.inputPrimary};
  width: 15px;
  margin-left: ${typography.unit * 2}px;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledButtonDropdown = styled(MoreActionsDropDown)<StyledWithThemeProps>`
  cursor: pointer; 
  font-family: Speedee;
  font-weight: 400; 
`;

export const StyledUpdatedTime = styled.span`
  font-size: 12px;
  margin-left: ${typography.unit * 2}px;
`;

export const StyledProductTooltip = styled(Tooltip)`
  width: 468px;
  max-width: 500px;
  height: 308px;
  padding: 12px 16px !important;

`;

export const StyledError = styled.p<StyledWithThemeProps>`
  color: ${newGlobalTheme.colors.text.error};
  font-family: Speedee;
  font-size: 10px;
  font-weight: 300;
  margin-left: 2px; 
  height: 28px; 
  display: flex; 
  align-items:center; 


`;

export const StyledIcon = styled(Icon) <IconProps>`
  width: 18px;
  fill: #fff;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
  transition: all ${typography.transition}s ease;

  :hover {
    fill: ${({ theme }) => theme.colors.global.inputPrimary};
  }
`;

export const StyledViewImage = styled(StyledIcon)`
  width: 24px;
`;

export const StyledImageButtonText = styled(NewButtonText)`

`;

export const OfferTemplateSection = styled.div`
  display: flex; 
  flex-direction: column; 
  gap: 16px; 
`

export const SelectionSection = styled.div`
  display: flex; 
  flex-direction: column; 
  gap: 8px; 
  width: 100%; 
`

export const Footer = styled.div`
  display: flex; 
  flex-direction: row; 

`
export const BaseTextButton = styled(NewButtonText)`
  padding: 8px 12px 8px 0px;  
  font-family: Speedee;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.42px;

  &:disabled {
        color: ${newGlobalTheme.colors.text.disabled};
    }
`;

export const CancelButton = styled(BaseTextButton)`
  margin-right: auto;
`;

export const StyledGoldButton = styled(NewButtonContained)`
  color: ${newGlobalTheme.colors.text.primary};
  height: 32px;
  border-radius: 5px;
  border: 1px solid var(--Accent-Gold, #C08B00);
  background: var(--McDonalds-Gold, #FFBC0D);
  font-size: 14px;

  &:disabled {
        border: none;
        background: ${newGlobalTheme.colors.button.disabledSave};
        color: ${newGlobalTheme.colors.text.disabledSave};
    }

  :hover {
    border: 1px solid var(--Accent-Gold, #C08B00);
    background: var(--McDonalds-Gold, #FFBC0D);
  }


`

export const StyledWhiteButton = styled(NewButtonContained)`
  color: ${newGlobalTheme.colors.text.primary};
  height: 32px;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid ${newGlobalTheme.colors.text.disabledSave};
  background: #FFF;

  :hover {
    background-color: inherit;
  }

  &:disabled {
      color: ${newGlobalTheme.colors.text.disabledSave};
      border-radius: 5px;
        border: 1px solid ${newGlobalTheme.colors.status.archived};
        background: var(--White, #FFF);
  }

`

export const OfferInfoWrapper = styled.div<{disabled?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  label {
    ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave}` : `color: ${newGlobalTheme.colors.text.primary}`};
  }
  span {
      ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave}` : `color: ${newGlobalTheme.colors.text.primary}`};
  }

`

export const ProductLine = styled.label``;

export const LineWrapper = styled.div<{gap?: number}>`
  display: flex; 
  flex-direction: row; 
  gap: ${({gap}) => gap ? `${gap}px`: '16px'}; 
  align-items: center; 
  height: 36px; 

`

export const LineWrapperSpaceBetween = styled.div<{gap?: number, values?: Product[]}>`
  display: flex; 
  flex-direction: row; 
  align-items: center; 
  height: 36px; 
  gap: 16px;
`

export const AdditionalInfoRow = styled.div`
  justify-content: flex-start; 
  display: flex; 
  flex-direction: row; 
  gap: 16px;
  width: 100%;
  height: 
`

export const StyledButtonProductSelectionText  = styled(NewButtonText)<{disabled: boolean}>`
    font-weight: 700;
    line-height: 22.834px; 
    letter-spacing: -0.42px;
    font-size: 14px;
    padding: 8px 0px; 
    color: ${({disabled}) => disabled ? newGlobalTheme.colors.text.disabledSave : newGlobalTheme.colors.global.textSubheading};
`

export const SaveButtons = styled.div`
  display: flex; 
  flex-direction: row; 
  gap: 12px; 
`

export const ContentContainer = styled.div`
  display: flex; 
  flex-direction: column; 
  gap: 16px; 
  width: 100%; 
   div[role="alert"] {
      margin-top: 44px; 
      color: ${newGlobalTheme.colors.text.error} !important;
  }
`

export const ImageRowContainer = styled.div<{imageSelected?: string}>`
  display: flex; 
  flex-direction: row; 
  gap: 16px; 
  height: ${({imageSelected}) => imageSelected ? '277px': '251px'};
`

export const StyledSelectImageButton = styled(NewButtonContained)`
  color: ${newGlobalTheme.colors.text.primary};
  height: 32px;
  border-radius: 5px;
  border: 1px solid ${newGlobalTheme.colors.button.border.secondary};
  background: ${newGlobalTheme.colors.global.brandMain};
  font-size: 14px;
  :hover {
    background: ${newGlobalTheme.colors.global.brandMain};
  }
`

export const ImageTextWrapper = styled.div`
  display: flex; 
  flex-direction: column; 
  gap: 8px; 
  justify-content: center; 
`

export const OfferFormSection = styled(StyledFormSection)`
    width: 530px; 
    gap: 16px; 
    margin-bottom: 0px;
`

export const TermsConditionsWrapper = styled.div`
    display: flex; 
    flex-direction: column; 
    gap: 8px; 
     div[role="alert"] {
      color: ${newGlobalTheme.colors.global.error}; 
      font-size: 10px;
  }
`

export const CancelTooltip = styled(Tooltip)`
  button {
    font-size: 14px; 
  }
`

export const ContentTabWrapper = styled.div`
  display:flex;
  flex-direction:column;
  gap: 8px; 

`

export const SelectImageSecondaryButton = styled(NewButtonText)`
  font-family: Speedee;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.42px;
  justify-content: flex-start;
`

export const ImageColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px; 
`


