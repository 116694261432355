export interface ZonesManagementModalProps {
  zones: any[];
  selectOffers: any[];
  searchQuery?: string;
}

export const getOfferFilterOptions = (offersSel: any) => {
  const languageKeys = Object.keys(offersSel?.versions?.[0]?.translationsMap);
  const TermLanguageKeys = Object.keys(offersSel?.versions?.[0]?.term?.translationsMap);
  const optionsExactMatch = {
    keys: [
      { name: 'id', weight: 1 },
      { name: 'versions.translations.title', weight: 0.9 },
      { name: 'versions.translations.subtitle', weight: 0.8 },
      { name: 'versions.translations.description', weight: 0.7 },
      { name: 'versions.translations.posTitle', weight: 0.6 },
      ...languageKeys.flatMap((langKey) => [
        { name: `versions.translationsMap.${langKey}.image.description`, weight: 0.5 },
        { name: `versions.translationsMap.${langKey}.image.name`, weight: 0.5 },
        { name: `versions.translationsMap.${langKey}.image.id`, weight: 0.4 },
      ]),
      ...TermLanguageKeys.flatMap((langKey) => [
        { name: `versions.term.translationsMap.${langKey}.content`, weight: 0.4 },
      ]),
      { name: 'versions.term.name', weight: 0.4 },
      { name: 'versions.templateValues.getProducts.products.name', weight: 0.4 },
      { name: 'versions.templateValues.getProducts.products.plu', weight: 0.4 },
      { name: 'versions.templateValues.buyProducts.products.plu', weight: 0.4 },
      { name: 'versions.templateValues.buyProducts.products.name', weight: 0.4 },
      { name: 'versions.templateValues.products.products.plu', weight: 0.4 },
      { name: 'versions.templateValues.products.products.name', weight: 0.4 },
      { name: 'versions.templateValues.conditionProducts.products.plu', weight: 0.4 },
      { name: 'versions.templateValues.conditionProducts.products.name', weight: 0.4 },
      { name: 'versions.templateValues.buyProductsA.products.plu', weight: 0.4 },
      { name: 'versions.templateValues.buyProductsA.products.name', weight: 0.4 },
      { name: 'versions.templateValues.buyProductsB.products.plu', weight: 0.4 },
      { name: 'versions.templateValues.buyProductsB.products.name', weight: 0.4 },
      { name: 'versions.templateValues.getProductsA.products.plu', weight: 0.4 },
      { name: 'versions.templateValues.getProductsA.products.name', weight: 0.4 },
      { name: 'versions.templateValues.getProductsB.products.plu', weight: 0.4 },
      { name: 'versions.templateValues.getProductsB.products.name', weight: 0.4 },
      { name: 'versions.templateValues.offerTemplates.buyProducts.products.plu', weight: 0.4 },
      { name: 'versions.templateValues.offerTemplates.buyProducts.products.name', weight: 0.4 },
      { name: 'versions.templateValues.product', weight: 0.4 },
      { name: 'versions.templateValues.substitutes.products.plu', weight: 0.4 },
      { name: 'versions.templateValues.substitutes.products.name', weight: 0.4 },
      { name: 'versions.templateValues.includeNonFoodProductCodes.products.plu', weight: 0.4 },
      { name: 'versions.templateValues.includeNonFoodProductCodes.products.name', weight: 0.4 },
      { name: 'segmentId', weight: 0.4 },
    ],
    threshold: 0,
    distance: 0,
  };

  const optionsFuzzyMatch = {
    keys: [
      { name: 'id', weight: 0.5 },
      { name: 'versions.translations.title', weight: 0.4 },
      { name: 'versions.translations.subtitle', weight: 0.3 },
      { name: 'versions.translations.description', weight: 0.2 },
      { name: 'versions.translations.posTitle', weight: 0.1 },
      ...languageKeys.flatMap((langKey) => [
        { name: `versions.translationsMap.${langKey}.image.description`, weight: 0.1 },
        { name: `versions.translationsMap.${langKey}.image.name`, weight: 0.1 },
        { name: `versions.translationsMap.${langKey}.image.id`, weight: 0.1 },
      ]),
      ...TermLanguageKeys.flatMap((langKey) => [
        { name: `versions.term.translationsMap.${langKey}.content`, weight: 0.1 },
      ]),
      { name: 'versions.term.name', weight: 0.1 },

      { name: 'versions.templateValues.getProducts.products.name', weight: 0.1 },
      { name: 'versions.templateValues.getProducts.products.plu', weight: 0.1 },
      { name: 'versions.templateValues.buyProducts.products.plu', weight: 0.1 },
      { name: 'versions.templateValues.buyProducts.products.name', weight: 0.1 },
      { name: 'versions.templateValues.products.products.plu', weight: 0.1 },
      { name: 'versions.templateValues.products.products.name', weight: 0.1 },
      { name: 'versions.templateValues.conditionProducts.products.plu', weight: 0.1 },
      { name: 'versions.templateValues.conditionProducts.products.name', weight: 0.1 },
      { name: 'versions.templateValues.buyProductsA.products.plu', weight: 0.1 },
      { name: 'versions.templateValues.buyProductsA.products.name', weight: 0.1 },
      { name: 'versions.templateValues.buyProductsB.products.plu', weight: 0.1 },
      { name: 'versions.templateValues.buyProductsB.products.name', weight: 0.1 },
      { name: 'versions.templateValues.getProductsA.products.plu', weight: 0.1 },
      { name: 'versions.templateValues.getProductsA.products.name', weight: 0.1 },
      { name: 'versions.templateValues.getProductsB.products.plu', weight: 0.1 },
      { name: 'versions.templateValues.getProductsB.products.name', weight: 0.1 },
      { name: 'versions.templateValues.offerTemplates.buyProducts.products.plu', weight: 0.1 },
      { name: 'versions.templateValues.offerTemplates.buyProducts.products.name', weight: 0.1 },
      { name: 'versions.templateValues.product', weight: 0.1 },
      { name: 'versions.templateValues.substitutes.products.plu', weight: 0.1 },
      { name: 'versions.templateValues.substitutes.products.name', weight: 0.1 },
      { name: 'versions.templateValues.includeNonFoodProductCodes.products.plu', weight: 0.1 },
      { name: 'versions.templateValues.includeNonFoodProductCodes.products.name', weight: 0.1 },
      { name: 'segmentId', weight: 0.1 },
    ],
    threshold: 0.3,
    distance: 100,
  };
  return { optionsExactMatch, optionsFuzzyMatch };
};
