import { useEffect, useMemo, useRef, useState } from 'react';
import { SelectboxPaginateProps } from './SelectboxPaginate.consts';
import { StyledLabel } from 'components/shared/textField/TextField.style';
import useOnClickOutside from 'hooks/use-onclick-outside';
import { get } from 'lodash';
import { OptionProps, components } from 'react-select';
import {
  InputContainer,
  LimitIndication,
  SelectboxContainer,
  StyledCheckbox,
  StyledError,
  StyledOption,
} from 'components/shared/selectbox/Selectbox.style';
import zIndex from 'styles/zIndex';
import Chip from '../chip/Chip';
import { getIsControlWithError } from 'utils/form';
import { Controller } from 'react-hook-form';
import { StyledSelectPaginate } from './SelectboxPaginate.Style';
import { defaultTheme } from 'styles/themes/defaultTheme';
import { offerFilterNameMapping } from 'pages/offers/offerManagement/Offers.const';

export const SelectboxPaginate = ({
  initialSelectedItems,
  label,
  placeholder = 'Select',
  name,
  valueField = 'id',
  labelField = 'name',
  disabled,
  clearOnDisabled,
  multiple,
  labelIsHorizontal,
  withAmount,
  reset,
  defaultValue,
  control,
  validation,
  errors,
  limit,
  maxItems,
  onChange,
  selectWidth,
  className,
  theme = defaultTheme,
  getIsOptionDisabled,
  enableTagHover,
  loadOptions,
  isOpen,
  setIsOpen,
  filters,
  version
}: SelectboxPaginateProps) => {
  const ref = useRef();

  const searchInputRef = useRef<HTMLInputElement | any>(null);
  const [inputValue, setInputValue] = useState(null);
  useEffect(() => {
    let timeout: any = null;
    if (searchInputRef.current && isOpen) {
      timeout = setTimeout(() => {
        const input = searchInputRef.current.inputRef;
        if (input && document.activeElement !== input) {
          input.focus();
        }
      }, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [inputValue, isOpen]);

  useOnClickOutside(ref, () => setIsOpen(false));
  const memoizedValidation = useMemo(() => validation, [validation]);

  const [value, setValue] = useState([]);

  const Option = (props: OptionProps<any> & { value: any }) => {
    const { value: optionValue, label: optionLabel, isSelected } = props;
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {multiple && optionValue ? (
          <components.Option {...props}>
            <StyledOption title={optionLabel}>
              <StyledCheckbox onClick={null} checked={isSelected} />
              <label>{optionLabel}</label>
            </StyledOption>
          </components.Option>
        ) : (
          <components.Option {...props} />
        )}
      </div>
    );
  };

  const MultiValue = ({ index, getValue, ...props }: any) => {
    const overflowItems = maxItems
      ? getValue()
          .slice(maxItems)
          .map((x: any) => x[labelField])
      : null;
    return !maxItems || index < maxItems ? (
      <Chip
        title={props.data[labelField]}
        enableTagHover={enableTagHover}
        primaryChip
        {...{
          onClose: !disabled
            ? (e) => {
                e.stopPropagation();
                props.removeProps.onClick();
              }
            : null,
        }}
      >
        {props.data[labelField]}
      </Chip>
    ) : index === maxItems ? (
      <Chip extra onClose={null} title={overflowItems.join(', ')}>{`+${overflowItems.length}`}</Chip>
    ) : null;
  };

  const ValueContainer = ({ children, ...props }: any) => {
    const toBeRendered = children;
    return (
      <components.ValueContainer {...props}>
        {toBeRendered} {limit ? <LimitIndication>{`${value?.length || 0} of ${limit}`}</LimitIndication> : null}
      </components.ValueContainer>
    );
  };

  const getOptionLabel = (option: any) =>
    withAmount && option.amount ? `${option[labelField]} (${option.amount})` : option[labelField];
  const getStyle = (conditionMet: boolean, styleIfTrue: string, styleIfFalse: string) => {
    let style;
    if (conditionMet) {
      style = styleIfTrue;
    } else {
      style = styleIfFalse;
    }
    return style;
  };

  const hasError = getIsControlWithError(name, errors);

  const stylesMap: { [key: string]: string } = {
    cursor: getStyle(enableTagHover, 'default', 'pointer'),
    hoverColor: getStyle(enableTagHover, theme.colors.global.border, theme.colors.text.disabled),
    controlBorderBottom: getStyle(hasError, theme.colors.global.error, theme.colors.global.border),
    inputVisibility: getStyle(enableTagHover, 'hidden', 'visible'),
  };

  const getSingleValueStyle = (provided: any) => ({
    ...provided,
    color: name === 'isPriority' && disabled === true ? theme.colors.text.disabled : theme.colors.text.primary,
  });

  const customStyles = useMemo(
    () => ({
      valueContainer: (provided: any) => ({
        ...provided,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        flexWrap: 'nowrap',
        padding: '0',
        display: 'flex',
        fontSize: '13px',
        position: 'relative',
      }),
      singleValue: (provided: any) => getSingleValueStyle(provided),
      control: (provided: any) => ({
        ...provided,
        border: 0,
        borderRadius: 0,
        boxShadow: 'none',
        borderBottom: `1px solid ${stylesMap['controlBorderBottom']}`,
        backgroundColor: 'none',

        minHeight: '28px',
        ':hover': {
          ...provided[':hover'],
          borderColor: theme.colors.text.disabled,
          borderBottom: `1px solid ${stylesMap['controlHoverBorderBottom']}`,
        },
      }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: '0',
        transform: 'scale(0.8)',
        cursor: stylesMap['cursor'],
        color: theme.colors.global.border,
        ':hover': {
          ...provided[':hover'],
          color: stylesMap['hoverColor'],
        },
      }),
      clearIndicator: (provided: any) => ({
        ...provided,
        padding: '0',
        transform: 'scale(0.8)',
        cursor: 'pointer',
        color: theme.colors.global.border,
        display: enableTagHover ? 'none' : 'inline-flex',
        ':hover': {
          ...provided[':hover'],
          color: theme.colors.text.disabled,
        },
      }),
      option: (provided: any, { isDisabled }: any) => ({
        ...provided,
        backgroundColor: 'none',
        color: isDisabled ? theme.colors.text.disabled : theme.colors.text.primary,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        ':hover': {
          ...provided[':hover'],
          backgroundColor: !isDisabled ? theme.colors.global.selectedBackground : undefined,
        },
      }),
      placeholder: (provided: any) => ({
        ...provided,
        position: 'absolute',
        ...(name === 'localSchedule.period' && {
          color: disabled ? theme.colors.text.disabled : theme.colors.text.primary,
          fontWeight: disabled ? '300' : '400',
          position: 'absolute',
        }),
      }),
      menu: (provided: any) => ({
        ...provided,
        border: `1px solid ${theme.colors.global.border}`,
        boxShadow: '1px 1px 10px 0 rgb(0 0 0 / 22%)',
        zIndex: zIndex.dropdown,
        width: 'auto',
        minWidth: '100%',
        maxWidth: '500px',
      }),
      menuList: (provided: any) => ({
        ...provided,
        maxHeight: '190px',
      }),
      container: (base: any) => ({ ...base, width: selectWidth }),
      input: (provided: any) => ({
        ...provided,
        visibility: stylesMap['inputVisibility'],
      }),
    }),
    [hasError, enableTagHover, disabled],
  );

  useEffect(() => {
    const fieldValue = get(control?._defaultValues, name);
    if (fieldValue) {
      setValue(fieldValue);
    }
  }, [get(control?._defaultValues, name)]);

  useEffect(() => {
    if (disabled && clearOnDisabled) {
      setValue(null);
    }
  }, [disabled]);

  useEffect(() => {
    if (initialSelectedItems) {
      setValue(
        initialSelectedItems.map((ele) => {
          return { id: ele, name: ele };
        }),
      );
    }
  }, [initialSelectedItems]);

  // For restore default button functionality
  // When filters change in URL, clear the selectbox value if the filter is no longer selected/present in URL
  useEffect(() => {
    if (filters && version === 'offer-filter') {
        if (!Object.keys(filters).includes(offerFilterNameMapping[name])) {
          setValue(null);
        }
    }
}, [filters])

  return (
    <SelectboxContainer
      labelIsHorizontal={labelIsHorizontal}
      className={className}
      data-automation-id={`selectbox-${name}`}
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          setIsOpen(!isOpen);
        }
      }}
      tabIndex={0}
    >
      {label && (
        <StyledLabel
          theme={theme}
          disabled={disabled || enableTagHover}
          labelIsHorizontal={labelIsHorizontal}
        >{`${label}${validation?.required ? '*' : ''}`}</StyledLabel>
      )}
      <InputContainer>
        {control ? (
          <Controller
            control={control}
            name={name}
            rules={memoizedValidation}
            defaultValue={defaultValue}
            render={({ field }) => (
              <StyledSelectPaginate
                getOptionLabel={getOptionLabel}
                getOptionValue={(option: any) => option[valueField]}
                name={name}
                placeholder={placeholder}
                isDisabled={disabled}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isMulti={multiple}
                isClearable={reset}
                onChange={(selectedItems: any) => {
                  onChange(selectedItems);
                  setValue(selectedItems);
                }}
                defaultValue={defaultValue}
                value={value}
                isOptionDisabled={(option: any) =>
                  (limit && value?.length >= limit && !value.includes(option)) || getIsOptionDisabled?.(option)
                }
                components={{ Option, MultiValue, ValueContainer, IndicatorSeparator: (): any => null }}
                styles={customStyles}
                width={selectWidth}
                menuIsOpen={isOpen}
                loadOptions={loadOptions}
                inputValue={inputValue}
                onInputChange={setInputValue}
                selectRef={searchInputRef}
              />
            )}
          />
        ) : (
          <StyledSelectPaginate
            getOptionLabel={getOptionLabel}
            getOptionValue={(option: any) => option[valueField]}
            name={name}
            placeholder={placeholder}
            isDisabled={disabled}
            closeMenuOnSelect={false}
            onChange={(selectedItems: any) => {
              onChange(selectedItems);
              setValue(selectedItems);
            }}
            hideSelectedOptions={false}
            isMulti={multiple}
            isClearable={reset}
            defaultValue={defaultValue}
            value={value}
            isOptionDisabled={(option: any) =>
              (limit && value?.length >= limit && !value.includes(option)) || getIsOptionDisabled?.(option)
            }
            components={{ Option, MultiValue, ValueContainer, IndicatorSeparator: (): any => null }}
            styles={customStyles}
            width={selectWidth}
            menuIsOpen={isOpen}
            loadOptions={loadOptions}
            inputValue={inputValue}
            onInputChange={setInputValue}
            selectRef={searchInputRef}
          />
        )}
        <StyledError name={name} errors={errors} />
      </InputContainer>
    </SelectboxContainer>
  );
};
