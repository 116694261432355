import styled from '@emotion/styled';
import typography, { newTypography } from 'styles/typography';
import TextArea from 'components/shared/textArea/TextArea';
import { LanguageNavigationItemProps, StyledLanguageContentProps } from './TermConditionTranslations.const';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { OfferFormTextArea } from 'components/shared/textArea/OfferFormTextArea';

export const LanguageNavigationContainer = styled.div`
  display: flex;
  margin-bottom: ${typography.unit * 2}px;
  column-gap: 8px;
  row-gap: 16px;
`;

export const LanguageContentContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LanguageContent = styled(OfferFormTextArea)<StyledLanguageContentProps>`
  width: 100%;
  display: ${({ show }) => !show && 'none'};
  textarea{
    border: 1px solid #ADADAD;
    height: 142px;
  }
`;

export const LanguageNavigationItem = styled.div<LanguageNavigationItemProps>`
  display: flex;
  padding: ${typography.unit}px 0;
  border-bottom: ${({ isActive }) => isActive && `2px solid ${newGlobalTheme.colors.global.brandMain}`};
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  color: ${({ isActive }) => (isActive ? newGlobalTheme.colors.text.primary : newGlobalTheme.colors.text.disabledSave)};
  cursor: pointer;
  font-size: ${newTypography.smallFontSize}px;
  text-transform: capitalize;
  gap: 8px;
  line-height: 15.17px;
`;

export const StyledLabel = styled.label<LanguageNavigationItemProps>`
  font-family: Speedee;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: ${typography.unit * 2}px;
  text-transform: capitalize;
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
`;
