import React, { useEffect, useRef } from 'react';
import {
  ButtonsContainer,
  CloseButton,
  Container,
  GmaFormFooter,
  GmaFormHeader,
  ModalSubTitle,
  ModalTitle,
  StyledGoldButton,
  StyledIcon,
  TextContainer,
  StyledWhiteButton,
} from './GmaViewModal.style';
import ReactDOM from 'react-dom';
import { BaseOffer } from 'utils/types/offers';
import { useOfferFromRedux } from 'pages/offers/offerManagement/components/offerForm/components/hooks/useOfferFromRedux';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import OfferToolTip from 'components/shared/tooltip/OfferToolTip';
import { hideTooltip } from 'utils/tooltip';
import ReactTooltip from 'react-tooltip';
import { getOfferStatus } from 'utils/offer';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { ApprovalStatus, FormMode } from 'utils/types';
import { EntityType } from 'pages/shared/entityApproveButton/EntityApproveButton.consts';
import { EntityApproveButton } from 'pages/shared/entityApproveButton/EntityApproveButton';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { store } from 'app/store';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import GMAPreview from './components/GMAPreview';
import Backdrop from 'components/shared/backdrop/Backdrop';
import { Icon } from 'components/shared/icon';
import { scalingFactorGMA } from './GmaViewForm.const';
import { Loading } from '../Campaign.style';
import { Loader } from 'components/shared/loader';
import { useOperationCounter } from 'app/apollo/operationCounter';
import { handleKeyDownModal } from 'utils/modal';

const GmaViewModal = ({
  offer,
  campaign,
  onClose,
  onSubmit,
  handleRejectClick,
  onGoBack,
  isDisabled = false,
  withLoader = true,
  ignoreOperations = [],
}: {
  offer?: any;
  campaign?: {
    status: ApprovalStatus;
    mode: FormMode;
    offerSource: OfferSource;
    entity: any;
    hasRevoke?: boolean;
    hasStopAssociation?: boolean;
    stoppedAssociationVoucher?: boolean;
    revokeTooltip?: any;
    stoppedAssociationTooltip?: any;
    stopAssociationTooltip?: any;
    rejectTooltip?: any;
    saveDeployedTooltip?: any;
  };
  onClose?: (item: any) => void;
  onSubmit?: () => void;
  handleRejectClick?: (offer: BaseOffer, rejectionComment: string) => void;
  isDisabled?: boolean;
  onGoBack: () => void;
  withLoader?: boolean;
  ignoreOperations?: string[];
}) => {
  const { reqs: pendingRequests } = useOperationCounter();
  let status: ApprovalStatus;
  let isNewOrDuplicate: boolean;
  let isPendingApproval: boolean;
  let isDOEOffer: boolean = false;
  let isEditMode: boolean;
  let persistentMode: any;
  let offerSource: string;
  let entityType: EntityType;
  let entity: any;
  if (offer) {
    const {
      offer: persistentOffer,
      mode: persistentMode,
      offerSource: persistentOfferSource,
    }: { offer: BaseOffer; mode: string; selectedLanguage: string; offerSource: string } = useOfferFromRedux(
      cloneDeep(offer),
    );
    entityType = EntityType.Offer;
    entity = persistentOffer.versions[0];
    status = getOfferStatus(persistentOffer);
    offerSource = persistentOfferSource;
    isNewOrDuplicate = [FormMode.New, FormMode.Duplicate].includes(persistentMode as FormMode);
    isPendingApproval = status === ApprovalStatus.PendingApproval;
    isDOEOffer = offerSource === OfferSource.DOE;
    isEditMode = persistentMode === FormMode.Edit;
  } else if (campaign) {
    entityType = EntityType.Campaign;
    entity = campaign.entity;
    status = campaign.status;
    offerSource = campaign.offerSource;
    isNewOrDuplicate = [FormMode.New, FormMode.Duplicate].includes(campaign.mode);
    isPendingApproval = status === ApprovalStatus.PendingApproval;
    isEditMode = campaign.mode === FormMode.Edit;
  }

  const handleClose = () => store.dispatch(closeModal());

  const headerRef = useRef(null);

  useEffect(() => {
    // Focus on the header when the modal opens
    if (headerRef.current) {
      headerRef.current.focus();
    }
    // add event listener to prevent focus from leaving the modal 
    const keyDownListener : any = (event: KeyboardEvent) => handleKeyDownModal(event, 'modal');
    document.addEventListener('keydown', keyDownListener);
    return () => {
      document.removeEventListener('keydown', keyDownListener)
    }
  }, [offer, campaign]);


  return ReactDOM.createPortal(
    <>
      <Backdrop {...(onClose && { onClick: onClose })} />
      <Container data-automation-id="modal">
        {withLoader && (
          <Loading isLoading={difference(pendingRequests, ignoreOperations).length > 0}>
            <Loader />
          </Loading>
        )}
        <GmaFormHeader>
          <TextContainer>
            <ModalTitle>GMA preview</ModalTitle>
            <ModalSubTitle>
              This is how the offer will be presented to a McDonald's customer on their GMA mobile app
            </ModalSubTitle>
          </TextContainer>
          <StyledIcon 
            ref={headerRef} 
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                e.preventDefault(); 
                handleClose()
              }}} 
            onClick={handleClose} 
            tabIndex={0}>
            <Icon name="close" width={18.38 * scalingFactorGMA} height={18.38 * scalingFactorGMA} />
          </StyledIcon>
        </GmaFormHeader>
        <GMAPreview offer={offer} campaign={campaign} />
        <GmaFormFooter>
          <ButtonsContainer>
            <CloseButton noStopPropagation data-for="cancel-tooltip" onClick={() => onGoBack()}>
              Go Back
            </CloseButton>
            <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
              {offerSource === OfferSource.VCE &&
                (isPendingApproval || (campaign && status === ApprovalStatus.Approved)) &&
                !entity.isLocalCampaign &&
                !isNewOrDuplicate &&
                !isEditMode && (
                  <EntityApproveButton entity={entity} entityType={entityType}>
                    <StyledWhiteButton noStopPropagation onClick={() => null} data-tip data-for="reject-tooltip">
                      Reject
                    </StyledWhiteButton>
                  </EntityApproveButton>
                )}
              {((isPendingApproval && !isEditMode && !isNewOrDuplicate && !entity.isLocalCampaign) ||
                (isEditMode && isDOEOffer)) &&
                status !== ApprovalStatus.Draft && (
                  <EntityApproveButton entity={entity} entityType={entityType}>
                    <StyledGoldButton noStopPropagation onClick={onSubmit} disabled={isDisabled}>
                      {isDOEOffer ? 'Save' : 'Approve'}
                    </StyledGoldButton>
                  </EntityApproveButton>
                )}
              {(([ApprovalStatus.Draft, ApprovalStatus.Rejected].includes(status) && !entity.isLocalCampaign) ||
                (campaign &&
                  isEditMode &&
                  !entity.isLocalCampaign &&
                  [ApprovalStatus.PendingApproval, ApprovalStatus.Approved].includes(status)) ||
                (offer && isEditMode) ||
                isNewOrDuplicate) &&
                !isDOEOffer && (
                  <StyledGoldButton noStopPropagation onClick={onSubmit} disabled={isDisabled}>
                    Submit for Approval
                  </StyledGoldButton>
                )}
              {campaign &&
                [ApprovalStatus.Active, ApprovalStatus.Deployed, ApprovalStatus.AssociationStopped].includes(status) &&
                isEditMode && (
                  <StyledGoldButton
                    noStopPropagation
                    onClick={() => null}
                    data-tip
                    data-for="deployed-active-tooltip"
                    disabled={isDisabled}
                  >
                    Save
                  </StyledGoldButton>
                )}
              {status === ApprovalStatus.Approved && entity.isTriggerEvent && !isEditMode && (
                <StyledGoldButton noStopPropagation onClick={onSubmit} disabled={isDisabled}>
                  Deploy
                </StyledGoldButton>
              )}
              {[ApprovalStatus.Active, ApprovalStatus.Deployed, ApprovalStatus.AssociationStopped].includes(status) &&
                !isEditMode &&
                campaign?.hasRevoke && (
                  <StyledWhiteButton
                    noStopPropagation
                    data-for={campaign?.stoppedAssociationVoucher ? 'show-stop-association-msg' : 'revoke-tooltip'}
                    data-tip
                    onClick={() => null}
                    disabled={isDisabled}
                  >
                    Revoke
                  </StyledWhiteButton>
                )}
              {campaign?.status === ApprovalStatus.Active && !isEditMode && campaign?.hasStopAssociation && (
                <StyledWhiteButton
                  noStopPropagation
                  onClick={() => null}
                  data-tip
                  data-for="stop-association-tooltip"
                  disabled={isDisabled}
                >
                  Stop Association
                </StyledWhiteButton>
              )}
            </RoleGuard>
          </ButtonsContainer>
          {offer && (
            <OfferToolTip
              id="reject-tooltip"
              content="Are you sure you want to reject?"
              onDisapproveClick={() => {
                hideTooltip('#reject-tooltip');
                ReactTooltip.hide();
              }}
              eventOff={null}
              approveMsg="Reject"
              isWithResponse
              disapproveMsg="Cancel"
              responsePlaceholder="Enter reject reason"
              onApproveWithResponseClick={(rejectionComment) => {
                handleRejectClick(offer, rejectionComment);
              }}
            />
          )}
          {campaign?.rejectTooltip && campaign.rejectTooltip}
          {campaign?.revokeTooltip && campaign.revokeTooltip}
          {campaign?.stoppedAssociationTooltip && campaign.stoppedAssociationTooltip}
          {campaign?.stopAssociationTooltip && campaign.stopAssociationTooltip}
          {campaign?.saveDeployedTooltip && campaign.saveDeployedTooltip}
        </GmaFormFooter>
      </Container>
    </>,
    document.querySelector('#modal'),
  );
};

export default GmaViewModal;
