import React from 'react';
import { FormMode, GenericItem, GenericSet, SetOfEntity } from 'utils/types';
import { Product, ProductBagInfo } from 'utils/types/products';
import ProductItem from 'pages/settings/productSets/components/productItem/ProductItem';
import ProductSetTitle from 'pages/settings/productSets/components/productSetTitle/ProductSetTitle';
import { AccordionContentType } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { SelectedAccordionProps } from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/SelectedSetItemsListPanel.consts';
import { searchProduct, searchSet } from 'utils/product';
import { getAllItemsRecursively } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import { ButtonContainer, StyledModal, StyledProductSet } from './ProductViewModal.style';
import { StyledWhiteButton } from 'pages/offers/offerManagement/components/offerForm/OfferForm.style';

type productsviewmodalprops = {
  buyGetProducts: ProductBagInfo;
  mode: string;
  offerID: number;
  fromCampaignScreen?: boolean;
  onCancel?: () => any;
};
export const ProductViewModal = ({
  buyGetProducts,
  mode = FormMode.New,
  offerID,
  onCancel,
}: productsviewmodalprops) => {
  const onViewOfferClicked = async () => {
    // Run on cancel function passed from OfferForm
    if (onCancel) {
        onCancel();
    }
  };

  const selectedProductsSetsSectionProps: SelectedAccordionProps = {
    name: 'selected-products-sets',
    headline: 'Sets',
    contentType: AccordionContentType.Set,
    searchItem: searchProduct as (item: GenericItem, searchQuery: string) => boolean,
    searchItemSet: searchSet as (set: GenericSet, searchQuery: string) => boolean,
    filterItemSet: () => true,
  };
  const selectedProductsSectionProps: SelectedAccordionProps = {
    name: 'selected-products',
    headline: 'Products',
    contentType: AccordionContentType.Items,
    searchItem: searchProduct as (item: GenericItem, searchQuery: string) => boolean,
    searchItemSet: searchSet as (set: GenericSet, searchQuery: string) => boolean,
    filterItemSet: () => true,
  };
  const excludedItemsByIdMap = new Map<number, GenericItem>(
    getAllItemsRecursively(buyGetProducts.productSets, SetOfEntity.Products).map((item) => [Number(item.id), item]),
  );

    return (
        <StyledModal
            title='View Products'
            modalMode={mode}
            fromCampaignScreen
        >
         <StyledProductSet
            data-automation-id="selected-items-container"
            modalMode={mode}
            setOf={SetOfEntity.Products}
            itemSetTitleFormatter={ProductSetTitle}
            selectedAccordionsProps={[selectedProductsSetsSectionProps, selectedProductsSectionProps]}
            itemFormatter={(product: Product) => ProductItem(product, null)}
            formMode={FormMode.QuickView}
            supportSetFringes={false}
            itemSet={
            {
                sets: buyGetProducts?.productSets,
                products: buyGetProducts?.products,
                excludedItems: buyGetProducts?.excludedProductsIds?.map((productId) =>
                excludedItemsByIdMap.get(productId),
                ),
            } as GenericSet
            }
            offerPreview
        />
        <ButtonContainer>
            <StyledWhiteButton noStopPropagation onClick={onViewOfferClicked}>Cancel</StyledWhiteButton>
        </ButtonContainer>
        </StyledModal>
          )
}
