import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { OfferVersionItemProps } from './OfferVersionItem.consts';
import offersGqls from 'pages/offers/offerManagement/Offers.gqls';
import { FetchPolicies } from 'utils/types/common';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { Modals, openModal, modal as modalSlice } from 'app/slices/modals';
import { OfferTemplates } from 'utils/types/offers';
import NewStatusLabel from 'components/shared/statusLabel/NewStatusLabel';
import { store } from 'app/store';
import { FormMode } from 'utils/types';
import { NoOfferSelected, OfferHeading, RowFooter, StyledButtonText, TextNoOfferSelected } from './ViewOfferItem.style';
import { getCampaignById } from 'utils/api/campaigns';
import { useFormContext } from 'react-hook-form';

const ViewOfferItem = ({ offer, campaign, onClick, className, isLocalCampaign}: OfferVersionItemProps) => {
  const { props, data: modalData } = useSelector(modalSlice);
  const [load] = useLazyQuery(offersGqls.queries.getById, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      id: offer?.baseOfferId,
    },
  });

  const { data: realoffer } = useQuery(offersGqls.queries.getById, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      id: offer?.baseOfferId,
    },
  });
  const { config } = useSelector(marketConfig);
  const primaryTranslation = realoffer?.getOffer?.versions[0]?.translationsMap[config.primaryLanguage];

  const handleViewDetails = async () => {
    try {
      const { data } = await load();
      const offer = data?.getOffer;
      store.dispatch(
        openModal({
          modal: Modals.OfferModal,
          props: {
            offer,
            viewOnly: true,
            mode: FormMode.View,
            fromCampaignScreen: true,
            onClose: () =>
              store.dispatch(
                openModal({
                  modal: Modals.CampaignModal,
                  props: { mode: props?.mode, campaign: campaign, isLocalCampaign: isLocalCampaign },
                  data: { mode: modalData?.mode, campaign: campaign },
                }),
              ),
          },
        }),
      );
    } catch (error) {
      console.error('Failed to load offer data:', error);
    }
  };

  return (
    <>
      {offer ? (
        <>
          <Card sx={{ width: 254, boxShadow: '0px 0px 5.3px 1px #00000029' }}>
          <div style={{display: "flex", justifyContent:"center", borderBottom: '1px solid #D6D6D6'}}>
          <CardMedia
            component="img"
            alt={`${primaryTranslation?.image?.name}`}
            height="140"
            image={`${primaryTranslation?.image?.file}`}
            crossOrigin="anonymous"
            sx={{width:"116px", objectFit: 'scale-down' }}
          />
          </div>
            <CardContent>
              <OfferHeading>
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: '400',
                    fontFamily: 'Speedee',
                    marginTop: '8px',
                    marginBottom: '8px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {primaryTranslation?.title}
                </Typography>
              </OfferHeading>
              <Typography sx={{ fontSize: '12px', fontWeight: '700', fontFamily: 'Speedee', marginBottom: '8px' }}>
                ID {offer?.baseOfferId}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  fontFamily: 'Speedee',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {OfferTemplates[offer.templateValues.templateType as keyof typeof OfferTemplates]?.name}
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '400',
                    fontFamily: 'Speedee',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    height: '16px',
                  }}
                >
                  {primaryTranslation?.subtitle}
                </Typography>
              </Typography>
              <RowFooter>
                <div>
                  <NewStatusLabel type={offer?.status} />
                </div>
                <div>
                  <StyledButtonText onClick={handleViewDetails}>View Details</StyledButtonText>
                </div>
              </RowFooter>
            </CardContent>
          </Card>
        </>
      ) : (
        <NoOfferSelected>
          <TextNoOfferSelected>No Offer Defined</TextNoOfferSelected>
        </NoOfferSelected>
      )}
    </>
  );
};

export default ViewOfferItem;
